import { color } from "../../../../../../mui-theme/palette";

export enum RegistrationStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  TIMESLOT_CANCELLED = "TIMESLOT_CANCELLED",
  COMPLETED = "COMPLETED",
}

export enum RegistrationApprovalStatus {
  NOT_STARTED = "NOT_STARTED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "REGISTERED",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  RETURNED = "RETURNED",
  CANCELLED = "CANCELLED",
  PASS = "PASSED",
  FAIL = "FAILED",
}

export enum ExamStatus {
  PASS = "PASSED",
  FAIL = "FAILED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
}

export enum LicenseStatus {
  NOT_STARTED = "NOT_STARTED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RETURNED = "RETURNED",
  CANCELLED = "CANCELLED",
}

export enum ExamMode {
  INFO_NOT_AVAILABLE = "INFO_NOT_AVAILABLE",
  DEFAULT = "DEFAULT",
  COMPUTER = "COMPUTER",
  PAPER = "PENCIL_AND_PAPER",
}

export const iconBgColorMap = {
  [ExamMode.DEFAULT]: color.redLightest,
  [ExamMode.INFO_NOT_AVAILABLE]: color.redLightest,
  [ExamMode.COMPUTER]: color.blueLight,
  [ExamMode.PAPER]: color.cyanLight,
};

export const iconColorMap = {
  [ExamMode.DEFAULT]: color.majorRed,
  [ExamMode.INFO_NOT_AVAILABLE]: color.majorRed,
  [ExamMode.COMPUTER]: color.blue,
  [ExamMode.PAPER]: color.cyan,
};

export const iconMap = {
  [ExamMode.DEFAULT]: "licenseexam",
  [ExamMode.INFO_NOT_AVAILABLE]: "licenseexam",
  [ExamMode.COMPUTER]: "computer",
  [ExamMode.PAPER]: "pencilandpaper",
};
