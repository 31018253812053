import React, { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/store";
import dayjs from "dayjs";
import { EVENT_PATH, ROOT_PATH, constants } from "../../../../routes/constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  UnavailableType,
  formatEventRegistrationDate,
  getUnavailableModalConfig,
} from "../../util/event.util";
import { prompt } from "../../../../components/modal/prompt";
import { takeUIClickEvent } from "../../../../common/ga/ga";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import { RegMethodType } from "../../network/type";

export const DetailBottom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { refId = "" } = useParams();
  const { regStartDate, regEndDate, isOverallQuotaFull, remainingRegQuota, regMethod, regLink } =
    useAppSelector((state) => state.event.eventDetailData)!;

  const unavailableModalType = useMemo(() => {
    if (dayjs().isBefore(regStartDate)) return UnavailableType.NOT_OPEN_YET;
    if (dayjs().isAfter(regEndDate)) return UnavailableType.REGISTRATION_CLOSE;
    if (isOverallQuotaFull || remainingRegQuota === 0) return UnavailableType.EVENT_FULL;
  }, [isOverallQuotaFull, regEndDate, regStartDate, remainingRegQuota]);

  const handleRegister = useCallback(() => {
    const gaData: TakeUIClickEventParameters = {
      journey: "event_rsvp",
      stage: "register_for_event",
      object_name: "Register",
      object_type: "BUTTON",
    };
    takeUIClickEvent(gaData);
    if (unavailableModalType) {
      const config = getUnavailableModalConfig(unavailableModalType);
      if (config) {
        prompt(config);
      }
    } else {
      if (regMethod === RegMethodType.LINK && regLink) {
        window.location.href = regLink;
      } else {
        navigate(`${ROOT_PATH}/${EVENT_PATH}/form/${refId}`);
      }
    }
  }, [unavailableModalType, regMethod, regLink, navigate, refId]);

  return (
    <div className="w-full flex flex-col flex-1 items-center fixed bottom-0 left-0 px-4 bg-white">
      <button
        className="w-full md:max-w-[480px] px-4 py-3 my-2 bg-[#E8192C] rounded-xl text-base font-semibold text-white"
        onClick={handleRegister}
      >
        {t("event.register")}
      </button>
      <span className="py-2 text-xs font-medium text-[#666] text-center leading-3">
        {t("event.registration_period_time", {
          time: formatEventRegistrationDate(regStartDate, regEndDate),
        })}
      </span>
    </div>
  );
};
