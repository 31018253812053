import { useCallback, useMemo } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ExamStatus, LicenseStatus, RegistrationApprovalStatus, RegistrationStatus } from "./type";
import moment from "moment";
import { selectOnboardingTrainingSummary } from "../../../../redux/selector";
import { color } from "../../../../../../mui-theme/palette";
import { mapLanguage } from "../../../../network/network";
import { useNavigate } from "react-router-dom";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../../../../routes/constants";
import { useQuery } from "../../../../../../utils/hooks/use-query";

interface HookProps {}
export const useLicenseExamEntranceCard = (props: HookProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const trainingAndExamSummary = useSelector(selectOnboardingTrainingSummary);
  const getCardColorStyle = useCallback(
    (status: RegistrationStatus | RegistrationApprovalStatus) => {
      if (status === RegistrationStatus.IN_PROGRESS) {
        return { statusBackgroundColor: color.cyanLight, statusTextColor: color.cyan };
      } else if (status === RegistrationStatus.COMPLETED) {
        return { statusBackgroundColor: color.greenLight, statusTextColor: color.green };
      } else if (status === RegistrationApprovalStatus.PASS) {
        return { statusBackgroundColor: color.greenLight, statusTextColor: color.green };
      } else if (status === RegistrationApprovalStatus.FAIL) {
        return { statusBackgroundColor: color.orangeLight, statusTextColor: color.orange };
      } else if (status === RegistrationApprovalStatus.PENDING_APPROVAL) {
        return { statusBackgroundColor: color.redLightest, statusTextColor: color.majorRed };
      } else if (status === RegistrationApprovalStatus.APPROVED) {
        return { statusBackgroundColor: color.cyanLight, statusTextColor: color.cyan };
      } else if (status === RegistrationApprovalStatus.REJECTED) {
        return { statusBackgroundColor: color.redLightest, statusTextColor: color.majorRed };
      } else if (status === RegistrationApprovalStatus.RETURNED) {
        return { statusBackgroundColor: color.yellowLight, statusTextColor: color.yellow };
      }
      return { statusBackgroundColor: color.greyBg, statusTextColor: color.grey33 };
    },
    [],
  );
  const licenses = useMemo(() => {
    const licenses = getLicenseExamStatus((trainingAndExamSummary?.examSummary as any)?.data);
    if (!licenses) {
      return [];
    }
    return licenses.map((license: any) => ({
      ...license,
      status: t(license.statusI18nKey),
      description: license.statusDesc,
      examName: license.examName,
      examType: license.examType,
      ...getCardColorStyle(license.status),
    }));
  }, [getCardColorStyle, t, trainingAndExamSummary?.examSummary]);
  const translations = useMemo(() => {
    return {
      title: t("Recruitment.license_exam"),
      more: t("Common.more"),
    };
  }, [t]);
  const handlePress = useCallback(
    (licenseOrExam: any) => {
      if (!licenseOrExam.examCode) {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/exams`);
        return;
      }
      navigate(
        `${ROOT_PATH}/${ONBOARDING_PATH}/exams/registry/${licenseOrExam.examCode}?${
          licenseOrExam?.registrationId ? `&registrationId=${licenseOrExam?.registrationId}` : ""
        }${query.toString() ? `&${query.toString()}` : ""}`,
        {
          state: { data: licenseOrExam },
        },
      );
    },
    [navigate, query],
  );

  return {
    handlePress,
    translations,
    licenses,
  };
};

// Function to determine the status and description
export function getLicenseExamStatus(data: any[]) {
  const startedExams = data?.filter?.(
    (exam) => exam.registrationStatus !== RegistrationStatus.NOT_STARTED,
  );
  if (!startedExams || startedExams.length === 0) {
    return [
      {
        statusI18nKey: "Common.NOT_STARTED",
        status: "NOT_STARTED",
        statusDesc: getI18n().t("Recruitment.complete_module_now"),
      },
    ];
  }
  const results = startedExams.map(mapLicenseExamStatus);
  return results;
}

function mapLicenseExamStatus(item: any) {
  if (item.examDate) {
    return mapExamRegistrationStatus(item);
  }
  const examName = item.examName?.[mapLanguage(getI18n().language)];
  const {
    registrationApprovalStates: registrationApprovalStatus,
    licenseApprovalStates: licenseStatus,
    registrationStatus,
  } = item;
  let examStatus = item.examStatus;

  if (registrationStatus === RegistrationStatus.TIMESLOT_CANCELLED) {
    examStatus = ExamStatus.CANCELLED;
  }

  if (
    registrationStatus === RegistrationStatus.NOT_STARTED &&
    (registrationApprovalStatus === RegistrationApprovalStatus.NOT_STARTED ||
      !registrationApprovalStatus) &&
    (examStatus === ExamStatus.NOT_STARTED || !examStatus) &&
    (licenseStatus === LicenseStatus.NOT_STARTED || !licenseStatus)
  ) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.NOT_STARTED",
      status: "NOT_STARTED",
      statusDesc: getI18n().t("Recruitment.complete_module_now"),
    };
  }

  if (licenseStatus === LicenseStatus.PENDING_APPROVAL) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc: getI18n().t("Recruitment.exam_proof_request_review"),
    };
  }

  if (licenseStatus === LicenseStatus.RETURNED) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc: getI18n().t("Recruitment.verification_returned_review_info"),
    };
  }

  if ([LicenseStatus.CANCELLED, LicenseStatus.REJECTED].includes(licenseStatus)) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc: getI18n().t("Recruitment.verification_cancelled_restart"),
    };
  }

  if (licenseStatus === LicenseStatus.APPROVED) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.COMPLETED",
      status: "COMPLETED",
      statusDesc: getI18n().t("Recruitment.exam_proof_request_passed_verification"),
    };
  }

  if (registrationApprovalStatus === RegistrationApprovalStatus.PENDING_APPROVAL) {
    const timeslot = `${moment(item.examDate, "YYYY-MM-DD").format("DD/MM/YYYY")} ${
      item.startTime
    }-${item.endTime}`;
    const statusDesc = getI18n().t("Recruitment.registered_timeslot", { timeslot });
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc,
    };
  }

  if (registrationApprovalStatus === RegistrationApprovalStatus.RETURNED) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc: getI18n().t("Recruitment.registration_returned_review_info"),
    };
  }

  if (examStatus === ExamStatus.CANCELLED) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc: getI18n().t("Recruitment.exam_cancelled_register_another_timeslot"),
    };
  }

  if (examStatus === ExamStatus.FAIL) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc: getI18n().t("Recruitment.exam_failed_register_again"),
    };
  }

  if (
    registrationApprovalStatus === RegistrationApprovalStatus.CANCELLED ||
    registrationApprovalStatus === RegistrationApprovalStatus.REJECTED
  ) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.IN_PROGRESS",
      status: "IN_PROGRESS",
      statusDesc: getI18n().t("Recruitment.registration_cancelled_restart"),
    };
  }

  if (
    [RegistrationApprovalStatus.APPROVED, RegistrationApprovalStatus.COMPLETED].includes(
      registrationApprovalStatus,
    ) &&
    (examStatus === ExamStatus.NOT_STARTED || !examStatus)
  ) {
    const timeslot = `${moment(item.examDate, "YYYY-MM-DD").format("DD/MM/YYYY")} ${
      item.startTime
    }-${item.endTime}`;
    const statusDesc = getI18n().t("Recruitment.registration_verified_timeslot", {
      timeslot,
    });
    return {
      ...item,
      examName,
      statusI18nKey: "Common.COMPLETED",
      status: "COMPLETED",
      statusDesc,
    };
  }

  if (examStatus === ExamStatus.PASS) {
    return {
      ...item,
      examName,
      statusI18nKey: "Common.COMPLETED",
      status: "COMPLETED",
      statusDesc: getI18n().t("Recruitment.exam_passed_congratulations"),
    };
  }

  return {
    ...item,
    examName,
    statusI18nKey: "Unknown",
    status: "Unknown",
    statusDesc: "Recruitment.status_info_not_available",
  };
}

const mapExamRegistrationStatus = (item: any) => {
  const examName = item.examName?.[mapLanguage(getI18n().language)];
  const { registrationApprovalStates, examCentre, examMode, examDate, score, startTime, endTime } =
    item;
  // exam schedule form looks like: 29/01/2025, Wed, 10:30 - 12:30
  const examSchedule = `${moment(examDate, "YYYY-MM-DD").format("DD/MM/YYYY")}, ${moment(
    examDate,
    "YYYY-MM-DD",
  ).format("ddd")}, ${startTime} - ${endTime}`;
  return {
    ...item,
    examName,
    statusI18nKey: `Common.${registrationApprovalStates}`,
    status: registrationApprovalStates,
    examSchedule,
    examCentre: examCentre,
    examMode,
    score,
  };
};
