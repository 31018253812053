export const textStyleSet = {
  text_text_24_bold: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: "32px",
  },

  text_24_bold: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: "32px",
  },

  text_20_bold: {
    fontSize: 20,
    fontWeight: "600",
    lineHeight: "28px",
  },

  text_20_med: {
    fontSize: 20,
    fontWeight: "500",
    lineHeight: "28px",
  },

  text_20_reg: {
    fontSize: 20,
    fontWeight: "400",
    lineHeight: "28px",
  },

  text_18_bold: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: "26px",
  },

  text_18_med: {
    fontSize: 18,
    fontWeight: "500",
    lineHeight: "26px",
  },

  text_16_bold: {
    fontSize: 16,
    fontWeight: "700",
    lineHeight: "24px",
  },

  text_16_med: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "24px",
  },

  text_14_bold: {
    fontSize: 14,
    fontWeight: "700",
    lineHeight: "22px",
  },

  text_14_med: {
    fontSize: 14,
    fontWeight: "500",
    lineHeight: "22px",
  },

  text_14_reg: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: "22px",
  },

  text_12_bold: {
    fontSize: 12,
    fontWeight: "700",
    lineHeight: "20px",
  },

  text_12_med: {
    fontSize: 12,
    fontWeight: "500",
    lineHeight: "16px",
  },

  text_11_med: {
    fontSize: 11,
    fontWeight: "500",
    lineHeight: "19px",
  },

  text_11_bold: {
    fontSize: 11,
    fontWeight: "700",
    lineHeight: "19px",
  },

  text_10_med: {
    fontSize: 10,
    fontWeight: "500",
    lineHeight: "18px",
  },

  num_40: {
    fontSize: 40,
    fontWeight: "700",
    lineHeight: "49px",
  },

  num_30: {
    fontSize: 30,
    fontWeight: "700",
    lineHeight: "37px",
  },

  num_28: {
    fontSize: 28,
    fontWeight: "600",
    lineHeight: "34px",
  },

  num_20: {
    fontSize: 20,
    fontWeight: "700",
    lineHeight: "26px",
  },

  num_18: {
    fontSize: 18,
    fontWeight: "600",
    lineHeight: "22px",
  },

  num_14: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: "27px",
  },

  num_12: {
    fontSize: 12,
    fontWeight: "600",
    lineHeight: "14px",
  },
};
