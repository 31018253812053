import { color } from "../../../../../../mui-theme/palette";

export const styles = {
  contentContainer: {
    padding: 12,
    backgroundColor: color.white,
    marginBottom: 12,
  },
  iconContainer: {
    borderRadius: 12,
    borderWidth: 0,
    backgroundColor: color.redLightest,
  },
};
