import React from "react";
import { IconFont, IconFontProps } from "./iconfont";

export interface IconFontLayerProps extends IconFontProps {
  backgroundColor?: string;
  backgroundSize?: number;
  backgroundOpacity?: number;
  containerStyle?: React.CSSProperties;
}

/**
 * IconFontLayer component: add a background layer outside the icon, support custom background color, size, opacity, etc.
 */
export const IconFontLayer: React.FC<IconFontLayerProps> = (props) => {
  const {
    backgroundColor,
    backgroundOpacity = 1,
    backgroundSize,
    containerStyle,
    size = 16, // Default icon size
    ...rest
  } = props;

  // Calculate the size of the background layer, default is the icon size + 24
  const backSize = backgroundSize || size + 24;

  return (
    <div
      style={{
        backgroundColor,
        width: backSize,
        height: backSize,
        borderRadius: backSize / 2,
        borderWidth: 1, // Default border width
        borderColor: backgroundColor,
        opacity: backgroundOpacity,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <IconFont size={size} {...rest} />
    </div>
  );
};
