import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import { ErrorView } from "../../components/error-view";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { Layout } from "../../../../layout/layout";
import { Header } from "../../components/header";
import { convert2SurveyLocale } from "../../../../components/surveyjs/survey/survey.util";
import { useTranslation } from "react-i18next";
import "./survey-form-screen.css";
import { initValidation } from "../../../../components/surveyjs/survey/survey-default-form/util";
import { RegisterResultComponent } from "../../components/event-register/register-result/register-result.component";
import { useSurveyForm } from "./survey-form.hook";
import { ToastLoadingComponent } from "../../../../components/loading/toast-loading/toast-loading.component";
import { useAppSelector } from "../../../../redux/store";
import "./survey-form-screen.css";

export const SurveyFormScreen = () => {
  initValidation();
  const { t, i18n } = useTranslation();
  const { errorStatus, completed, surveyModel, loading, qrCodeShortenCode } = useSurveyForm();
  const { eventDetailData, loading: eventDetailLoading } =
    useAppSelector((state) => state.event) ?? {};

  const showCompletedView = completed || eventDetailData?.registered;

  return (
    <Layout disableLanguageButton disableTnc>
      {eventDetailLoading ? null : errorStatus ? (
        <>
          <Header />
          {errorStatus === "404" ? (
            <ErrorView title={t("event.event_expired")} />
          ) : (
            <ErrorScreen statusCode={errorStatus} />
          )}
        </>
      ) : showCompletedView ? (
        <RegisterResultComponent
          qrCodeShortenCode={
            eventDetailData?.registered ? eventDetailData?.shortenCode : qrCodeShortenCode
          }
        />
      ) : surveyModel ? (
        <div className="w-full h-full pb-5 overflow-hidden">
          <Survey
            model={surveyModel}
            id="event-survey"
            locale={convert2SurveyLocale(i18n.language)}
          />
          {loading && <ToastLoadingComponent />}
        </div>
      ) : null}
    </Layout>
  );
};
