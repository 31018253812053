import React from "react";

import pruforceBg from "../../../../components/icon/assets/candidate_pruforce_bg.svg";
import defaultAvatar from "../../../../components/icon/assets/default-avatar.png";
import editLogo from "../../../../components/icon/assets/edit.svg";
import emailLogo from "../../../../components/icon/assets/email.svg";
import female from "../../../../components/icon/assets/female.svg";
import licenseExamLogo from "../../../../components/icon/assets/license-exam.svg";
import logoutLogo from "../../../../components/icon/assets/logout.svg";
import male from "../../../../components/icon/assets/male.svg";
import prudnaLogo from "../../../../components/icon/assets/prudna.svg";
import settingLogo from "../../../../components/icon/assets/setting.svg";
import trainingLogo from "../../../../components/icon/assets/training.svg";

import { useCandidateHome } from "./candidate-home.hook";

import { CandidateStatus } from "../../types";
import styles from "./candidate-home.module.css";
import { LicenseExamEntranceCardComponent } from "./components/license-exam-entrance-card/license-exam-entrance-card.component";
type CandidateHomeProps = {};

export const CandidateHomeScreen = (props: CandidateHomeProps) => {
  const {
    t,
    clickInbox,
    clickSetting,
    clickProfile,
    clickLogout,
    handleDisclaimerClick,
    handlePrivacyPolicyClick,
    handleLanguageClick,
    clickPopup,
    candidateProfile,
    isSettingPopupOpen,
    labelOption,
    inboxUnreadCount,
    clickLicenseExam,
    clickPrudna,
    clickTraining,
    enableOnboardingExamRegistry,
  } = useCandidateHome();

  return (
    <div className={styles.container}>
      {/* Header section */}
      <header className={styles.header}>
        <img src={pruforceBg} alt=""></img>
        <div className={styles.operation}>
          <div className={styles.logoContainer} onClick={clickInbox}>
            <img src={emailLogo} alt="" className={styles.logoImg} />
            {inboxUnreadCount ? (
              <div className={styles.countTextContainer}>
                <span className={styles.countText}>
                  {inboxUnreadCount <= 99 ? inboxUnreadCount : "99+"}
                </span>
              </div>
            ) : null}
          </div>

          <img src={settingLogo} alt="" className={styles.logoImg} onClick={clickSetting} />
          <img src={logoutLogo} alt="" className={styles.logoImg} onClick={clickLogout} />
        </div>
      </header>

      {/* profile section */}
      <div className={styles.profileContainer}>
        <div className={styles.profile}>
          <div className="px-5 pt-5 pb-10 aspect-ratio-1-1:rounded-xl bg-white">
            <div className={styles.profileHeader}>
              <div className={styles.profilePictureContainer}>
                <img
                  src={candidateProfile?.profile_img_url || defaultAvatar}
                  alt=""
                  className={styles.profileImage}
                />
                <div className={styles.overlay} onClick={clickProfile}>
                  <img src={editLogo} alt="" className={styles.editIcon} />
                </div>
              </div>

              <div className={styles.profileInfo}>
                <div className={styles.nameSex}>
                  <h2 className={styles.profileName}>{candidateProfile?.name}</h2>
                  <img
                    src={candidateProfile?.gender === "F" ? female : male}
                    alt=""
                    className={styles.sexImg}
                  />
                </div>
                <span
                  className={styles.profileStatus}
                  style={{
                    backgroundColor: labelOption(candidateProfile?.status as CandidateStatus)
                      ?.backgroundColor,
                    color: labelOption(candidateProfile?.status as CandidateStatus)?.color,
                  }}
                >
                  {labelOption(candidateProfile?.status as CandidateStatus)?.text}
                </span>
              </div>
            </div>
            <div className={styles.actions}>
              <div className={styles.actionItem} onClick={clickPrudna}>
                <img src={prudnaLogo} alt="" className={styles.actionIcon}></img>
                <span className={styles.text}>{t("Common.prudna")}</span>
              </div>
              <div className={styles.actionItem} onClick={clickTraining}>
                <img src={trainingLogo} alt="" className={styles.actionIcon}></img>
                <span className={styles.text}>{t("Common.training")}</span>
              </div>
              <div className={styles.actionItem} onClick={clickLicenseExam}>
                <img src={licenseExamLogo} alt="" className={styles.actionIcon}></img>
                <span className={styles.text}>{t("Recruitment.license_exam")}</span>
              </div>
            </div>
          </div>
          {enableOnboardingExamRegistry ? <LicenseExamEntranceCardComponent /> : null}
        </div>
      </div>

      {isSettingPopupOpen && (
        <div className={styles.settingOverlay} onClick={clickPopup}>
          <div className={styles.popup}>
            <ul>
              <li className={styles.popupItem} onClick={handleLanguageClick}>
                {t("Common.language")}
              </li>
              <li className={styles.popupItem} onClick={handlePrivacyPolicyClick}>
                {t("Common.privacy_notice")}
              </li>
              <li className={styles.popupItem} onClick={handleDisclaimerClick}>
                {t("Common.disclaimer")}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
