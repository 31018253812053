import { get } from "lodash";
import { stringify, parse } from "qs";
import { OnboardingTrainingPlatform } from "./type";
import { http } from "../../../../utils/axios/axios";
import { CORE_URL, getTargetCandidate, getToken, parseJwt } from "../../utils";
import { getCustomHeader, updateTrainingStatus } from "../../network/network";
import { OnboardingLessonStatus, RoleEnum } from "../../types";

const CONSTANTS = {
  Course: "C",
  LearningPath: "LP",
};

export const updateTargetCandidatePaymentType = (newType: string) => {
  const targetCandidate = getTargetCandidate();
  targetCandidate.paymentType = newType;
  const newTargetCandidateString = JSON.stringify(targetCandidate);
  sessionStorage.setItem("CANDIDATE_PROFILE", newTargetCandidateString);
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      action: "updateTargetCandidatePaymentType",
      payload: newType,
    }),
  );
};

export const redirectToOnboardingTraining = async (
  trainingPlatform: OnboardingTrainingPlatform,
) => {
  if (trainingPlatform === OnboardingTrainingPlatform.COE) {
    return;
  }
  if (trainingPlatform === OnboardingTrainingPlatform.LOCAL) {
    if (!localLMSEnabled()) {
      return;
    }
    let litmosUrl = get(window, "envConfig.REACT_APP_LITMOS_URL", "");
    const response = await http.get(`${CORE_URL}/integration/token?issuer=pruexpert`, {
      headers: getCustomHeader(),
    });
    const urlWithloginKey = response?.data?.meta?.LoginKey;

    const [_, queryString] = litmosUrl.split("?");
    const query = parse(queryString);

    const needAppendLoginKeyUrlParams = [CONSTANTS.Course, CONSTANTS.LearningPath];
    const urlParams = [...Object.keys(query)];
    const needAppendLoginKey =
      needAppendLoginKeyUrlParams.filter((item) => urlParams.includes(item)).length > 0;

    if (needAppendLoginKey) {
      const [urlBase, urlQueryString] = urlWithloginKey.split("?");
      const urlInfo = parse(urlQueryString);
      let obj = {
        ...query,
        ...{ loginKey: urlInfo?.loginkey },
        ...{ skipFirstlogin: true },
      };
      const path = `${urlBase}?${stringify(obj)}`;

      litmosUrl = path;
    }

    if (response?.data?.statusCode === 404) {
      alert("Please check your PRUExpert access");
      return;
    }

    if (urlWithloginKey) {
      litmosUrl = `${urlWithloginKey}&skipFirstlogin=true`;
    }

    window.ReactNativeWebView?.postMessage?.(`{ "action" : "show-top-back-button"}`);
    const currentUrlQuery = parse(window.location.search, { ignoreQueryPrefix: true });
    const candidateId: string = currentUrlQuery.candidateId as string;
    updateTrainingStatus(candidateId, { status: OnboardingLessonStatus.IN_PROGRESS });
    window.location.href = litmosUrl;
  }
};

export const localLMSEnabled = () => {
  const PRUFORCE_REGION = get(window, "envConfig.PRUFORCE_REGION", "");
  const token = getToken();
  const tokenData = token ? parseJwt(token) : {};
  return ["PH", "HK"].includes(PRUFORCE_REGION) && tokenData.role === RoleEnum.CANDIDATE;
};
