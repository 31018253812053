import { cloneDeep } from "lodash";
import { formatCountryCode } from "../../../components/surveyjs/survey/survey.util";
import { getI18n } from "react-i18next";
import { SurveyFormData } from "../pages/survey-form/survey-form.hook";

export const decodeEventSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const phone = searchParams.get("phone");
  const email = searchParams.get("email");

  const decodedFirstName = firstName ? decodeURIComponent(firstName) : "";
  const decodedLastName = lastName ? decodeURIComponent(lastName) : "";
  const decodedPhone = phone ? decodeURIComponent(phone) : "";
  const decodedEmail = email ? decodeURIComponent(email) : "";

  const { countryCode, phoneNumber } = formatAgentPhone(decodedPhone);

  return {
    firstName: decodedFirstName,
    lastName: decodedLastName,
    countryCode,
    phoneNumber,
    email: decodedEmail,
  };
};

/**
 * @description format agent phone number
 * @param phone eg "+852 32424324" "32424324"
 * @returns ep: {countryCode: "+852", phoneNumber: "32424324"} {countryCode: "", phoneNumber: "32424324"}
 */
export const formatAgentPhone = (phone: string) => {
  const regex = /^(\+\d+\s)?(.+)$/;
  const match = phone.match(regex);

  if (match) {
    const countryCode = match[1] ? match[1].trim() : "";
    const phoneNumber = match[2].trim();

    return { countryCode, phoneNumber };
  } else {
    return { countryCode: "", phoneNumber: "" };
  }
};

const setElementProperty = (data: any[], name: string, property: string, value: any) => {
  if (!value || !data) return;
  for (const item of data) {
    if (item.name === name) {
      item[property] = value;
      return;
    } else if (item.elements) {
      setElementProperty(item.elements, name, property, value);
    }
  }
};

const findElement = (data: any[], name: string) => {
  if (!data) return;
  for (const item of data) {
    if (item.name === name) {
      return item;
    } else if (item.elements) {
      const foundItem: any = findElement(item.elements, name);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return null;
};

export const formatSurveyJson = ({
  surveyData,
  region,
  dropdownConfig,
}: {
  surveyData: SurveyFormData;
  region: any;
  dropdownConfig?: any;
}) => {
  const surveyDataCopy = cloneDeep(surveyData);
  const { regFormBody, regFormResult } = surveyDataCopy;
  const { pages } = regFormBody;

  // add "(full)" text for full session on form page
  if (!regFormResult) {
    pages?.[0]?.elements?.forEach((item: any) => {
      if (item?.name.includes("session")) {
        item?.choices?.forEach((choice: any) => {
          if (choice.enableIf === "false") {
            Object.keys(choice.text).forEach((key) => {
              choice.text[key] += getI18n().t("event.full");
            });
          }
        });
      }
    });
  }

  const { email, phoneNumber, countryCode, firstName, lastName } = decodeEventSearchParams();

  // set default survey field
  setElementProperty(pages, "registrant_firstName", "defaultValue", firstName);
  setElementProperty(pages, "registrant_lastName", "defaultValue", lastName);
  setElementProperty(pages, "registrant_email", "defaultValue", email);

  const regionPhone = findElement(pages, "countryCode_phoneNumber");
  if (regionPhone && dropdownConfig) {
    const { value, defaultValue } = formatCountryCode(
      dropdownConfig.regionPhone,
      region?.toLocaleLowerCase(),
    );

    if (regFormResult?.registrant_countryCode) {
      // if return regFormResult, it means that the results page needs to be displayed, then set the value for country code on the results page.
      regFormResult.registrant_countryCode = value
        ?.filter((item) => item.key === regFormResult.registrant_countryCode)
        ?.map(({ key, label, regex }) => ({ key, regex }))?.[0];
    }

    const countryCodeDefaultValue = findElement(pages, "registrant_countryCode")?.defaultValue;
    const currentCountryCodeValue = value?.filter(
      (item) => item.key === countryCode || item.key === countryCodeDefaultValue,
    )?.[0];

    // set country code dropdown options
    setElementProperty(
      regFormBody.pages,
      "registrant_countryCode",
      "choices",
      value?.map(({ key, label, regex }) => ({ value: { key, regex }, text: key })),
    );
    setElementProperty(regFormBody.pages, "registrant_countryCode", "defaultValue", {
      key: currentCountryCodeValue?.key || defaultValue?.key,
      regex: currentCountryCodeValue?.regex || defaultValue?.regex,
    });
    setElementProperty(regFormBody.pages, "registrant_phoneNumber", "defaultValue", phoneNumber);
  }

  regFormBody.showPreviewBeforeComplete = "off";
  return { regFormBody, regFormResult };
};
