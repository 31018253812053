import React, { Fragment, useMemo } from "react";
import "../../pages/detail/detail-screen.css";
import { useAppSelector } from "../../../../redux/store";
import { EventLabel, EventType, OnlineMeetingLinkDisplayEnum, Session } from "../../network/type";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { constants } from "../../../../routes/constants";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { getI18nData } from "../../../../components/surveyjs/survey/survey.util";

export const Sessions = () => {
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.event.eventDetailData);

  return data?.sessions && data?.sessions?.length > 0 ? (
    <Fragment>
      <div className="divider" />
      <span className="font-semibold text-[16px] mb-4 md:text-2xl md:mb-[30px]">
        {t("event.sessions")}
      </span>

      <div className="lg:columns-2 columns-1 gap-[30px] w-full">
        {data?.sessions?.map((session, i) => {
          return (
            <div
              className="w-full px-3 flex flex-col rounded-lg card-shadow break-inside-avoid-column mb-4 md:mb-5 last:mb-0"
              key={i}
            >
              <SessionItem item={session} num={i + 1} />
              {session?.subSessions && session.subSessions?.length > 0 && (
                <div className="w-full h-[1px] bg-[#e6e6e6]" />
              )}
              {session?.subSessions?.map((item, inx) => {
                return <SessionItem item={item} num={inx + 1} isSub key={item.key} />;
              })}
            </div>
          );
        })}
      </div>
    </Fragment>
  ) : null;
};

const SessionItem = ({ item, num, isSub }: { item: Session; num: number; isSub?: boolean }) => {
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.event.eventDetailData);
  const {
    name,
    venue,
    startTime,
    endTime,
    label,
    quota,
    onlineMeetingLink,
    onlineMeetingLinkDisplay,
  } = item;

  return (
    <div className="w-full flex flex-row py-4">
      <div
        className={`md:w-[72px] w-[60px] h-auto flex flex-col  items-center rounded-[11px] mr-3 overflow-hidden ${
          isSub ? "bg-[#EBF3FF]" : "bg-[#E0FBFF]"
        }`}
      >
        {label === EventLabel.CAN_WALK_IN && (
          <div className="w-full px-2 py-1 text-xs leading-3 font-medium text-white bg-[#FFB83D] text-center">
            {t("event.walk_in")}
          </div>
        )}
        <div className="flex flex-1 flex-col justify-center items-center">
          <span
            className={`text-xs text-center font-semibold md:text-[14px] md:leading-4 whitespace-pre-wrap ${
              isSub ? "text-[#4386E5]" : "text-[#24B1C7]"
            }`}
          >
            {isSub ? t("event.sub_session") : t("event.session")}
          </span>
          <span
            className={`text-xs text-center font-semibold md:text-[14px] md:leading-4 ${
              isSub ? "text-[#4386E5]" : "text-[#24B1C7]"
            }`}
          >
            {num}
          </span>
        </div>
      </div>

      <div className="flex flex-col flex-1 min-w-0 gap-y-[5px] md:gap-y-[6px]">
        <span className="text-base font-medium md:text-xl">{getI18nData(name)}</span>
        <div className="flex flex-row items-start">
          <Icon
            svg={SvgAssets.locationGray}
            width={16}
            height={16}
            className="flex-shrink-0 mt-[2px]"
          />
          <div className="flex flex-col ml-1 flex-1 min-w-0 gap-1">
            <span className="text-[#999] text-sm font-medium md:text-lg">
              {getI18nData(venue) ||
                (data?.type === EventType.VIRTUAL
                  ? t("event.online_event")
                  : getI18nData(data?.venue))}
            </span>
            {onlineMeetingLink &&
              onlineMeetingLinkDisplay?.includes(OnlineMeetingLinkDisplayEnum.EVENT_DETAIL) && (
                <a
                  href={onlineMeetingLink}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#4386E5] text-sm font-medium md:text-lg"
                >
                  {t("event.access_link")}
                </a>
              )}
          </div>
        </div>

        <div className="flex flex-row items-center">
          <Icon svg={SvgAssets.timeGray} width={16} height={16} className="flex-shrink-0" />
          <span className="flex-1 min-w-0 text-[#999] text-sm font-medium ml-1 md:text-lg">
            {`${dayjs(startTime).format(constants.Time_Format)}-${dayjs(endTime).format(
              constants.Time_Format,
            )}`}
          </span>
        </div>

        {quota && (
          <div className="flex flex-row items-center">
            <Icon svg={SvgAssets.quotaSmall} width={16} height={16} className="flex-shrink-0" />
            <span className="flex-1 min-w-0 text-[#999] text-sm font-medium ml-1 md:text-lg">
              {quota}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
