import dayjs from "dayjs";
import React, { useMemo } from "react";
import { InboxMessage } from "../../../network/types";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../../routes/constants";
import { GlobalHelper } from "../../../../../utils/helpers/global-helper";
import { isEmpty } from "lodash";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { updateMessageClickStatus, updateMessageReadStatus } from "../../../network/network";
import { t } from "i18next";

interface InboxItemProps {
  message: InboxMessage;
  highlightText?: string;
}
export type ExtendData = {
  redirectTo: string;
  link: string;
  openBy: null;
  params: {
    id: string;
    type: string;
  };
};

const routesMappping: any = {
  recruitmentcandidateprofile: `${ROOT_PATH}/${ONBOARDING_PATH}/candidate-home`,
};

export const onHandleNotification = async ({
  item,
  dispatchCb,
}: {
  item: InboxMessage;
  dispatchCb: Function;
}) => {
  // set message's read status to Yes
  await dispatchCb(item.id);
  const extendValue: ExtendData =
    typeof item.extendValue === "string" ? JSON.parse(item.extendValue) : item.extendValue;
  if (extendValue) {
    let extendValueParams = extendValue.params || {};
    extendValueParams =
      typeof extendValueParams === "string" ? JSON.parse(extendValueParams) : extendValueParams;
    if (!extendValue.link) return;

    switch (extendValue.redirectTo) {
      case "app":
        const navigate = GlobalHelper.getGlobalNavigate();
        navigate(routesMappping[extendValue.link], { state: extendValueParams });
        break;
      case "url":
        // {"link": "https://www.baidu.com", "openBy": "external", "redirectTo": "url"}
        if (extendValue.openBy === "external") {
          // open the url with new tab
          window.open(extendValue.link, "_blank");
        } else {
          // open the url with same tab
          window.location.href = extendValue.link;
        }
        break;
    }
  }
};

const highlightTextInContent = (text: string, highlight: string) => {
  if (!highlight) return text;

  // Escape special characters in regular expressions
  const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const parts = text.split(new RegExp(`(${escapedHighlight})`, "gi"));

  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} className="text-red-600">
        {part}
      </span>
    ) : (
      part
    ),
  );
};

export const InboxItem: React.FC<InboxItemProps> = ({ message, highlightText }) => {
  const { extendValue } = useMemo(() => {
    return message ?? {};
  }, [message]);
  if (!message) return null;
  return (
    <li
      key={message.id}
      className="p-4 bg-white border-b border-gray-100 cursor-pointer"
      onClick={() => {
        onHandleNotification({
          item: message,
          dispatchCb: async (id: string) => {
            await Promise.all([updateMessageClickStatus(id), updateMessageReadStatus(id)]).catch(
              (e) => console.error("Failed to update message status:", e),
            );
          },
        });
      }}
    >
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <p
            className="font-semibold text-[14px] leading-[22px] text-left"
            style={{ hyphens: "auto" }}
          >
            {highlightTextInContent(message.msgTitle, highlightText || "")}
            {!message.readStatus && (
              <span className="ml-2 inline-block w-2 h-2 bg-red-500 rounded-full"></span>
            )}
          </p>
        </div>
        <span className="text-[14px] font-medium leading-[20px] text-right text-gray-400 pl-4">
          {dayjs(message.time).format("DD/MM/YYYY HH:mm")}
        </span>
      </div>
      <p className="text-gray-600 py-2" style={{ whiteSpace: "pre-wrap" }}>
        {highlightTextInContent(message.msgContent, highlightText || "")}
      </p>
      {isEmpty(extendValue) ? null : (
        <div className="mt-2 text-cobalt flex flex-row items-center">
          {t("Common.view_detail")}
          <ChevronRightIcon style={{ marginLeft: -4 }} />
        </div>
      )}
    </li>
  );
};
