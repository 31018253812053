import React from "react";
import type { RouteObject } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { SurveyFormScreen } from "./pages/form/survey-form.screen";
import { MenuScreen } from "./pages/menu";
import { PendingFollowUpScreen } from "./pages/pending-follow-up";
import { CandidateDataFormScreen } from "./pages/candidate-data-form/candidate-data-form.screen";
import { JoinDoneScreen } from "./pages/candidate-data-form/join-done.screen";
import { JoinExpiredScreen } from "./pages/candidate-data-form/join-expired.screen";
import { InvitationCancelledScreen } from "./pages/candidate-data-form/invitation-cancelled.screen";
import { CourseMenuScreen } from "./pages/course-menu-screen/course-menu-screen";
import { CourseLessonScreen } from "./pages/course-menu-screen/screens/course-lesson-screen/course-lesson-screen";
import { CourseQuizScreen } from "./pages/course-menu-screen/screens/course-quiz-screen/course-quiz-screen";
import { ExamMenuScreen } from "./pages/exam-menu-screen/exam-menu-screen";
import { CourseQuizFeedbackScreen } from "./pages/course-menu-screen/screens/course-quiz-screen/quiz-feedback-screen";
import { ExamFeedbackScreen } from "./pages/exam-menu-screen/screens/exam-registry-screen/exam-feedback-screen";
import { ReassessPage } from "./pages/reassess/reassess.screen";
import { ApplicationDetailScreen } from "./pages/application-detail-screen/application-detail.screen";
import { TrainingPaymentScreen } from "./pages/training-payment-screen/training-payment-screen";
import { DocumentDownloadScreen } from "./pages/document-download-screen/document-download-screen";
import { ExamRegistryPaymentScreen } from "./pages/exam-menu-screen/screens/exam-registry-screen/exam-registry-payment-screen";
import { CandidateHomeScreen } from "./pages/candidate-home/candidate-home.screen";
import { SettingScreen } from "./pages/setting/setting.screen";
import { OtpVerificationScreen } from "./pages/otp-verification-screen/otp-verification.screen";
import { SetPasswordScreen } from "./pages/set-password-screen/set-password.screen";
import IdleTimerLayout from "../common/idle-timer/idle-timer-layout";
import { ProtocolScreenPage } from "./pages/protocol-screen/protocol-screen.screen";
import { PrivacyNoticeScreen } from "./pages/protocol-screen/privacy-notice.screen";
import { InboxListScreen } from "./pages/inbox/inbox-list-screen/inbox-list-screen";
import { InboxSearchScreen } from "./pages/inbox/inbox-search-screen/inbox-search-screen";

export const routesOnboarding: RouteObject[] = [
  { path: "menu", index: true, element: <MenuScreen /> },
  { path: "form", element: <SurveyFormScreen /> },
  { path: "application-detail", element: <ApplicationDetailScreen /> },
  {
    path: "courses",
    element: <Outlet />,
    children: [
      { path: "", index: true, element: <CourseMenuScreen /> },
      { path: "payment", element: <TrainingPaymentScreen /> },
      { path: "lesson", element: <CourseLessonScreen /> },
      { path: "quiz/:quizId", element: <CourseQuizScreen /> },
      { path: "quiz/:quizId/feedback", element: <CourseQuizFeedbackScreen /> },
    ],
  },
  {
    path: "exams",
    element: (
      <IdleTimerLayout>
        <Outlet />
      </IdleTimerLayout>
    ),
    children: [
      { path: "", index: true, element: <ExamMenuScreen /> },
      // { path: "registry/:examId", element: <ExamRegistryScreen /> },
      { path: "registry/:examId/feedback", element: <ExamFeedbackScreen /> },
      { path: "registry/:examId", element: <ExamRegistryPaymentScreen /> },
    ],
  },
  // { path: "courses", element: <CourseMenuScreen /> },
  { path: "follow-up", element: <PendingFollowUpScreen /> },
  { path: "reassess", element: <ReassessPage /> },
  {
    path: "join-now",
    element: (
      <IdleTimerLayout>
        <CandidateDataFormScreen />
      </IdleTimerLayout>
    ),
  },
  { path: "join-done", element: <JoinDoneScreen /> },
  { path: "join-expired", element: <JoinExpiredScreen /> },
  { path: "invitation-cancelled", element: <InvitationCancelledScreen /> },
  { path: "document-download", element: <DocumentDownloadScreen /> },
  {
    path: "candidate-home",
    element: (
      <IdleTimerLayout>
        <CandidateHomeScreen />
      </IdleTimerLayout>
    ),
  },

  {
    path: "setting",
    element: (
      <IdleTimerLayout>
        <SettingScreen />
      </IdleTimerLayout>
    ),
  },
  { path: "otp-verification", index: true, element: <OtpVerificationScreen /> },
  { path: "set-password", index: true, element: <SetPasswordScreen /> },
  { path: "protocol", index: true, element: <ProtocolScreenPage /> },
  {
    path: "privacy-notice",
    index: true,
    element: <PrivacyNoticeScreen />,
  },
  {
    path: "inbox",
    element: <Outlet />,
    children: [
      {
        path: "",
        index: true,
        element: (
          <IdleTimerLayout>
            <InboxListScreen />
          </IdleTimerLayout>
        ),
      },
      {
        path: "search",
        element: (
          <IdleTimerLayout>
            <InboxSearchScreen />
          </IdleTimerLayout>
        ),
      },
    ],
  },
];
