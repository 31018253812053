import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { Model } from "survey-core";
import {
  fetchCandidateOnboardingForm,
  fetchConfig,
  fetchOnboardingFormTemplate,
  getTodoTasks,
  fetchTrainingCourses,
  submitApplicationForm,
  fetchTrainingExams,
  fetchTrainingCourseQuizDetail,
  lessonStatusUpdate,
  quizAnswerUpdate,
  fetchTrainingExamCentres,
  fetchTrainingExamTimeslots,
  postCandidateExamRegistry,
  getOnboardingTrainingSummary,
  fetchGlobalConfig,
  updateCourseQuizRandomQuestion,
  fetchOnboardingDocumentUpload,
  fetchInboxMessages,
} from "../network/network";
import { AxiosError } from "axios";
import {
  OnboardingApplicationStatus,
  OnboardingExam,
  OnboardingFlow,
  OnboardingFormModuleName,
  OnboardingFormQuestionName,
  OnboardingFormSectionName,
  OnboardingLessonStatus,
  OnboardingQuiz,
  OnboardingRegistrationInfo,
  OnboardingTrainingCourse,
  OnboardingTypeExam,
  RoleEnum,
  SubmitFormActionParams,
  Timeslot,
  OnboardingTrainingSummary,
} from "../types";
import { merge, get, cloneDeep } from "lodash";
import { extractOwnerId, getToken, parseJwt } from "../utils";
import {
  convertCoursesResponse,
  convertExamCentresResponse,
  convertExamRegistryResponse,
  convertExamTimeslotsResponse,
  convertQuizResponse,
} from "./convert-data";
import { DocumentUpload, SourceEnum } from "../network/file-op-interface";
import { popupExpiredTemplateReminder } from "../pages/form/util";
import { readConfigs } from "../../../utils/common-utils";
import { InboxMessagesResponse, MessageListRequestParams } from "../network/types";
export const onboardingPersistConfig = {
  key: "onboarding",
  storage,
};

export const initialState: OnboardingState = {
  requesting: "idle",
  configs: {},
};

export interface OnboardingState {
  requesting?: "idle" | "pending" | "fulfilled" | "rejected";
  loaded?: boolean;
  candidateOnboardingForm?: any;
  isAssignee?: boolean;
  isFormCreator?: boolean;
  followUpTasks?: any;
  allTodoTasks?: any;
  trainingCourses?: OnboardingTrainingCourse[];
  trainingUserId?: string;
  trainingCourseQuiz?: OnboardingQuiz;
  trainingExams?: OnboardingTypeExam[];
  inboxMessagesResponse?: InboxMessagesResponse;
  currentExam?: OnboardingExam;
  trainingAndExamSummary?: OnboardingTrainingSummary;
  trainingExamCentres?: OnboardingRegistrationInfo[];
  trainingExamTimeslots?: Timeslot[];
  currentTemplate?: any;
  rawTemplate?: any;
  getDataError?: AxiosError | any;
  populatedFieldData?: any;
  formDataVerifiedError?: any;
  submiting?: boolean;
  configs: any;
  configsLoaded?: boolean;
  documentDownloadList?: any;
  documentUploadList?: any;
}

export const fetchCandidateOnboardingFormAsync = createAsyncThunk(
  "candidate-onboarding-form/get",
  async (param: { candidateId: string }, thunkAPI: any) => {
    try {
      const { candidateId } = param;
      const result = await fetchCandidateOnboardingForm(candidateId);
      const followUpTaskResults = result.data?.[0]?.applicationId
        ? await getTodoTasks({
            applicationId: result.data?.[0]?.applicationId,
            page: 0,
            limit: 20,
          })
        : { data: [] };
      result.allTodoTasks = followUpTaskResults.data.data;
      result.followUpTasks = {};
      result.followUpTasks.data =
        result.allTodoTasks?.filter?.(
          (item: { type: string }) => item.type === "FOLLOWUP_REQUIRED",
        ) ?? [];
      result.followUpTasks.totalNumbers = get(result, "followUpTasks.data.length", 0);

      return result;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchTrainingCoursesAsync = createAsyncThunk(
  "candidate-onboarding-courses/get",
  async ({ candidateId }: { candidateId: string }, thunkAPI: any) => {
    try {
      const stateData = thunkAPI.getState();
      let globalConfig = stateData?.configs?.Global;
      let onboardingForm = stateData?.onboarding?.candidateOnboardingForm;
      if (!onboardingForm) {
        const formResponse = await fetchCandidateOnboardingForm(candidateId);
        onboardingForm = formResponse?.data?.[0];
      }
      if (!globalConfig) {
        const globalConfigResponse = await fetchGlobalConfig();
        globalConfig = globalConfigResponse?.data?.content;
      }
      const response = await fetchTrainingCourses({ candidateId });
      return {
        data: convertCoursesResponse(response, candidateId, onboardingForm).data,
        globalConfig,
      };
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchTrainingCourseQuizDetailAsync = createAsyncThunk(
  "candidate-onboarding-courses-quiz/get",
  async (params: { quizId: string; candidateId: string }, thunkAPI: any) => {
    try {
      const { quizId, candidateId } = params;
      const stateData = thunkAPI.getState();
      let globalConfig = stateData?.configs?.Global;
      if (!globalConfig) {
        const globalConfigResponse = await fetchGlobalConfig();
        globalConfig = globalConfigResponse?.data?.content;
      }
      let onboardingForm = stateData?.onboarding?.candidateOnboardingForm;
      if (!onboardingForm) {
        const formResponse = await fetchCandidateOnboardingForm(candidateId);
        onboardingForm = formResponse?.data?.[0];
      }
      let courses: OnboardingTrainingCourse[] = get(stateData, "onboarding.trainingCourses");
      if (!courses) {
        const courseResponse = await fetchTrainingCourses({ candidateId });
        courses = convertCoursesResponse(courseResponse, candidateId, onboardingForm).data as any;
      }
      let lessonId = "";
      let courseId = courses.find((course) => course.quiz?.quizId === quizId)?.courseId;
      if (!courseId)
        courseId = courses.find((course) =>
          course.lessons.find((lesson) => {
            lessonId = lesson.lessonId;
            return lesson.quiz?.quizId === quizId;
          }),
        )?.courseId;
      const response = await fetchTrainingCourseQuizDetail({
        candidateId,
        courseId: courseId!,
        quizId,
        lessonId,
      });
      const data = convertQuizResponse(response, candidateId).data;
      return { data: { ...data, courseId, quizId, lessonId, candidateId } };
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateCourseQuizRandomQuestionAsync = createAsyncThunk(
  "random-courses-quiz/patch",
  async (params: { quizId: string; candidateId: string }, thunkAPI: any) => {
    try {
      const { quizId, candidateId } = params;
      const stateData = thunkAPI.getState();
      let globalConfig = stateData?.configs?.Global;
      if (!globalConfig) {
        const globalConfigResponse = await fetchGlobalConfig();
        globalConfig = globalConfigResponse?.data?.content;
      }
      let onboardingForm = stateData?.onboarding?.candidateOnboardingForm;
      if (!onboardingForm) {
        const formResponse = await fetchCandidateOnboardingForm(candidateId);
        onboardingForm = formResponse?.data?.[0];
      }
      let courses: OnboardingTrainingCourse[] = get(stateData, "onboarding.trainingCourses");
      if (!courses) {
        const courseResponse = await fetchTrainingCourses({ candidateId });
        courses = convertCoursesResponse(courseResponse, candidateId, onboardingForm).data as any;
      }
      let lessonId = "";
      let courseId = courses.find((course) => course.quiz?.quizId === quizId)?.courseId;
      if (!courseId)
        courseId = courses.find((course) =>
          course.lessons.find((lesson) => {
            lessonId = lesson.lessonId;
            return lesson.quiz?.quizId === quizId;
          }),
        )?.courseId;
      const response = await updateCourseQuizRandomQuestion({
        candidateId,
        courseId: courseId!,
        quizId,
        lessonId,
      });
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchTrainingExamsAsync = createAsyncThunk(
  "candidate-onboarding-exams/get",
  async (params: { candidateId: string }, thunkAPI: any) => {
    try {
      const response = await fetchTrainingExams(params);
      const data = convertExamRegistryResponse(response, params.candidateId).data;
      return data;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchInboxMessagesAsync = createAsyncThunk(
  "candidate-onboarding-inbox/get",
  async (params: MessageListRequestParams, thunkAPI: any) => {
    try {
      const response = await fetchInboxMessages(params);
      return response;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getOnboardingTrainingSummaryAsync = createAsyncThunk(
  "candidate-onboarding-training-summary/get",
  async (params: { candidateId: string }, thunkAPI: any) => {
    try {
      const response = await getOnboardingTrainingSummary(params);
      const data = response.data;
      return data;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchTrainingExamByIdAsync = createAsyncThunk(
  "candidate-onboarding-exams/getById",
  async (
    params: { candidateId: string; registrationId: string | null; examCode: string },
    thunkAPI: any,
  ) => {
    try {
      const response = await fetchTrainingExams(params);
      const formResponse = await fetchCandidateOnboardingForm(params.candidateId);
      const onboardingForm = formResponse?.data?.[0];
      const data = convertExamRegistryResponse(response, params.candidateId).data;
      let exam: OnboardingExam | undefined;
      for (const typeExam of data) {
        if (params.registrationId) {
          exam = typeExam.exams.find((item) => item.registrationId === params.registrationId);
        } else {
          exam = typeExam.exams.find((item) => item.examCode === params.examCode);
        }

        if (exam) {
          break;
        }
      }
      return { exam, onboardingForm };
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchTrainingExamCentresAsync = createAsyncThunk(
  "candidate-onboarding-exam-centres/get",
  async (params: OnboardingExam, thunkAPI: any) => {
    try {
      const response = await fetchTrainingExamCentres(params);
      const data = convertExamCentresResponse(response).data;
      return data;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchTrainingExamTimeslotsAsync = createAsyncThunk(
  "candidate-onboarding-exam-timeslots/get",
  async (params: OnboardingExam & { examCentre: string }, thunkAPI: any) => {
    try {
      const response = await fetchTrainingExamTimeslots(params);
      const data = convertExamTimeslotsResponse(response).data;
      return data;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchOnboardingFormTemplateAsync = createAsyncThunk<any, any, any>(
  "candidate-onboarding-template/get",
  async (param: { templateId?: string }, thunkAPI: any) => {
    try {
      const result = await fetchOnboardingFormTemplate(param);
      return result;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const submitApplicationFormAsync = createAsyncThunk(
  "onboarding-app-form/post",
  async (params: SubmitFormActionParams) => {
    const { data, action } = params;
    try {
      const result = await submitApplicationForm(data, action);
      return result;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchConfigAsync = createAsyncThunk<any, void, { state: OnboardingState }>(
  "onboarding-configs/get",
  async (thunkAPI: any) => {
    try {
      const result = await fetchConfig();

      const configs = readConfigs(result.map((configRes: any) => configRes.data));
      return { data: configs };
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const lessonStatusUpdateAsync = createAsyncThunk(
  "onboarding-course/lesson-status-update",
  async (params: { lessonId: string; status: OnboardingLessonStatus }, thunkApi) => {
    const { lessonId, status } = params;
    const stateData = thunkApi.getState();
    const courses: OnboardingTrainingCourse[] = get(stateData, "onboarding.trainingCourses")!;
    const candidateId: string = get(stateData, "onboarding.trainingUserId")!;
    const courseId = courses.find((course) =>
      course.lessons.find((lesson) => lesson.lessonId === lessonId),
    )!.courseId;
    const data = { lessonId, status, candidateId, courseId };
    try {
      const result = await lessonStatusUpdate(data);
      return result;
    } catch (error) {
      throw error;
    }
  },
);

export const quizAnswerUpdateAsync = createAsyncThunk(
  "onboarding-course/quiz-answer-update",
  async (params: { name: string; value: any; endQuiz?: boolean }, thunkApi) => {
    const stateData = thunkApi.getState();
    const { name, value, endQuiz } = params;
    const quizAnswer = {
      name: name,
      value: value,
      endQuiz,
    };
    try {
      const result = await quizAnswerUpdate(
        get(stateData, "onboarding.trainingCourseQuiz")!,
        quizAnswer,
      );
      return { ok: true, data: result.data };
    } catch (error) {
      return { ok: false, error };
    }
  },
);

export const postCandidateExamRegistryAsync = createAsyncThunk(
  "onboarding-course/exam-registry",
  async (params: { candidateId: string; body: any }, thunkApi) => {
    const { candidateId, body } = params;
    try {
      const result = await postCandidateExamRegistry(candidateId, body);
      return result;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchOnboardingDocumentUploadAsync = createAsyncThunk(
  "onboarding-documentUpload/get",
  async (params: DocumentUpload, thunkAPI: any) => {
    try {
      const response = await fetchOnboardingDocumentUpload(params);
      return response;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

let onboardingFlow: OnboardingFlow;
export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    reset: () => {
      return { ...initialState };
    },
    resetTrainingExamTimeslots: (state) => {
      state.trainingExamTimeslots = [];
    },
  },
  extraReducers(builder: any) {
    builder
      .addCase(fetchCandidateOnboardingFormAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
      })
      .addCase(
        fetchCandidateOnboardingFormAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          state.candidateOnboardingForm = action.payload.data[0];
          state.followUpTasks = action.payload.followUpTasks;
          state.allTodoTasks = action.payload.allTodoTasks;
          state.loaded = true;
        },
      )
      .addCase(
        fetchCandidateOnboardingFormAsync.rejected,
        (state: OnboardingState, action: any) => {
          state.requesting = "rejected";
          state.loaded = false;
          state.getDataError = action.payload;
        },
      )
      .addCase(fetchTrainingCoursesAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
      })
      .addCase(
        fetchTrainingCoursesAsync.fulfilled,
        (
          state: OnboardingState,
          action: {
            meta: any;
            payload: any | undefined;
          },
        ) => {
          state.requesting = "fulfilled";
          state.trainingCourses = action.payload.data;
          state.configs = { ...state.configs, Global: action.payload.onboardingConfig };
          state.trainingUserId = action.meta.arg.candidateId;
          state.loaded = true;
        },
      )
      .addCase(fetchTrainingCoursesAsync.rejected, (state: OnboardingState, action: any) => {
        state.requesting = "rejected";
        state.loaded = false;
        state.getDataError = action.payload;
      })
      .addCase(fetchTrainingCourseQuizDetailAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
        state.trainingCourseQuiz = null;
      })
      .addCase(
        fetchTrainingCourseQuizDetailAsync.fulfilled,
        (
          state: OnboardingState,
          action: {
            meta: any;
            payload: any | undefined;
          },
        ) => {
          state.requesting = "fulfilled";
          state.trainingCourseQuiz = { ...action.payload.data, ...action.meta.arg };
          state.loaded = true;
        },
      )
      .addCase(
        fetchTrainingCourseQuizDetailAsync.rejected,
        (state: OnboardingState, action: any) => {
          state.requesting = "rejected";
          state.loaded = false;
          state.getDataError = action.payload;
        },
      )
      .addCase(
        lessonStatusUpdateAsync.fulfilled,
        (
          state: OnboardingState,
          action: {
            meta: any;
            payload: any | undefined;
          },
        ) => {
          if (!action.payload.ok) {
            return;
          }
          state.trainingCourses = state.trainingCourses?.map((course) => {
            return {
              ...course,
              lessons: course.lessons.map((lesson) => {
                return {
                  ...lesson,
                  status: action.meta.arg?.status,
                };
              }),
            };
          });
        },
      )
      .addCase(
        quizAnswerUpdateAsync.fulfilled,
        (
          state: OnboardingState,
          action: {
            meta: any;
            payload: any | undefined;
          },
        ) => {
          if (!action.payload.ok) {
            return;
          }
          state.trainingCourseQuiz = (state.trainingCourseQuiz ?? {}) as OnboardingQuiz;
          state.trainingCourseQuiz.passedCount = state.trainingCourseQuiz.passedCount ?? 0;
          state.trainingCourseQuiz.failedCount = state.trainingCourseQuiz.failedCount ?? 0;
          const { questionCorrect } = get(action, "payload.data", {});
          if (!action.meta.arg?.endQuiz) {
            if (questionCorrect) {
              state.trainingCourseQuiz.passedCount++;
            } else {
              state.trainingCourseQuiz.failedCount++;
            }
          }
        },
      )
      .addCase(fetchTrainingExamsAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
      })
      .addCase(
        fetchTrainingExamsAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          state.trainingExams = action.payload;
          state.loaded = true;
        },
      )
      .addCase(fetchTrainingExamsAsync.rejected, (state: OnboardingState, action: any) => {
        state.requesting = "rejected";
        state.loaded = false;
        state.getDataError = action.payload;
      })
      .addCase(fetchInboxMessagesAsync.pending, (state: any) => {
        state.getDataError = null;
        state.loaded = false;
      })
      .addCase(
        fetchInboxMessagesAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined; meta: any }) => {
          state.inboxMessagesResponse = {
            ...state.inboxMessagesResponse,
            ...action.payload,
            data: (!state.inboxMessagesResponse?.data || action.meta?.arg?.page === 1
              ? []
              : state.inboxMessagesResponse?.data
            ).concat(action.payload.data),
          };
          state.loaded = true;
        },
      )
      .addCase(fetchInboxMessagesAsync.rejected, (state: OnboardingState, action: any) => {
        state.loaded = true;
        state.getDataError = action.payload;
      })
      .addCase(getOnboardingTrainingSummaryAsync.pending, (state: any) => {
        state.trainingAndExamSummary = {};
      })
      .addCase(
        getOnboardingTrainingSummaryAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined }) => {
          state.trainingAndExamSummary = action.payload;
        },
      )
      .addCase(fetchTrainingExamByIdAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
        state.currentExam = null;
      })
      .addCase(
        fetchTrainingExamByIdAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          state.currentExam = action.payload.exam;
          state.candidateOnboardingForm = action.payload.onboardingForm;
          state.loaded = true;
        },
      )
      .addCase(fetchTrainingExamCentresAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
        state.trainingExamCentres = [];
        state.trainingExamTimeslots = [];
      })
      .addCase(
        fetchTrainingExamCentresAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          state.trainingExamCentres = action.payload;
          state.loaded = true;
        },
      )
      .addCase(fetchTrainingExamTimeslotsAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
        state.trainingExamTimeslots = [];
      })
      .addCase(
        fetchTrainingExamTimeslotsAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          state.trainingExamTimeslots = action.payload;
          state.loaded = true;
        },
      )
      .addCase(fetchOnboardingFormTemplateAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
      })
      .addCase(
        fetchOnboardingFormTemplateAsync.fulfilled,
        (state: OnboardingState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          onboardingFlow = state.configs.formCreator;
          const rawTemplate = action.payload.data;
          state.rawTemplate = rawTemplate;
          state.currentTemplate = filterAndConvertTemplate(rawTemplate, state);
          popupExpiredTemplateReminder(state);
          state.loaded = true;
        },
      )
      .addCase(fetchOnboardingFormTemplateAsync.rejected, (state: OnboardingState, action: any) => {
        state.requesting = "rejected";
        state.loaded = false;
        state.getDataError = action.payload;
      })
      .addCase(submitApplicationFormAsync.pending, (state: OnboardingState, action: any) => {
        state.submiting = true;
      })
      .addCase(submitApplicationFormAsync.fulfilled, (state: OnboardingState, action: any) => {
        state.submiting = false;
        if (!action.payload?.errors) {
          state.candidateOnboardingForm = action.payload.data || state.candidateOnboardingForm;
        } else {
          state.formDataVerifiedError = action.payload?.errors;
        }
      })
      .addCase(submitApplicationFormAsync.rejected, (state: OnboardingState, action: any) => {
        state.formDataVerifiedError = tryParseJson(action.error.message);
        state.submiting = false;
      })
      .addCase(fetchConfigAsync.fulfilled, (state: OnboardingState, action: { payload: any }) => {
        state.configs = { ...state.configs, ...action.payload.data };
        state.configsLoaded = true;
      })
      .addCase(fetchOnboardingDocumentUploadAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.getDataError = null;
      })
      .addCase(
        fetchOnboardingDocumentUploadAsync.fulfilled,
        (
          state: OnboardingState,
          action: {
            [x: string]: any;
            payload: any | undefined;
          },
        ) => {
          state.requesting = "fulfilled";
          if (action?.meta?.arg?.source === SourceEnum.DOCUMENT_DOWNLOAD) {
            state.documentDownloadList = action.payload.data;
          } else {
            state.documentUploadList = action.payload.data;
          }
          state.loaded = true;
        },
      )
      .addCase(
        fetchOnboardingDocumentUploadAsync.rejected,
        (state: OnboardingState, action: any) => {
          state.requesting = "rejected";
          state.loaded = false;
          state.getDataError = action.payload;
        },
      );
  },
});

function tryParseJson(str: string) {
  try {
    const obj = JSON.parse(str);
    return obj;
  } catch (e) {
    // if failed, return the original string
    return str;
  }
}

function filterAndConvertTemplate(rawTemplateJson: Model, state: OnboardingState): any {
  const templateJson = cloneDeep(rawTemplateJson);
  if (!templateJson) return {};
  const pages = get(templateJson, "pages", []).filter((page) => page.visible !== false);
  if (pages.length === 0) return templateJson;
  const newPages: any[] = [];
  const applicationStatus = state.candidateOnboardingForm?.applicationStatus;
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const isFormAssignee = extractOwnerId(tokenData) === state.candidateOnboardingForm?.assignee;
  let isAssignee = isFormAssignee;
  if (
    [
      OnboardingApplicationStatus.FOLLOW_UP_REQUIRED,
      OnboardingApplicationStatus.PENDING_SIGNATURE,
    ].includes(state.candidateOnboardingForm?.applicationStatus)
  ) {
    const allTodoTasks = state.allTodoTasks ?? [];

    if (allTodoTasks.length !== 0) {
      const assigneeRoles = [RoleEnum.CANDIDATE, RoleEnum.AGENT, RoleEnum.MANAGER];
      const firstTaskAssigneeRole = assigneeRoles.find((role) => {
        return allTodoTasks?.find?.((task: any) => task.assigneeRole === role)?.assigneeId;
      });

      const currentOwnerRole =
        tokenData.role === RoleEnum.SECRETARY ? RoleEnum.MANAGER : tokenData.role;
      isAssignee = firstTaskAssigneeRole === currentOwnerRole;
    }
  } else if (state.candidateOnboardingForm?.assignee === undefined || isFormAssignee) {
    isAssignee = true;
  }
  if (tokenData.role === RoleEnum.SECRETARY && state.candidateOnboardingForm?.isSecretaryReadonly) {
    isAssignee = false;
  }
  state.isAssignee = isAssignee;

  const isFormCreator =
    !state.candidateOnboardingForm ||
    extractOwnerId(tokenData) === state.candidateOnboardingForm?.formCreatorUserId;
  state.isFormCreator = isFormCreator;
  pages.forEach((page) => {
    if (page.visible === false) return;
    let readOnly = page.readOnly;
    if (
      !isAssignee ||
      ((!isFormCreator || OnboardingApplicationStatus.PENDING_SIGNATURE === applicationStatus) &&
        page.name !== OnboardingFormModuleName.ESignature)
    ) {
      readOnly = true;
    }
    if (
      [
        OnboardingApplicationStatus.SUBMITTED,
        OnboardingApplicationStatus.ACCEPTED,
        OnboardingApplicationStatus.REJECTED,
        OnboardingApplicationStatus.EXPIRED,
        OnboardingApplicationStatus.CANCELLED,
        OnboardingApplicationStatus.L1_PENDING_APPROVAL,
        OnboardingApplicationStatus.L2_PENDING_APPROVAL,
        OnboardingApplicationStatus.L3_PENDING_APPROVAL,
        OnboardingApplicationStatus.L4_PENDING_APPROVAL,
        OnboardingApplicationStatus.L1_APPROVED,
        OnboardingApplicationStatus.L2_APPROVED,
        OnboardingApplicationStatus.L3_APPROVED,
      ].includes(applicationStatus)
    ) {
      readOnly = true;
    }
    page.readOnly = readOnly;
    const panels = get(page, "elements", []);
    const newPanels = filterPagePanels(panels, state);
    if (newPanels.length > 0) newPages.push({ ...page, elements: newPanels });
  });
  return { ...templateJson, pages: newPages };
}

const filterPagePanels = (panels: any[], state: OnboardingState): any[] => {
  const results: any[] = [];
  panels.forEach((panel) => {
    const convertedPanel = convertPanel(panel, state);
    const isVisible = checkPanelVisibility(convertedPanel);
    if (isVisible) results.push(convertedPanel);
  });
  return results;
};

const checkPanelVisibility = (panel: any): boolean => {
  if (panel.visible === false) return false;
  const elements = get<any[]>(panel, "elements", []);
  const isVisible =
    elements.length > 0 &&
    elements
      .filter((q: any) => !["html", "image"].includes(q.type) && !get(q, "visibleIf"))
      .some((el) => {
        let visible = get(el, "visible");
        if (visible === undefined) {
          if (el.templateElements !== undefined) {
            if (el.templateElements.length) {
              visible = el.templateElements
                .filter((q: any) => !["html", "image"].includes(q.type) && !get(q, "visibleIf"))
                .some((q: any) => {
                  return get(q, "visible", true);
                });
            } else {
              visible = false;
            }
          } else {
            visible = true;
          }
        }
        return visible;
      });
  return isVisible;
};

const convertPanel = (panel: any, state: OnboardingState) => {
  const elements = get<any[]>(panel, "elements", []);
  elements.map((el) => {
    if (el.choicesLazyLoadEnabled && el.choicesByUrl) {
      el.choicesLazyLoadByUrl = el.choicesByUrl;
      delete el.choicesByUrl;
    }
  });
  if (panel.name === OnboardingFormSectionName.eSignatureSection) {
    return convertESignatureSection(panel, state);
  }
  return panel;
};

const convertESignatureSection = (panel: any, state: OnboardingState) => {
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const recruiterAgentCode = state.candidateOnboardingForm?.recruiterCode;
  const recruiterIsLeader = recruiterAgentCode === extractOwnerId(tokenData);
  panel.elements.map((el: any) => {
    if (panel.readOnly) {
      el.readOnly = true;
    }
  });
  switch (tokenData.role) {
    case RoleEnum.CANDIDATE:
      panel.elements = panel.elements.filter((q: any) => {
        return [OnboardingFormQuestionName.candidateSignature].includes(q.name);
      });
      break;
    case RoleEnum.AGENT:
      const agentFields = [
        OnboardingFormQuestionName.candidateSignature,
        OnboardingFormQuestionName.recruiterSignature,
        OnboardingFormQuestionName.recruiterIdentityDocumentType,
        OnboardingFormQuestionName.recruiterIdentityDocumentUpload,
      ];
      panel.elements = panel.elements.filter((q: any) => {
        return agentFields.includes(q.name);
      });
      break;
    case RoleEnum.MANAGER:
      let leaderFields = [
        OnboardingFormQuestionName.agentLeaderSignature,
        OnboardingFormQuestionName.agentLeaderDocumentType,
        OnboardingFormQuestionName.agentLeaderDocumentUpload,
      ];
      if (recruiterIsLeader) {
        leaderFields = [
          OnboardingFormQuestionName.candidateSignature,
          OnboardingFormQuestionName.recruiterSignature,
          OnboardingFormQuestionName.recruiterIdentityDocumentType,
          OnboardingFormQuestionName.recruiterIdentityDocumentUpload,
        ];
      }
      panel.elements = panel.elements.filter((q: any) => {
        return leaderFields.includes(q.name);
      });
      break;

    default:
      break;
  }
  configureCandidateSigField(panel, tokenData.role);
  return panel;
};

const configureCandidateSigField = (panel: any, role: RoleEnum) => {
  const candidateSignatureField = panel.elements.find(
    (q: any) => q.name === OnboardingFormQuestionName.candidateSignature,
  );
  if (!candidateSignatureField) return;
  if (onboardingFlow === OnboardingFlow.Candidate) {
    switch (role) {
      case RoleEnum.CANDIDATE:
        break;
      case RoleEnum.AGENT:
      case RoleEnum.MANAGER:
        candidateSignatureField.readOnly = true;
        break;

      default:
        break;
    }
  } else {
    switch (role) {
      case RoleEnum.CANDIDATE:
        candidateSignatureField.readOnly = true;
        break;
      case RoleEnum.AGENT:
      case RoleEnum.MANAGER:
        break;

      default:
        break;
    }
  }
};

export const { reset, resetTrainingExamTimeslots } = onboardingSlice.actions;
