import React, { FC, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../components/icon/assets";
import styles from "./register-result.module.css";
import { downloadICS, formatCalendarEventData, openFromApp } from "../../../util/event.util";
import { useAppSelector } from "../../../../../redux/store";
import { EventDetailType } from "../../../network/type";
import { debounce } from "lodash";

type ComponentProps = {
  /* otherProp */
  qrCodeShortenCode?: string;
};

export const RegisterResultComponent: React.FC<ComponentProps> = memo(
  ({ qrCodeShortenCode }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();
    const eventDetailData = useAppSelector((state) => state.event.eventDetailData);

    const onClick = debounce((qrCodeShortenCode: string, eventDetailData?: EventDetailType) => {
      const formatData = formatCalendarEventData(qrCodeShortenCode, eventDetailData);
      if (openFromApp()) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            action: "addToCalendar",
            payload: { data: formatData },
          }),
        );
      } else {
        downloadICS(formatData);
      }
    }, 200);

    return (
      <div className="flex flex-col items-center justify-center w-full min-h-screen px-5">
        <Icon svg={SvgAssets.success} width={80} height={80} />
        <span className="text-lg font-semibold mt-6">{t("event.thank_you")}</span>
        <span className="text-base mt-2 font-medium text-center text-[#858585]">
          {t("event.register_result_message")}
        </span>

        <div
          className="px-4 py-2 border-2 border-[#ccc] rounded-lg mt-6 font-medium flex flex-row items-center"
          onClick={() => onClick(qrCodeShortenCode || "", eventDetailData)}
        >
          <Icon svg={SvgAssets.date} width={16} height={16} className={styles.blackDate} />
          {t("event.add_to_my_calendar")}
        </div>
      </div>
    );
  },
);
