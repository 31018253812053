import React, { useEffect, useMemo, useState } from "react";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  confirmActionAsyncFunc,
  markdownHandler,
} from "../../components/surveyjs/survey/survey.util";
import { initSurvey } from "../../components/surveyjs/survey/init-survey";
import { getI18n, useTranslation } from "react-i18next";
import { useQuery } from "../../../../utils/hooks/use-query";
import { useLocation, useNavigate } from "react-router-dom";
import { TrainingPaymentTemplate } from "./template";
import { getTrainingPayment, submitTrainingPayment } from "../../network/network";
import { cloneDeep, get, isArray } from "lodash";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../../routes/constants";
import { HeaderComponent } from "../../../../components/header/header.component";
import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";
import { ConfirmButton } from "../../../../components/buttons";
import { CandidateStatus, PaymentStatus } from "../../types";
import { getTargetCandidate, getToken, parseJwt } from "../../utils";
import { redirectToOnboardingTraining } from "../course-menu-screen/utils";
import store from "../../../../redux/store";
import { OnboardingTrainingPlatform } from "../course-menu-screen/type";

const getPaymentType = () => {
  const targetCandidate = getTargetCandidate();
  return targetCandidate.paymentType;
};

const getCandidateStatus = () => {
  const targetCandidate = getTargetCandidate();
  return targetCandidate.status;
};
export const TrainingPaymentScreen: React.FC = () => {
  initSurvey();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const candidateId = query.get("candidateId");
  const location = useLocation();
  const [formValues, setForm] = useState({} as any);
  const [pageInitiating, setPageInitiating] = useState(true);
  const trainingSummary = useMemo(() => {
    if (location.state?.summary && getPaymentType()) {
      const updatedSummary = { ...location.state?.summary };
      updatedSummary.paymentStatus = updatedSummary.paymentStatus ?? PaymentStatus.POPULATED;
      return updatedSummary;
    }
    return location.state?.summary;
  }, [location.state?.summary]);

  useEffect(() => {
    if (!candidateId) {
      setPageInitiating(false);
      return;
    }
    getTrainingPayment(candidateId)
      .then((res: any) => {
        const formVal = res?.data ? (isArray(res.data) ? res.data[0] : res.data) : undefined;
        if (formVal) {
          setForm({
            officialReceiptAmount: formVal.officialReceiptAmount,
            officialReceiptDate: formVal.officialReceiptDate,
            feeOfficialReceiptNo: formVal.feeOfficialReceiptNo,
            paymentType: formVal.paymentType ?? getPaymentType(),
          });
        } else {
          setForm({
            paymentType: getPaymentType(),
          });
        }
      })
      .finally(() => {
        setPageInitiating(false);
      });
  }, [candidateId]);
  const canGoBack = useCanGoBack();
  const tokenData = useMemo(() => {
    const rawToken = getToken();
    return rawToken ? parseJwt(rawToken) : {};
  }, []);
  const form = useMemo(() => {
    if (pageInitiating) return null;
    convertTemplate(TrainingPaymentTemplate);
    const surveyIsReadonly =
      [PaymentStatus.PENDING_APPROVAL, PaymentStatus.APPROVED].includes(
        trainingSummary?.paymentStatus,
      ) ||
      tokenData.sub !== candidateId ||
      getCandidateStatus() !== CandidateStatus.IN_PROGRESS;

    const clonedTemplate = cloneDeep(TrainingPaymentTemplate);
    // formap the template to set the readonly property
    clonedTemplate.pages[0].elements = clonedTemplate.pages[0].elements.map((element: any) => {
      element.readOnly = surveyIsReadonly;
      if (
        ((trainingSummary?.paymentStatus &&
          ![PaymentStatus.RETURNED, PaymentStatus.REJECTED].includes(
            trainingSummary?.paymentStatus,
          )) ||
          getPaymentType()) &&
        element.name === "paymentType"
      ) {
        element.readOnly = true;
      }
      return element;
    });
    const survey = new Model(clonedTemplate);
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    const defaultValue = { ...formValues };
    survey.mergeData(defaultValue);
    survey.onComplete.add((_sender: any) => {});
    survey.onTextMarkdown.add(markdownHandler);
    survey.onGetQuestionTitleActions.add((_, options) => {
      if (options.question.tooltips) {
        options.titleActions = [
          {
            id: "show-popover",
            component: "popover",
            tooltip: options.question.tooltips,
          },
        ];
      }
    });
    settings.confirmActionAsync = confirmActionAsyncFunc;
    return (
      <div className="flex flex-1 flex-col">
        <div className="flex-1 bg-gray-100 flex-col w-screen pb-4">
          <div className="bg-gray-100 w-screen flex justify-center items-center pt-5">
            <Survey
              currentPageNo={0}
              className="survey-common"
              id="training-payment"
              model={survey}
            />
          </div>
        </div>

        <div className=" w-screen sticky bottom-0 bg-gray-100 px-4 pt-2 pb-7 flex items-center justify-between gap-4">
          <ConfirmButton
            disabled={surveyIsReadonly}
            onClick={() => {
              let isValid = false;
              isValid = survey.validateCurrentPage();

              if (!isValid) {
                return;
              }
              const surveyData = survey.data;

              const trainingPaymentData: any = { ...surveyData };
              submitTrainingPayment(
                candidateId as string,
                trainingPaymentData,
                survey,
                (res: any) => {
                  const { trainingPlatform = OnboardingTrainingPlatform.COE } = get(
                    store.getState(),
                    "onboarding.configs.Recruitment",
                    {},
                  );
                  if (res.data && res.data.status === PaymentStatus.APPROVED) {
                    if (trainingPlatform === OnboardingTrainingPlatform.COE) {
                      navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/courses?${query.toString()}`, {
                        replace: true,
                      });
                      return;
                    } else {
                      redirectToOnboardingTraining(trainingPlatform);
                    }
                  } else {
                    if (canGoBack()) {
                      navigate(-1);
                    }
                  }
                },
              );
            }}
          >
            {t("proceed")}
          </ConfirmButton>
        </div>
      </div>
    );
  }, [formValues, candidateId, pageInitiating, trainingSummary, tokenData]);
  return (
    <div className="h-screen w-screen flex flex-col">
      <HeaderComponent
        title={t("onboarding_training")}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
      />
      {form}
    </div>
  );
};

const convertTemplate = (template: any) => {
  // Function to translate a given field if it exists
  const translateField = (obj: any, field: string) => {
    if (obj?.[field]) {
      obj[field] = getI18n().t(obj[field]);
    }
  };
  // Loop through each element in the first page
  template.pages[0].elements = template.pages[0].elements.map((q: any) => {
    // Translate title, labelTrue, labelFalse, requiredErrorText fields
    translateField(q, "title");
    translateField(q, "placeholder");
    translateField(q, "labelTrue");
    translateField(q, "labelFalse");
    translateField(q, "requiredErrorText");
    translateField(q, "tooltips");
    translateField(q?.modalConfig, "modalTitle");

    // translate choices if they exist
    if (q.choices && Array.isArray(q.choices)) {
      q.choices.forEach((choice: any) => {
        translateField(choice, "text");
      });
    }

    // Check if the element has validators and translate their text fields
    if (q.validators && Array.isArray(q.validators)) {
      q.validators.forEach((validator: any) => {
        translateField(validator, "text");
      });
    }

    // If the element is a paneldynamic type, we need to handle nested templateElements
    if (q.type === "paneldynamic" && q.templateElements && Array.isArray(q.templateElements)) {
      q.templateElements = q.templateElements.map((te: any) => {
        translateField(te, "title");
        translateField(te, "labelTrue");
        translateField(te, "labelFalse");
        translateField(te, "requiredErrorText");
        translateField(te?.modalConfig, "modalTitle");

        if (te.validators && Array.isArray(te.validators)) {
          te.validators.forEach((validator: any) => {
            translateField(validator, "text");
          });
        }

        return te;
      });
    }

    return q;
  });
};
