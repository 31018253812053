export const PersonalInfoTemplate = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "firstName",
          title: "First Name",
          isRequired: true,
          requiredErrorText: "Please input",
        },
        {
          type: "text",
          name: "lastName",
          title: "Last Name",
          isRequired: true,
          requiredErrorText: "Please input",
        },
        {
          type: "text",
          name: "email",
          title: "Email Address",
          isRequired: true,
          requiredErrorText: "Common.please_enter_a_valid_email_address",
          validators: [
            {
              type: "email",
              text: "Common.please_enter_a_valid_email_address",
            },
          ],
          inputType: "email",
        },
        {
          type: "paneldynamic",
          name: "regionPhone",
          title: "Phone Number",
          titleLocation: "top",
          isRequired: true,
          templateElements: [
            {
              type: "dropdown",
              name: "regionCode",
              width: "36%",
              minWidth: "36%",
              maxWidth: "36%",
              startWithNewLine: false,
              allowClear: false,
              title: "Country Code",
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Please select option where applicable",
              fieldType: "Standard",
              choices: ["+852", "+853", "+62"],
              modalConfig: {
                modalTitle: "Common.international_country_codes",
                showSearch: true,
              },
            },
            {
              type: "text",
              name: "phoneNumber",
              width: "64%",
              minWidth: "64%",
              maxWidth: "64%",
              startWithNewLine: false,
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Please input",
              validators: [
                {
                  type: "expression",
                  text: "Common.please_input_real_phone",
                  expression:
                    "regionPhoneValidate({#regionPhone[0].regionCode},{regionPhone[0].phoneNumber})",
                },
              ],
              inputType: "tel",
            },
          ],
          allowAddPanel: false,
          allowRemovePanel: false,
          panelCount: 1,
          minPanelCount: 1,
          maxPanelCount: 1,
        },
        {
          type: "text",
          name: "otp",
          title: "Verification code",
          isRequired: true,
          requiredErrorText: "Please input",
          otpConfig: {
            position: "end",
            actionParams: {
              context: "PublicSharing",
              lang: "en",
              userType: "ANONYMOUS",
              deliveryMethod: "SMS",
            },
          },
          // inputType: "number",
        },
        {
          type: "boolean",
          name: "privacyPolicyAgreement",
          title: "personal_info_collection_statement_public_portal",
          titleLocation: "left",
          requiredErrorText: "Common.consent_to_personal_data_collection",
          validators: [
            {
              type: "expression",
              text: "Common.consent_to_personal_data_collection",
              expression: "{privacyPolicyAgreement} = true",
            },
          ],
          fieldType: "Standard",
          labelTrue: "Yes",
          labelFalse: "No",
          preset: "confirm_checkbox",
        },
        {
          type: "boolean",
          name: "privacyPolicyAgreement2",
          title: "recruitment_form_submission_agreement",
          titleLocation: "left",
          requiredErrorText: "Common.please_agree_with_statement",
          validators: [
            {
              type: "expression",
              text: "Common.please_agree_with_statement",
              expression: "{privacyPolicyAgreement2} = true",
            },
          ],
          fieldType: "Standard",
          labelTrue: "Yes",
          labelFalse: "No",
          preset: "confirm_checkbox",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};
