import React from "react";
import { useGlobalModal } from "../../use-modal/use-modal";
import { Modal } from "../../use-modal/modal.type";
import { useTranslation } from "react-i18next";
import Icon from "../../icon/icon";
import { SvgAssets } from "../../icon/assets";

export interface PromptModalProps {
  title?: string;
  icon?: any;
  iconName?: keyof typeof SvgAssets; // recommend
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  close?: () => void;
}

export const PromptModal = ({
  title,
  icon,
  iconName,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  close,
}: PromptModalProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col px-4 pt-5 pb-4 md:p-9">
      {icon ? (
        <div className="flex flex-1 justify-center items-center mb-6">
          <Icon svg={icon} width={80} height={80} />
        </div>
      ) : null}

      {iconName ? (
        <div className="flex flex-1 justify-center items-center mb-6">
          <Icon svg={SvgAssets[iconName]} width={80} height={80} />
        </div>
      ) : null}

      {title ? (
        <span className="text-2xl font-semibold text-center break-words">{title}</span>
      ) : null}
      <span className="mt-3 md:mt-6 text-sm break-words">{message}</span>

      <button
        className="w-full mt-5 md:mt-[68px] px-4 py-3 bg-[#E8192C] rounded-xl text-base font-semibold text-white"
        onClick={() => {
          const result: any = onConfirm?.();
          if (!result?.keepAlive) {
            close?.();
          }
        }}
      >
        {confirmButtonText || t("global.text.got_it")}
      </button>

      {cancelButtonText && (
        <button
          className="w-full mt-3 px-4 py-3 rounded-xl text-base font-semibold border-2 border-[#CCCCCC] "
          onClick={() => {
            const result: any = onCancel?.();
            if (!result?.keepAlive) {
              close?.();
            }
          }}
        >
          {cancelButtonText}
        </button>
      )}
    </div>
  );
};

export const prompt = (props: PromptModalProps, openProps?: Modal) => {
  return new Promise<{ ok: boolean }>((resolve, reject) => {
    useGlobalModal().open(
      <PromptModal
        {...props}
        onConfirm={() => {
          const res = props.onConfirm?.();
          resolve({ ok: true });
          return res;
        }}
        onCancel={() => {
          const res = props.onCancel?.();
          resolve({ ok: false });
          return res;
        }}
      />,
      {
        showHeader: false,
        ...openProps,
      },
    );
  });
};
