import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Chip, ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SvgAssets } from "../../../../components/icon/assets";
import Icon from "../../../../components/icon/icon";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { useQuery } from "../../../../utils/hooks/use-query";
import {
  ExamStatus,
  LicenseStatus,
  OnboardingExamRegistryStatus,
  RegistrationApprovalStatus,
  RegistrationStatus,
} from "../../types";
import { completeBoxStyle, completeIconStyle, item, itemGrey, subheader } from "./style";
import { ModuleSection, SectionItem } from "./type";
import { useTranslation } from "react-i18next";
import { color } from "../../../../mui-theme/palette";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent } from "../../../../common/ga/ga";
import { selectEnableExamSameNameMultiRegistration } from "../../redux/selector";
import { useSelector } from "react-redux";
import { PruToast } from "../../../../components/pru-toast";

type MenuListProps = {
  sections?: ModuleSection[];
};

export const MenuList = (props: MenuListProps) => {
  const { sections = [] } = props;
  const navigate = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();
  const enableExamSameNameMultiRegistration = useSelector(
    selectEnableExamSameNameMultiRegistration,
  );

  const getItemClickHandler = useCallback(
    (d: SectionItem, canRegister: boolean) => {
      if (!canRegister) {
        return PruToast({ message: t("you_have_already_registered_this_license_exam") });
      }
      query.set("canEdit", "true");
      if (
        [OnboardingExamRegistryStatus.FAILED, OnboardingExamRegistryStatus.PASSED].includes(
          d.status as any,
        )
      ) {
        navigate(
          `${ROOT_PATH}/${ONBOARDING_PATH}/exams/registry/${
            d.data.examCode
          }/feedback?${query.toString()}`,
          {
            state: { exam: d.data },
          },
        );
      } else {
        navigate(
          `${ROOT_PATH}/${ONBOARDING_PATH}/exams/registry/${d.data.examCode}?${query.toString()}`,
          {
            state: { data: d.data },
          },
        );
      }
      const gaData: TakeUIClickEventParameters = {
        module: "Recruitment",
        feature: "License",
        journey: "view_individual_license",
        stage: "select_individual_license",
        screen_id: "SCR_RCRTMT_LCNS_MN",
        screen_name: "Recruitment-License-Menu-Screen",
        object_name: "IndividualLicense",
        object_type: "BUTTON",
      };
      takeUIClickEvent(gaData);
    },
    [navigate, query],
  );

  const convertData = useCallback(
    (item: SectionItem) => {
      const examName = item.title;

      const {
        registrationApprovalStates: registrationApprovalStatus,
        licenseApprovalStates: licenseStatus,
        registrationStatus,
      } = item?.data?.originData;
      let examStatus = item?.data?.examResult;
      if (registrationStatus === RegistrationStatus.TIMESLOT_CANCELLED) {
        examStatus = ExamStatus.CANCELLED;
      }

      if (
        registrationStatus === RegistrationStatus.NOT_STARTED &&
        (registrationApprovalStatus === RegistrationApprovalStatus.NOT_STARTED ||
          !registrationApprovalStatus) &&
        (examStatus === ExamStatus.NOT_STARTED || !examStatus) &&
        (licenseStatus === LicenseStatus.NOT_STARTED || !licenseStatus)
      ) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.NOT_STARTED"),
          status: "NOT_STARTED",
          statusDesc: t("Recruitment.complete_module_now"),
        };
      }

      if (licenseStatus === LicenseStatus.PENDING_APPROVAL) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc: t("Recruitment.exam_proof_request_review"),
        };
      }

      if (licenseStatus === LicenseStatus.RETURNED) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc: t("Recruitment.verification_returned_review_info"),
        };
      }

      if ([LicenseStatus.CANCELLED, LicenseStatus.REJECTED].includes(licenseStatus)) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc: t("Recruitment.verification_cancelled_restart"),
        };
      }

      if (licenseStatus === LicenseStatus.APPROVED) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.COMPLETED"),
          status: "COMPLETED",
          statusDesc: t("Recruitment.exam_proof_request_passed_verification"),
        };
      }

      if (registrationApprovalStatus === RegistrationApprovalStatus.PENDING_APPROVAL) {
        const timeslot = `${item?.data?.originData?.examSchedule?.examDate},${item?.data?.originData?.examSchedule?.startTime}-${item?.data?.originData?.examSchedule?.endTime}`;
        const statusDesc = t("Recruitment.registered_timeslot", { timeslot });
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc,
        };
      }

      if (registrationApprovalStatus === RegistrationApprovalStatus.RETURNED) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc: t("Recruitment.registration_returned_review_info"),
        };
      }

      if (
        registrationApprovalStatus === RegistrationApprovalStatus.CANCELLED ||
        registrationApprovalStatus === RegistrationApprovalStatus.REJECTED
      ) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc: t("Recruitment.registration_cancelled_restart"),
        };
      }

      if (examStatus === ExamStatus.CANCELLED) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc: t("Recruitment.exam_cancelled_register_another_timeslot"),
        };
      }

      if (examStatus === ExamStatus.FAIL) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.IN_PROGRESS"),
          status: "IN_PROGRESS",
          statusDesc: t("Recruitment.exam_failed_register_again"),
        };
      }

      if (
        [RegistrationApprovalStatus.REGISTERED, RegistrationApprovalStatus.COMPLETED].includes(
          registrationApprovalStatus,
        ) &&
        (examStatus === ExamStatus.NOT_STARTED || !examStatus)
      ) {
        const timeslot = `${item?.data?.originData?.examSchedule?.examDate},${item?.data?.originData?.examSchedule?.startTime}-${item?.data?.originData?.examSchedule?.endTime}`;
        const statusDesc = t("Recruitment.registered_timeslot", { timeslot });
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.COMPLETED"),
          status: "COMPLETED",
          statusDesc,
        };
      }

      if (examStatus === ExamStatus.PASS) {
        return {
          ...item,
          examName,
          statusI18nKey: t("Common.COMPLETED"),
          status: "COMPLETED",
          statusDesc: t("Recruitment.exam_passed_congratulations"),
        };
      }
      return {
        ...item,
        examName,
        statusI18nKey: "Unknown",
        status: "Unknown",
        statusDesc: t("Recruitment.status_info_not_available"),
      };
    },
    [t],
  );

  const filterExamData = (data: ModuleSection[]): ModuleSection[] => {
    return data
      .filter((group) => {
        // Filter out groups where the top-level canRegister is true
        const validItems = group.data.filter((item) =>
          enableExamSameNameMultiRegistration
            ? item.data.originData.canRegister
            : item.data.originData.canRegister ||
              (!item.data.originData.canRegister && item.data.originData?.hasPassedExam),
        );
        // Only keep groups where validItems is not empty
        return validItems.length > 0;
      })
      .map((group) => {
        // Process each group, deduplicate and keep items with examSchedule
        const uniqueItems: { [examCode: string]: any } = {};
        group.data.forEach((item) => {
          const examCode = item.data.examCode;
          if (
            enableExamSameNameMultiRegistration
              ? item.data.originData.canRegister
              : item.data.originData.canRegister ||
                (!item.data.originData.canRegister && item.data.originData.hasPassedExam)
          ) {
            // If examCode does not exist in uniqueItems, add it
            if (!uniqueItems[examCode]) {
              uniqueItems[examCode] = item;
            } else if (item.data.originData.examSchedule) {
              // If it exists and the current item has examSchedule, update it
              uniqueItems[examCode] = item;
            } else if (item.data.originData.hasPassedExam) {
              // If it exists and the current item has examSchedule, update it
              uniqueItems[examCode] = item;
            }
          }
        });
        // Sort using localeCompare with proper options
        const sortedItems = Object.values(uniqueItems).sort((a, b) => {
          const isSpecialChar = (title: string) => {
            const regex = /[^\w\s]/;
            return regex.test(title);
          };

          const isChinese = (title: string) => {
            const regex = /[\u4e00-\u9fa5]/;
            return regex.test(title);
          };

          // Determine the type of title
          const aIsSpecial = isSpecialChar(a.title);
          const bIsSpecial = isSpecialChar(b.title);
          const aIsChinese = isChinese(a.title);
          const bIsChinese = isChinese(b.title);

          // Special characters are ranked last
          if (aIsSpecial && !bIsSpecial) return 1;
          if (!aIsSpecial && bIsSpecial) return -1;

          // Chinese characters are ranked before special characters
          if (aIsChinese && !bIsChinese) return -1;
          if (!aIsChinese && bIsChinese) return 1;

          // Otherwise, sort alphabetically
          return a.title.localeCompare(b.title, "en", { sensitivity: "base" });
        });

        // Convert uniqueItems back to an array
        return {
          ...group,
          data: sortedItems, // Get the deduplicated items
        };
      });
  };
  const filteredData = filterExamData(sections);

  return (
    <div className="px-4">
      <List>
        {filteredData.map((section, index) => {
          return (
            <div key={index}>
              <ListSubheader sx={subheader}>
                <ListItemText primary={section.title} />
              </ListSubheader>
              <List>
                {section.data.map((d, subIndex) => {
                  const data = convertData(d);
                  const canRegister = () => {
                    const { canRegister, examSchedule, hasPassedExam } = data.data.originData;
                    if (!enableExamSameNameMultiRegistration) {
                      if (examSchedule && examSchedule.canRegister === false) {
                        return false;
                      }
                      if (!canRegister && !examSchedule && hasPassedExam === true) {
                        return false;
                      }
                    }

                    return true;
                  };
                  return (
                    <div key={`${index}-${subIndex}`} className="bg-white mb-3 rounded-xl">
                      <ListItemButton
                        sx={{ backgroundColor: "white" }}
                        onClick={() => getItemClickHandler(d, canRegister())}
                      >
                        <ListItemText
                          sx={{
                            ...(canRegister() ? item : itemGrey),
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            flexGrow: 1,
                            marginRight: "8px",
                          }}
                          primary={data?.title}
                        />
                        <StatusIcon
                          status={d.status}
                          sx={{ color: canRegister() ? undefined : color.grey99 }}
                        ></StatusIcon>
                      </ListItemButton>
                    </div>
                  );
                })}
              </List>
            </div>
          );
        })}
      </List>
    </div>
  );
};

const StatusIcon = ({
  status,
  sx,
}: {
  status?: keyof typeof OnboardingExamRegistryStatus;
  sx?: object;
}) => {
  switch (status) {
    case OnboardingExamRegistryStatus.REGISTERED:
    case OnboardingExamRegistryStatus.PASSED:
      return (
        <Box sx={completeBoxStyle}>
          <CheckIcon sx={completeIconStyle} />
        </Box>
      );
    case OnboardingExamRegistryStatus.FAILED:
      return <Icon svg={SvgAssets.failure} height={32} width={32} />;
    default:
      return <ChevronRightIcon sx={sx} />;
  }
  return <></>;
};
