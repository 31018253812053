import React, { Fragment, useMemo } from "react";
import { DetailDesc } from "./detail-desc";
import { Details } from "./details";
import { Sessions } from "./sessions";
import { Attachment } from "./attachment";
import { DetailBottom } from "./detail-bottom";
import { useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import {
  EventDescriptionTypeEnum,
  EventImageTypeEnum,
  EventLabel,
  EventModeEnum,
} from "../../network/type";
import { getI18nData } from "../../../../utils/i18n/i18n.util";
import { TemplateDetails } from "./template-details";

export const EventDetail = () => {
  const { descriptionType, eventImageType, selectedEventImage, eventImage, label, sessions, mode } =
    useAppSelector((state) => state.event.eventDetailData)!;

  const showLabel = useMemo(() => {
    return (
      label === EventLabel.OPEN_FOR_REGISTRATION ||
      (label === EventLabel.CAN_WALK_IN && (!sessions || sessions?.length === 0))
    );
  }, [label, sessions]);

  const isTemplateDetails =
    getI18nData(descriptionType) === EventDescriptionTypeEnum.TEMPLATE ||
    getI18nData(descriptionType) === EventDescriptionTypeEnum.UPLOAD;

  return (
    <div
      className={`w-full h-full break-words relative ${
        mode !== EventModeEnum.ANNOUNCEMENT ? "pb-[92px]" : ""
      }`}
    >
      {showLabel && <EventLabelComponent label={label} isTemplate={isTemplateDetails} />}

      {isTemplateDetails ? (
        <TemplateDetails />
      ) : (
        <Fragment>
          {/* Banner */}
          <div className="w-full aspect-[2/1] md:h-[400px]">
            <img
              src={
                getI18nData(eventImageType) === EventImageTypeEnum.TEMPLATE
                  ? getI18nData(selectedEventImage)?.url
                  : getI18nData(eventImage)?.url
              }
              alt="banner"
              className="w-full h-full object-cover"
            />
          </div>
          {/* Content */}
          <div className="w-full flex flex-col px-4 pt-5 pb-10 md:px-[60px] md:pt-[60px] md:pb-[100px] lg:px-[100px]">
            <DetailDesc />
            <Details />
            <Sessions />
            <Attachment />
          </div>
        </Fragment>
      )}
      {mode !== EventModeEnum.ANNOUNCEMENT && <DetailBottom />}
    </div>
  );
};

const EventLabelComponent = ({
  label,
  isTemplate,
}: {
  label?: EventLabel;
  isTemplate: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`w-auto px-3 py-[3px] md:px-[31px] md:py-2 flex items-center justify-center rounded-br-xl absolute z-50 top-0 ${
        isTemplate ? "left-4" : "left-0"
      }  ${label === EventLabel.CAN_WALK_IN ? "bg-[#FFB83D]" : "bg-[#24C791]"}`}
    >
      <span className="text-xs leading-5 md:text-base font-medium text-[#ffffff]">
        {label === EventLabel.CAN_WALK_IN
          ? t("event.can_walk_in")
          : t("event.open_for_registration")}
      </span>
    </div>
  );
};
