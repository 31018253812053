import {
  CandidateStatus,
  OnboardingExamRegistryStatus,
  OnboardingLessonStatus,
  OnboardingQuiz,
  OnboardingQuizStatus,
  OnboardingRegistrationInfo,
  OnboardingTrainingCourse,
  OnboardingTypeExam,
  Timeslot,
} from "../types";
import { flatten, get, orderBy, sortBy } from "lodash";
import { getTargetCandidate, getToken, parseJwt } from "../utils";
import moment from "moment";
import store from "../../../redux/store";

const getCandidateStatus = () => {
  const targetCandidate = getTargetCandidate();
  return targetCandidate.status;
};
export const convertCoursesResponse = (
  rawResponse: any,
  candidateId: string,
  onboardingForm: any,
): { data: OnboardingTrainingCourse } => {
  const courseResults = get(rawResponse, "data.trainingHistory.courseResults");
  const allCoursesCompleted = courseResults.every(
    (course: any) => course.courseStatus === OnboardingLessonStatus.COMPLETED,
  );
  const courseData = courseResults.map((course: any) => {
    const { courseQuizResult } = course;
    const rawToken = getToken();
    const tokenData = rawToken ? parseJwt(rawToken) : {};

    const isReadonly =
      tokenData.sub !== candidateId ||
      ![CandidateStatus.IN_PROGRESS].includes(getCandidateStatus()) ||
      allCoursesCompleted;
    return {
      courseId: course.courseId,
      readonly: isReadonly,
      name: course.name,
      type: "ONBOARDING_TRAINING",
      code: course.name,
      status: course.courseStatus,
      lessons: course.lessonResults.map((lesson: any, index: number) => {
        const { lessonQuizResult } = lesson;
        const hasNext = index < course.lessonResults.length - 1;
        return {
          readonly: isReadonly,
          hasNext,
          lessonId: lesson.lessonId,
          name: lesson.name,
          code: lesson.code,
          courseName: course.name,
          status: lesson.lessonMaterialStatus,
          materialUpload: lesson.materialLink,
          ...(lessonQuizResult
            ? {
                quiz: {
                  readonly: isReadonly,
                  quizId: lessonQuizResult.quizId,
                  name: lessonQuizResult.name,
                  lessonId: lesson.lessonId,
                  courseId: course.courseId,
                  code: lessonQuizResult.code,
                  score: lessonQuizResult.score,
                  failedCount: 0,
                  passedCount: 0,
                  status:
                    lesson.lessonMaterialStatus !== OnboardingLessonStatus.COMPLETED
                      ? OnboardingQuizStatus.DISABLED
                      : lessonQuizResult.quizStatus,
                },
              }
            : {}),
          ...(courseQuizResult && !hasNext
            ? {
                courseQuiz: {
                  readonly: isReadonly,
                  quizId: courseQuizResult.quizId,
                  courseId: course.courseId,
                  name: courseQuizResult.name,
                  code: courseQuizResult.code,
                  score: courseQuizResult.score,
                  failedCount: 0,
                  passedCount: 0,
                  status: course.lessonResults.every(
                    (lesson: any) => lesson.lessonStatus === OnboardingLessonStatus.COMPLETED,
                  )
                    ? courseQuizResult.quizStatus
                    : OnboardingQuizStatus.DISABLED,
                },
              }
            : {}),
        };
      }),
      ...(courseQuizResult
        ? {
            quiz: {
              readonly: isReadonly,
              quizId: courseQuizResult.quizId,
              courseId: course.courseId,
              name: courseQuizResult.name,
              code: courseQuizResult.code,
              score: courseQuizResult.score,
              failedCount: 0,
              passedCount: 0,
              status: course.lessonResults.every(
                (lesson: any) => lesson.lessonStatus === OnboardingLessonStatus.COMPLETED,
              )
                ? courseQuizResult.quizStatus
                : OnboardingQuizStatus.DISABLED,
            },
          }
        : {}),
    };
  });
  const filteredCourses = courseData.filter(
    (course: OnboardingTrainingCourse) => course.lessons?.length,
  );
  return {
    data: filteredCourses,
  };
};
export const convertQuizResponse = (
  rawResponse: any,
  candidateId: string,
): {
  data: Pick<
    OnboardingQuiz,
    "questions" | "type" | "code" | "passingScore" | "maxFailCount" | "readonly"
  >;
} => {
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const isReadonly =
    tokenData.sub !== candidateId || ![CandidateStatus.IN_PROGRESS].includes(getCandidateStatus());
  const quizTemplateData = rawResponse.data;
  const passingScore = quizTemplateData.passingScore;
  const questions = quizTemplateData.quizContent.pages[0].elements;
  return {
    data: {
      readonly: isReadonly,
      type: quizTemplateData.quizType,
      code: quizTemplateData.quizCode,
      passingScore,
      maxFailCount: Math.floor(((100 - passingScore ?? 0) / 100) * questions.length),
      questions,
    },
  };
};
export const convertExamRegistryResponse = (
  rawResponse: any,
  candidateId?: any,
): {
  data: OnboardingTypeExam[];
} => {
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const data = rawResponse.data;
  // get the status of whole exam registration module
  const flattenedData = flatten(data);
  const isAllCompleted = flattenedData.every(
    (exam: any) => exam.status === OnboardingExamRegistryStatus.COMPLETED,
  );
  // set readonly status based on the candidate status and the status of the whole exam registration module
  const isReadonly =
    tokenData.sub !== candidateId ||
    ![CandidateStatus.IN_PROGRESS].includes(getCandidateStatus()) ||
    isAllCompleted;
  const typeExams = sortBy(Object.keys(data), (x) => x.toLowerCase()).map((type) => ({
    type,
    exams: orderBy(
      data[type].map((exam: any) => {
        return {
          readonly: isReadonly,
          originalName: exam.examName.original,
          name: exam.examName.localized,
          type: exam.examType,
          status: exam.status,
          score: exam.score ?? 0,
          registrationId: exam.registrationId,
          examCode: exam.examCode,
          examResult: exam.examResult ? exam.examResult : undefined,
          registeredInfo: exam.registeredInfo ? exam.registeredInfo : undefined,
          originData: exam,
        };
      }),
      ["id"],
      ["asc"],
    ),
  }));
  return {
    data: typeExams,
  };
};

export const convertExamCentresResponse = (
  rawResponse: any,
): {
  data: OnboardingRegistrationInfo[];
} => {
  const data = rawResponse.data;
  return {
    data: data.map((item: any) => {
      return {
        examCentre: item,
      };
    }),
  };
};

export const convertExamTimeslotsResponse = (
  rawResponse: any,
): {
  data: Timeslot[];
} => {
  const data = rawResponse.data;
  const currencies = get(store.getState(), "onboarding.configs.Global.region.currencies", []);
  return {
    data: data.map((item: any) => {
      const currencySymbol = currencies.length
        ? currencies.find((currency: any) => currency.code === (item as any)?.examFeeCurrencyCode)
            ?.symbol ?? currencies[0].symbol
        : "";
      return {
        name: `${item.examDate}, ${moment(item.examDate, "YYYY-MM-DD").format("ddd")}, ${
          item.examStartTime
        }-${item.examEndTime}`,
        value: item.examScheduleId,
        examStartTime: item?.examStartTime,
        examEndTime: item?.examEndTime,
        examDate: item?.examDate,
        examCentre: item?.examCentre,
        address: item?.address,
        remarks: item?.remarks,
        remainingQuota: item?.remainingQuota,
        examFee: item?.examFee ? `${currencySymbol} ${item?.examFee}` : "",
        startRegistrationDate: item?.startRegistrationDate,
        lastRegistrationDate: item?.lastRegistrationDate,
        examMode: item?.examMode,
        deviceType: item?.deviceType,
      };
    }),
  };
};
