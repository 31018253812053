import { Description } from "@mui/icons-material";
import { PaymentType } from "../../../../types";

export const ExamTemplate = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "boolean",
          name: "hasPassedExam",
          title: "I have taken and passed this exam",
          isRequired: true,
          disallowedActions: "delete",
          labelTrue: "app.select.yes",
          labelFalse: "app.select.no",
          valueTrue: true,
          valueFalse: false,
          requiredErrorText: "This field is mandatory",
        },

        {
          type: "boolean",
          visibleIf: `{hasPassedExam} = true`,
          name: "hasLicenseProof",
          title: "I have a proof for this passed exam",
          isRequired: true,
          disallowedActions: "delete",
          labelTrue: "app.select.yes",
          labelFalse: "app.select.no",
          valueTrue: true,
          valueFalse: false,
          requiredErrorText: "This field is mandatory",
        },

        {
          type: "html",
          name: "content",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = false`,
          html: `
            <div >
              <p style="font-size: 0.875rem; font-weight: 500; line-height: 20px; text-align: left; margin-bottom: 10px; color:#333">
                request_a_copy_of_exam_certificate
              </p>
              <p style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color: #999999;">
                please_pay_your_exam_certificate_request
              </p>

            </div>
          `,
        },

        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = true`,
          name: "examScore",
          inputType: "number",
          title: "Exam Score",
          isRequired: true,
          isPII: true,
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = true`,
          name: "examDate",
          title: "Exam Date",
          isRequired: true,
          disallowedActions: "delete",
          isPII: true,
          inputType: "date",
          requiredErrorText: "This field is mandatory",
          maxValueExpression: "today",
        },
        {
          type: "file",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = true`,
          name: "proofInfos",
          title: "Exam Proof Upload",
          description:
            "Please only upload PDF/ JPG/ JPEG/ PNG <br/>Each file should not be larger than 4MB",
          isRequired: true,
          allowMultiple: true,
          acceptedTypes: ".jpeg, .jpg, .png, .pdf",
          waitForUpload: true,
          maxSize: 4194304,
          needConfirmRemoveFile: true,
          acceptedTypesErrorText: {},
          convertImageToPdf: true,
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = false`,
          name: "officialReceiptNumber",
          title: "Official Receipt Number",
          isRequired: true,
          isPII: true,
          requiredErrorText: "This field is mandatory",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
        },
        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = false`,
          name: "paymentReceiptDate",
          title: "Payment Receipt Date",
          isRequired: true,
          isPII: true,
          inputType: "date",
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "dropdown",
          visibleIf: `{hasPassedExam} = false`,
          name: "examCentre",
          title: "Exam Venue",
          isRequired: true,
          choices: [{}, {}],
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "dropdown",
          visibleIf: `{hasPassedExam} = false`,
          name: "examScheduleId",
          title: "Exam Schedule",
          itemComponent: "exam-schedule-item",
          searchEnabled: false,
          isRequired: true,
          choices: [{}, {}],
          // inputType: "date",
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "text",
          visibleIf: "{examScheduleId} notempty && {hasPassedExam} = false",
          name: "remainingQuota",
          title: "remaining_quota",
          defaultValue: "",
          enableIf: "false",
        },
        {
          type: "text",
          visibleIf: "{examScheduleId} notempty && {hasPassedExam} = false  && {examFee} notempty",
          name: "examFee",
          title: "Exam Fee",
          defaultValue: "",
          enableIf: "false",
        },
        {
          type: "text",
          visibleIf:
            "{examScheduleId} notempty && {hasPassedExam} = false && {startRegistrationDate} notempty",
          name: "startRegistrationDate",
          title: "Start Registration Time",
          defaultValue: "",
          enableIf: "false",
        },
        {
          type: "text",
          visibleIf:
            "{examScheduleId} notempty && {hasPassedExam} = false && {lastRegistrationTime} notempty",
          name: "lastRegistrationTime",
          title: "Last Registration Time",
          defaultValue: "",
          enableIf: "false",
        },
        {
          type: "comment",
          visibleIf: "{examScheduleId} notempty && {hasPassedExam} = false && {address} notempty",
          name: "address",
          title: "Address",
          rows: -1,
          defaultValue: "",
          enableIf: "false",
        },
        {
          type: "comment",
          visibleIf: "{examScheduleId} notempty && {hasPassedExam} = false && {remarks} notempty",
          name: "remarks",
          title: "Remarks",
          rows: -1,
          defaultValue: "",
          enableIf: "false",
        },
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "radiogroup",
          name: "paymentType",
          isRequired: true,
          title: "Recruitment.payment_type",
          choices: [
            { text: "Recruitment.full_payment", value: PaymentType.FullPayment },
            { text: "Recruitment.code_now_pay_later", value: PaymentType.CodeNowPayLater },
          ],
          requiredErrorText: "Application type is required",
        },
        {
          type: "text",
          visibleIf: `{paymentType} = '${PaymentType.FullPayment}'`,
          name: "licenseFeeReceiptNo",
          isRequired: true,
          title: "License Fee Official Receipt Number",
          requiredErrorText: "This field is mandatory",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
        },
        {
          type: "text",
          visibleIf: `{paymentType} = '${PaymentType.FullPayment}'`,
          inputType: "date",
          name: "licenseFeeReceiptDate",
          isRequired: true,

          title: "License Fee Official Receipt Date",
          requiredErrorText: "This field is mandatory",
          maxValueExpression: "today",
        },
        {
          type: "text",
          visibleIf:
            "{paymentType} = '" +
            PaymentType.FullPayment +
            "' || {paymentType} = '" +
            PaymentType.CodeNowPayLater +
            "'",
          name: "examFeeReceiptNo",
          isRequired: true,
          title: "Exam Fee Official Receipt Number",
          requiredErrorText: "This field is mandatory",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
        },
        {
          type: "text",
          visibleIf:
            "{paymentType} = '" +
            PaymentType.FullPayment +
            "' || {paymentType} = '" +
            PaymentType.CodeNowPayLater +
            "'",
          inputType: "date",
          name: "examFeeReceiptDate",
          isRequired: true,
          title: "Exam Fee Official Receipt Date",
          requiredErrorText: "This field is mandatory",
          maxValueExpression: "today",
        },
      ],
    },
    {
      name: "page3",
      elements: [
        {
          type: "text",
          name: "firstName",
          title: "First Name",
          isRequired: true,
          requiredErrorText: "Common.this_field_is_mandatory",
        },
        {
          type: "text",
          name: "lastName",
          title: "Last Name",
          isRequired: true,
          requiredErrorText: "Common.this_field_is_mandatory",
        },
        {
          type: "text",
          name: "localName",
          title: "Chinese Name",
        },
        {
          type: "boolean",
          name: "gender",
          title: "Gender",
          isRequired: true,
          requiredErrorText: "Common.please_select_gender",
          labelTrue: "Common.male",
          labelFalse: "Common.female",
          valueTrue: "M",
          valueFalse: "F",
        },

        {
          type: "paneldynamic",
          name: "regionPhone",
          title: "Phone Number",
          titleLocation: "top",
          isRequired: true,
          templateElements: [
            {
              type: "dropdown",
              name: "regionCode",
              width: "36%",
              minWidth: "36%",
              maxWidth: "36%",
              startWithNewLine: false,
              allowClear: false,
              title: "Country Code",
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Please select option where applicable",
              fieldType: "Standard",
              choices: ["+852", "+853", "+62"],
              modalConfig: {
                modalTitle: "Common.international_country_codes",
                showSearch: true,
              },
            },
            {
              type: "text",
              name: "phoneNumber",
              width: "64%",
              minWidth: "64%",
              maxWidth: "64%",
              startWithNewLine: false,
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Common.this_field_is_mandatory",
              validators: [
                {
                  type: "expression",
                  text: "Common.please_input_real_phone",
                  expression:
                    "regionPhoneValidate({#regionPhone[0].regionCode},{regionPhone[0].phoneNumber})",
                },
              ],
              inputType: "tel",
            },
          ],
          allowAddPanel: false,
          allowRemovePanel: false,
          panelCount: 1,
          minPanelCount: 1,
          maxPanelCount: 1,
        },
        {
          type: "text",
          name: "email",
          title: "Common.email_address",
          isRequired: true,
          requiredErrorText: "Common.please_enter_a_valid_email_address",
          validators: [
            {
              type: "email",
              text: "Common.please_enter_a_valid_email_address",
            },
          ],
          inputType: "email",
        },
        {
          type: "text",
          inputType: "date",
          name: "birthday",
          title: "Common.birthday",
          isRequired: true,
          requiredErrorText: "Common.this_field_is_mandatory",
        },

        {
          type: "boolean",
          name: "personalInfoAgreement1",
          title: "personal_info_agreement1",
          titleLocation: "left",
          requiredErrorText: "Common.you_must_agree_to_the_privacy_agreement",
          validators: [
            {
              type: "expression",
              text: "Common.you_must_agree_to_the_privacy_agreement",
              expression: "{personalInfoAgreement1} = true",
            },
          ],
          fieldType: "Standard",
          labelTrue: "app.select.yes",
          labelFalse: "app.select.no",
          preset: "confirm_checkbox_round",
        },
        {
          type: "boolean",
          name: "personalInfoAgreement2",
          title: "personal_info_agreement2",
          titleLocation: "left",
          requiredErrorText: "Common.you_must_agree_to_the_privacy_agreement",
          validators: [
            {
              type: "expression",
              text: "Common.you_must_agree_to_the_privacy_agreement",
              expression: "{personalInfoAgreement2} = true",
            },
          ],
          fieldType: "Standard",
          labelTrue: "app.select.yes",
          labelFalse: "app.select.no",
          preset: "confirm_checkbox_round",
        },
        {
          type: "boolean",
          name: "personalInfoAgreement3",
          title: "personal_info_agreement3",
          titleLocation: "left",
          requiredErrorText: "Common.you_must_agree_to_the_privacy_agreement",
          validators: [
            {
              type: "expression",
              text: "Common.you_must_agree_to_the_privacy_agreement",
              expression: "{personalInfoAgreement3} = true",
            },
          ],
          fieldType: "Standard",
          labelTrue: "app.select.yes",
          labelFalse: "app.select.no",
          preset: "confirm_checkbox_round",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};
