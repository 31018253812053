import { OnboardingFormModuleName, OnboardingFormSectionName } from "../modules/onboarding/types";
import { getViewPort, getBreakpoint } from "../utils/common-utils";

export const NOT_FOUND_ERROR_PAGE_PATH = "/error/not-found";

export const FileType = {
  PDF: "pdf",
  VIDEO: ["mp4", "avi", "wmv"],
  IMG: [
    "bmp",
    "jpg",
    "jpeg",
    "png",
    "tif",
    "gif",
    "pcx",
    "tga",
    "exif",
    "fpx",
    "svg",
    "psd",
    "cdr",
    "pcd",
    "dxf",
    "ufo",
    "eps",
    "ai",
    "raw",
    "WMF",
    "webp",
  ],
};

export const possibleDateFromats = [
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "YYYY-MM-DD",
  "DD-MM-YYYY",
  "MMMM DD, YYYY", // e.g., February 21, 2007
  "DD MMM YYYY", // e.g., 21 Feb 2007
];

// Events
export const EVENT_OPEN_LINK_WITHIN_PAGE = "PUBLIC_PORTAL_WEB: openLink";

export const onboardingExcludedMenuModules = [
  OnboardingFormModuleName.PreQuestions,
  OnboardingFormModuleName.TermsNConditions,
  OnboardingFormModuleName.ESignature,
];
export const onboardingPreModules = [OnboardingFormModuleName.PreQuestions];
export const onboardingPostModules = [
  OnboardingFormModuleName.TermsNConditions,
  OnboardingFormModuleName.ESignature,
];

export const isPhone =
  getViewPort().width < getBreakpoint("lg") && navigator.userAgent.match(/iPad/i) === null;

export enum NameCompositionEnum {
  firstName = "firstName",
  lastName = "lastName",
}
