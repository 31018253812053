import dayjs from "dayjs";
import { constants } from "../../../routes/constants";
import { getI18n } from "react-i18next";
import { PromptModalProps } from "../../../components/modal/prompt";
import {
  Code,
  EventCalendarType,
  EventQRcodeDetail,
  EventType,
  Session,
  EventDetailType,
} from "../network/type";
import { getI18nData } from "../../../utils/i18n/i18n.util";
import moment from "moment";

/**
 * @description format event time
 * @param startTime
 * @param endTime
 * @returns ep: 31/10/2023 15:00-18:00 or 22/10/2023 - 23/10/2023
 */
export const formatEventTime = (startTime: string, endTime: string) => {
  return dayjs(startTime).format(constants.Default_Date_Format) ===
    dayjs(endTime).format(constants.Default_Date_Format)
    ? `${dayjs(startTime).format(constants.Date_Minute_Scale_Format)}-${dayjs(endTime).format(
        constants.Time_Format,
      )}`
    : `${dayjs(startTime).format(constants.Default_Date_Format)}-${dayjs(endTime).format(
        constants.Default_Date_Format,
      )}`;
};

/**
 * @description format event registration period
 * @param regStartDate
 * @param regEndDate
 * @returns ep: 31/10/2023 09:00-03/11/2023 20:00
 */
export const formatEventRegistrationDate = (regStartDate: string, regEndDate: string) => {
  return `${dayjs(regStartDate).format(constants.Date_Minute_Scale_Format)}-${dayjs(
    regEndDate,
  ).format(constants.Date_Minute_Scale_Format)}`;
};

export enum UnavailableType {
  NOT_OPEN_YET = "NOT_OPEN_YET",
  REGISTRATION_CLOSE = "REGISTRATION_CLOSE",
  EVENT_FULL = "EVENT_FULL",
  SESSION_FULL = "SESSION_FULL",
  AGENT_REQUIRED = "AGENT_REQUIRED",
  INVALID_AGENT = "INVALID_AGENT",
  REGISTER_DUPLICATED = "REGISTER_DUPLICATED",
  WALKIN_REGISTER_DUPLICATED = "WALKIN_REGISTER_DUPLICATED",
  AGENT_QUOTA_FULL = "AGENT_QUOTA_FULL",
}

export const getUnavailableModalConfig = (type: string): PromptModalProps | undefined => {
  const title = getI18n().t("event.registration_unavailable_modal_title");

  switch (type) {
    case UnavailableType.NOT_OPEN_YET:
      return {
        title,
        message: getI18n().t("event.not_open_registration_unavailable_modal_desc"),
      };
    case UnavailableType.REGISTRATION_CLOSE:
      return {
        title,
        message: getI18n().t("event.registration_close_registration_unavailable_modal_desc"),
      };
    case UnavailableType.AGENT_QUOTA_FULL:
    case UnavailableType.EVENT_FULL:
      return {
        title,
        message: getI18n().t("event.event_full_registration_unavailable_modal_desc"),
      };
    case UnavailableType.SESSION_FULL:
      return {
        iconName: "failure",
        title: getI18n().t("event.sorry"),
        message: getI18n().t("event.event_session_full_modal_desc"),
        confirmButtonText: getI18n().t("global.text.confirm"),
        cancelButtonText: getI18n().t("cancel"),
      };
    case UnavailableType.INVALID_AGENT:
      return {
        iconName: "failure",
        title: getI18n().t("event.error"),
        message: getI18n().t("event.invalid_agent_registration_unavailable_modal_desc"),
      };
    case UnavailableType.WALKIN_REGISTER_DUPLICATED:
    case UnavailableType.REGISTER_DUPLICATED:
      return {
        iconName: "notice",
        title: getI18n().t("event.attention"),
        message: getI18n().t("event.registered_duplicated_registration_unavailable_modal_desc"),
        confirmButtonText: getI18n().t("event.check_in"),
        cancelButtonText: getI18n().t("cancel"),
      };
    default:
      return;
  }
};

export const getEventType = (type: EventType) => {
  let curType = "";

  switch (type) {
    case EventType.PHYSICAL:
      curType = getI18n().t("event.physical");
      break;
    case EventType.VIRTUAL:
      curType = getI18n().t("event.virtual");
      break;
    case EventType.HYBRID:
      curType = getI18n().t("event.hybrid");
      break;
    default:
      break;
  }

  return curType;
};

export const openFromApp = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("type") === "app";
};

export const getTitleByCode = (code: Code) => {
  let title = "";

  switch (code) {
    case Code.INVALID_LINK:
      title = getI18n().t("event.invalid_link");
      break;
    case Code.EVENT_EXPIRED:
      title = getI18n().t("event.event_finished");
      break;
    case Code.EVENT_CANCELLED:
      title = getI18n().t("event.event_cancelled");
      break;
    default:
      break;
  }

  return title;
};

export const sessionFilter = (sessionId: string, data?: Session[]): Session | undefined => {
  for (const item of data || []) {
    if (item._id === sessionId) {
      return item;
    }
    if (item.subSessions) {
      const foundItem = sessionFilter(sessionId, item.subSessions);
      if (foundItem) {
        return foundItem;
      }
    }
  }

  return undefined;
};

export const formatCalendarEventData = (
  qrCodeShortenCode: string,
  data?: EventQRcodeDetail | EventDetailType,
) => {
  const { name, startTime, endTime, venue, type, tags, onlineMeetingLink } = data ?? {};

  return {
    title: getI18nData(name),
    tags: getI18nData(tags),
    qrCodeShortenCode,
    startTime: startTime ? moment(new Date(startTime)).utc().format("YYYYMMDDTHHmmss[Z]") : "",
    endTime: endTime ? moment(new Date(endTime)).utc().format("YYYYMMDDTHHmmss[Z]") : "",
    location: type === EventType.VIRTUAL ? onlineMeetingLink || "" : getI18nData(venue),
    onlineMeetingLink,
  };
};

export const downloadICS = (data: EventCalendarType) => {
  const { title, startTime, endTime, qrCodeShortenCode, tags, onlineMeetingLink, location } = data;

  const qrCodeUrl = new URL(window.location.href).origin + `/web/event/${qrCodeShortenCode}`;
  const tagDesc = tags && tags?.length > 0 ? `${tags?.join(", ")}.\\n` : "";
  const desc = `${tagDesc}${getI18n().t("event.qr_code_desc", { qrCodeUrl })}${
    onlineMeetingLink
      ? `\\n${getI18n().t("event.online_meeting_link_desc", { onlineMeetingLink })}`
      : ""
  }`;

  const icsContent = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    `UID:${qrCodeShortenCode}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${desc}`,
    `LOCATION:${location}`,
    `DTSTAMP:${moment().utc().format("YYYYMMDDTHHmmss[Z]")}`,
    `DTSTART:${startTime}`,
    `DTEND:${endTime}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ]
    .join("\r\n")
    .trim();

  const blob = new Blob([icsContent], { type: "text/calendar;charset=utf-8" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = title;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
