export enum TemplateField {
  idType = "idType",
  idNum = "idNum",
  invitation = "invitation",
}
export const CandidateDataTemplate = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "firstName",
          title: "First Name",
          isRequired: true,
          requiredErrorText: "Common.this_field_is_mandatory",
        },
        {
          type: "text",
          name: "lastName",
          title: "Last Name",
          isRequired: true,
          requiredErrorText: "Common.this_field_is_mandatory",
        },
        {
          type: "boolean",
          name: "gender",
          title: "Gender",
          isRequired: true,
          requiredErrorText: "Common.please_select_gender",
          labelTrue: "Common.male",
          labelFalse: "Common.female",
          valueTrue: "M",
          valueFalse: "F",
        },
        {
          type: "text",
          inputType: "date",
          name: "birthday",
          title: "Common.birthday",
          isRequired: true,
          requiredErrorText: "Common.this_field_is_mandatory",
        },
        {
          type: "paneldynamic",
          name: "regionPhone",
          title: "Phone Number",
          titleLocation: "top",
          readOnly: true,
          isRequired: true,
          templateElements: [
            {
              type: "dropdown",
              name: "regionCode",
              width: "36%",
              minWidth: "36%",
              maxWidth: "36%",
              readOnly: true,
              startWithNewLine: false,
              allowClear: false,
              title: "Country Code",
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Please select option where applicable",
              fieldType: "Standard",
              choices: ["+852", "+853", "+62"],
              modalConfig: {
                modalTitle: "Common.international_country_codes",
                showSearch: true,
              },
            },
            {
              type: "text",
              name: "phoneNumber",
              readOnly: true,
              width: "64%",
              minWidth: "64%",
              maxWidth: "64%",
              startWithNewLine: false,
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Common.this_field_is_mandatory",
              validators: [
                {
                  type: "expression",
                  text: "Common.please_input_real_phone",
                  expression:
                    "regionPhoneValidate({#regionPhone[0].regionCode},{regionPhone[0].phoneNumber})",
                },
              ],
              inputType: "tel",
            },
          ],
          allowAddPanel: false,
          allowRemovePanel: false,
          panelCount: 1,
          minPanelCount: 1,
          maxPanelCount: 1,
        },
        {
          type: "text",
          name: "email",
          title: "Common.email_address",
          isRequired: true,
          requiredErrorText: "Common.please_enter_a_valid_email_address",
          validators: [
            {
              type: "email",
              text: "Common.please_enter_a_valid_email_address",
            },
          ],
          inputType: "email",
        },
        {
          type: "radiogroup",
          name: "languagePreference",
          title: "Language Preference",
          isRequired: true,
          choices: [
            { value: "zh-Hant", text: "繁體中文" },
            { value: "en", text: "English" },
            // { value: "my", text: "Malaysia" },
          ],
        },
        {
          type: "boolean",
          name: "privacyPolicyAgreement",
          title: "personal_info_collection_statement_public_portal",
          titleLocation: "left",
          requiredErrorText: "Common.you_must_agree_to_the_privacy_agreement",
          validators: [
            {
              type: "expression",
              text: "Common.you_must_agree_to_the_privacy_agreement",
              expression: "{privacyPolicyAgreement} = true",
            },
          ],
          fieldType: "Standard",
          labelTrue: "Yes",
          labelFalse: "No",
          preset: "confirm_checkbox_round",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};
