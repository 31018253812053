import { NavigateFunction } from "react-router-dom";
import { getToken, parseJwt } from "../../modules/onboarding/utils";

let _env: EnvData = {};

export type EnvData = {
  toastRef?: any;
  modalRef?: any;
  intlRef?: any;
  dispatch?: any;
  navigate?: NavigateFunction;
  onboardingCurrentSection?: any;
  idTypeConfigs?: any;
  publicConfig?: any;
};

function setEnv(env: EnvData): void {
  _env = env;
}

function setSafetyEnv(env: EnvData): void {
  _env = { ..._env, ...(env || {}) };
}

function getEnv(): EnvData {
  return _env;
}

function setGlobalToastRef(toastRef: any): void {
  _env = { ..._env, toastRef };
}

function setGlobalDispatch(dispatch: any): void {
  _env = { ..._env, dispatch };
}

function setGlobalNavigate(navigate: NavigateFunction): void {
  _env = { ..._env, navigate };
}

function getGlobalToastRef(): any {
  return _env.toastRef || {};
}

function setGlobalIntlRef(intlRef: any): void {
  _env = { ..._env, intlRef };
}

function getGlobalIntlRef(): any {
  return _env.intlRef || {};
}

function getGlobalDispatch() {
  return _env.dispatch;
}

function getGlobalNavigate() {
  return _env.navigate!;
}

function setOnboardingCurrentSection(onboardingCurrentSection: any): void {
  _env = { ..._env, onboardingCurrentSection };
}

function getOnboardingCurrentSection(): any {
  return _env.onboardingCurrentSection || {};
}

function setIdTypeConfigs(idTypeConfigs: any): void {
  _env = { ..._env, idTypeConfigs };
}

function getIdTypeConfigs(): any {
  return _env.idTypeConfigs || {};
}

function getUserName(): any {
  return sessionStorage.getItem("USER_NAME") ?? "";
}

function setGlobalPublicConfig(publicConfig: any): void {
  _env = { ..._env, publicConfig };
}

function getGlobalPublicConfig(): any {
  return _env.publicConfig || {};
}

function getTokenUserSub(): string {
  const tokenFromStorage = getToken();
  const tokenPayload = parseJwt(tokenFromStorage);
  return tokenPayload?.sub;
}

export const GlobalHelper = {
  // setter
  setEnv,
  setSafetyEnv,
  setGlobalToastRef,
  setGlobalIntlRef,
  setGlobalDispatch,
  setGlobalNavigate,
  setOnboardingCurrentSection,
  setIdTypeConfigs,
  setGlobalPublicConfig,

  //getter
  getEnv,
  getGlobalToastRef,
  getGlobalIntlRef,
  getGlobalDispatch,
  getGlobalNavigate,
  getOnboardingCurrentSection,
  getIdTypeConfigs,
  getUserName,
  getGlobalPublicConfig,
  getTokenUserSub,
};
