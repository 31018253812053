import React, { FC, memo, useMemo } from "react";
import Icon from "../../icon/icon";
import { SvgAssets } from "../../icon/assets";

type ComponentProps = {};

export const ToastLoadingComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  return (
    <div className="w-full h-full fixed left-0 top-0 z-50 flex items-center justify-center">
      <div className="px-6 py-4 bg-black bg-opacity-80 rounded-xl flex flex-col items-center justify-center">
        <Icon svg={SvgAssets.toastLoading} width={32} height={32} />
        <span className="text-xs font-medium mt-2 text-white">Loading</span>
      </div>
    </div>
  );
});
