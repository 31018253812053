import React, { useEffect, useMemo, useState } from "react";
import { Button, FormControl } from "@mui/material";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
// import { json } from "../json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchConfig } from "../../../modules/agent-referral-link/redux/referral-link-slice";
import {
  convert2SurveyLocale,
  formatCity,
  formatCountryCode,
  getElementByName,
  setElementChoice,
  setElementProps,
} from "./survey.util";
import i18next from "i18next";
import "./survey-override.css";
import { initSurvey } from "../init-survey";
import { PopoverComponent } from "../../../modules/onboarding/components/surveyjs/popover/popover.component";

interface Props {
  json: object | undefined;
  onSubmit: (obj: any) => void;
  disabled?: boolean;
}
export const SurveyComponent: React.FC<Props> = ({ onSubmit, json }) => {
  initSurvey();
  const dispatch = useDispatch();
  const curLang = i18next.language;
  // const [valid, setValid] = useState(false);
  const hashEntry = useSelector<RootState>((state) => state.common?.hashEntry) as any;
  // regionPhone & city config
  const dropdownConfig = useSelector<RootState>((state) => state.referralLink.dropdown) as any;
  const [survey, setSurvey] = useState<Model | undefined>(undefined);

  const isPreview = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("preview") === "1";
  }, []);

  useEffect(() => {
    if (!isPreview || !survey) {
      return;
    }
    // disable input on preview mode
    const element = document.getElementById("custom-survey");
    if (element) {
      element.style.pointerEvents = "none";
    }
  }, [isPreview, survey]);

  useEffect(() => {
    if (!dropdownConfig) {
      return;
    }
    const city = getElementByName(json, "city");
    let jsonData = json;
    if (city) {
      const cityData = formatCity(dropdownConfig.location);
      jsonData = setElementChoice(json, "city", cityData);
    }
    const regionPhone = getElementByName(json, "phoneNumber");
    if (regionPhone) {
      const { region } = hashEntry?.data;
      const { value, defaultValue } = formatCountryCode(
        dropdownConfig.regionPhone,
        region.toLocaleLowerCase(),
      );
      jsonData = setElementChoice(json, "phoneNumber", value, defaultValue);
    }
    // update dateOfBirth to 'date-picker'
    jsonData = setElementProps(json, "dateOfBirth", "date-picker", "type");
    const survey = new Model(jsonData);
    survey.questionErrorLocation = "bottom";

    // check survey validation when the "Complete" button is clicked
    survey.onComplete.add(function (sender, options) {
      if (!survey.isValid) {
        // prevent completing the survey if it's invalid
        // options.allowComplete = false;
      }
      onSubmit(sender.data);
    });

    survey.onErrorCustomText.add(function (sender, options) {
      const errorText = options.error?.text;
      if (!errorText) {
        /**
         * override the default-message(no i18n needed) of required field
         * 'Response required.' -> 'Field is required'
         */
        options.text = "Field is required";
      }
    });

    survey.onGetQuestionTitleActions.add((_, options) => {
      if (options.question) {
        options.titleActions = [
          {
            id: "show-description",
            iconName: "icon-description-show",
            component: "custom-popover",
          },
        ];
      }
    });

    // final survey model
    setSurvey(survey);
  }, [dropdownConfig, json]);

  useEffect(() => {
    if (!hashEntry || !hashEntry.data) {
      return;
    }
    const { region, channel } = hashEntry.data;
    dispatch<any>(fetchConfig({ region, channel }));
  }, [hashEntry]);

  // check survey validation when a question is answered
  // survey.onValueChanged.add(function (sender, options) {
  //   console.log("sender====", sender.data);
  //   // const hasErrors = survey.hasErrors();
  //   // setValid(!hasErrors);
  // });

  // const submitForm = () => onSubmit(survey);

  if (!survey) {
    return null;
  }
  return (
    <Survey
      id="custom-survey"
      className="custom"
      model={survey}
      locale={convert2SurveyLocale(curLang)}
    />
  );
};
