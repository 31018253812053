import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getI18n } from "react-i18next";
import storage from "redux-persist/lib/storage";
import {
  getCandidateProfile,
  login,
  refreshLoginToken,
  updateCandidateProfile,
} from "../network/network";
import { CandidateProfile, LoginParams } from "../network/type";
import { parseJwt } from "../../onboarding/utils";
import { fetchGlobalConfig, fetchRecruitmentPublicConfig } from "../../onboarding/network/network";
export const authPersistConfig = {
  key: "auth",
  storage,
};

export const initialState: AuthenticationState = {
  isLoading: false,
  token: undefined,
  username: undefined,
  error: null,
};

export interface AuthenticationState {
  requesting?: "idle" | "pending" | "fulfilled" | "rejected";
  token?: any;
  refreshToken?: any;
  username?: undefined;
  error?: string | null;
  isLoading: boolean;
  candidateProfile?: CandidateProfile;
  autoLogoutMinutes?: number;
}

export const loginAsync = createAsyncThunk("auth/token", async (params: LoginParams) => {
  try {
    const result = await login(params);
    sessionStorage.setItem("USER_NAME", params.username);
    return result;
  } catch (error) {
    throw error;
  }
});

export const refreshTokenAsync = createAsyncThunk("auth/tokenRefresh", async () => {
  try {
    const result = await refreshLoginToken();
    return result;
  } catch (error) {
    throw error;
  }
});

export const getCandidateProfileAsync = createAsyncThunk(
  "candidates/profile",
  async (candidateId: string) => {
    try {
      const result = await getCandidateProfile(candidateId);
      return result;
    } catch (error) {
      throw error;
    }
  },
);

export const updateCandidateProfileAsync = createAsyncThunk(
  "candidates/profile/update",
  async (params: { candidateData: any }) => {
    try {
      const result = await updateCandidateProfile(params);
      return result;
    } catch (error) {
      throw error;
    }
  },
);

export const getGlobalConfigAsync = createAsyncThunk("globalConfig", async () => {
  try {
    const result = await fetchGlobalConfig();
    return result;
  } catch (error) {
    throw error;
  }
});
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers(builder: any) {
    builder
      .addCase(loginAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.isLoading = true;
      })
      .addCase(
        loginAsync.fulfilled,
        (state: AuthenticationState, action: { payload: any | undefined }) => {
          console.log("loginAsync.fulfilled:", action);
          state.requesting = "fulfilled";
          if (action.payload.statusCode) {
            const errorCode = get(action.payload, "errors[0].code");
            const errorSubCode = get(action.payload, "errors[0].subCode");
            const errorStatusCode = errorSubCode || errorCode; // use subCode first

            const messageRaw = get(action.payload, "errors[0].message"); // raw error message from api
            state.error = getCandidateLoginError(errorStatusCode);
          } else {
            sessionStorage.setItem("AUTH_TOKEN", `Bearer ${action.payload?.data?.accessToken}`);
            const tokenRawData = parseJwt(action.payload?.data?.accessToken);
            state.token = tokenRawData;
            state.refreshToken = action.payload?.data?.refreshToken;
            sessionStorage.setItem("AUTH_REFRESH_TOKEN", state.refreshToken ?? "");
            state.error = null;
          }
          state.isLoading = false;
        },
      )
      .addCase(loginAsync.rejected, (state: AuthenticationState, action: any) => {
        state.requesting = "rejected";
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(
        refreshTokenAsync.fulfilled,
        (state: AuthenticationState, action: { payload: any | undefined }) => {
          if (action.payload?.data?.accessToken) {
            sessionStorage.setItem("AUTH_TOKEN", `Bearer ${action.payload?.data?.accessToken}`);
            const tokenRawData = parseJwt(action.payload?.data?.accessToken);
            state.token = tokenRawData;
            state.refreshToken = action.payload?.data?.refreshToken;
            sessionStorage.setItem("AUTH_REFRESH_TOKEN", state.refreshToken ?? "");
            state.error = null;
          }
        },
      )
      .addCase(getCandidateProfileAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.isLoading = true;
      })
      .addCase(
        getCandidateProfileAsync.fulfilled,
        (state: AuthenticationState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          const candidateProfile: CandidateProfile = action.payload?.data as CandidateProfile;
          state.candidateProfile = candidateProfile;
          sessionStorage.setItem(
            "CANDIDATE_PROFILE",
            candidateProfile ? JSON.stringify(candidateProfile) : "",
          );
          state.isLoading = false;
        },
      )
      .addCase(getCandidateProfileAsync.rejected, (state: AuthenticationState, action: any) => {
        state.requesting = "rejected";
        state.error = action.payload;
        sessionStorage.setItem("CANDIDATE_PROFILE", "");
        state.isLoading = false;
      })
      .addCase(updateCandidateProfileAsync.pending, (state: any) => {
        state.requesting = "pending";
        state.isLoading = true;
      })
      .addCase(
        updateCandidateProfileAsync.fulfilled,
        (state: AuthenticationState, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          // console.log
          const candidateProfile: CandidateProfile = action.payload?.data as CandidateProfile;
          state.candidateProfile = candidateProfile;
          state.isLoading = false;
        },
      )
      .addCase(updateCandidateProfileAsync.rejected, (state: AuthenticationState, action: any) => {
        state.requesting = "rejected";
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(getGlobalConfigAsync.pending, (state: AuthenticationState, action: any) => {
        state.requesting = "pending";
      })
      .addCase(getGlobalConfigAsync.fulfilled, (state: AuthenticationState, action: any) => {
        state.requesting = "fulfilled";
        const autoLogoutMinutes = get(
          action.payload,
          "data.content.onboardingConfig.autoLogoutMinutes",
          15,
        );
        state.autoLogoutMinutes = autoLogoutMinutes;
      })
      .addCase(getGlobalConfigAsync.rejected, (state: AuthenticationState, action: any) => {
        state.requesting = "rejected";
        state.error = action.payload;
      });
  },
});

const getCandidateLoginError = (status: string) => {
  let errorMessage;
  let errLog =
    "System error. Please re-try your action. If you continue to get this error, please contact the Administrator.";
  switch (status) {
    case "OAM-1":
      errorMessage = getI18n().t("Auth.login_candidate_fail_msg", errLog);
      break;
    case "OAM-21":
    case "OAM-2":
      errLog = "Incorrect phone number or password";
      errorMessage = getI18n().t("Auth.incorrect_phone_number_or_password", errLog);
      break;
    case "OAM-22":
      errLog = "Failed login attempt";
      errorMessage = getI18n().t("Auth.login_candidate_max_text", errLog);
      break;
    case "Generic-1":
      errLog = "Please enter a valid phone number";
      errorMessage = getI18n().t("Auth.login_invalid_phone_number_msg", errLog);
      break;
    default:
      errLog = "Unexpected Error";
      errorMessage = getI18n().t("Auth.login_error", errLog);
  }
  return errorMessage;
};

export const { reset } = authSlice.actions;
