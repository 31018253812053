import React from "react";

export interface IconFontProps {
  name: string;
  size?: number;
  color?: string;
}

export const IconFont = ({ name, size, color }: IconFontProps) => {
  const style = {
    fontSize: size ? `${size ?? 16}px` : "inherit",
    color: color || "inherit",
  };

  return <i className={`iconfont ${name}`} style={style}></i>;
};
