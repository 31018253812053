import { get } from "lodash";
import { http } from "../../../utils/axios/axios";
import { LoginParams } from "./type";
import { getRefreshToken, getToken } from "../../onboarding/utils";
import { getCustomHeaderAsync } from "../../onboarding/network/network";

const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
const APIM_RECRUITMENT = get(window, "envConfig.REACT_APP_APIM_RECRUITMENT", "");
const APIM_CORE = get(window, "envConfig.REACT_APP_APIM_CORE", "");
const CORE_URL = `${APIM_BASE_URL}${APIM_CORE}`;
const RECRUITMENT_URL = `${APIM_BASE_URL}${APIM_RECRUITMENT}`;

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Ocp-Apim-Subscription-Key": get(window, "envConfig.REACT_APP_OEM_APIM_SUBSCRIPTION_KEY", ""),
  Authorization: getToken(),
};

export async function login(loginParams: LoginParams) {
  const url = `${CORE_URL}/auth/token?grantType=password`;
  const response = http.post(url, loginParams, { headers: commonHeaders }).catch((err) => {
    return err.response.data;
  });
  return response;
}

export const refreshLoginToken = async () => {
  const url = `${APIM_BASE_URL}${APIM_CORE}/auth/token?grantType=refreshToken&code=${getRefreshToken()}`;
  return http.get(url, { headers: await getCustomHeaderAsync() });
};

export async function getCandidateProfile(candidateId: string) {
  const url = `${RECRUITMENT_URL}/candidates/me/profile/${candidateId}`;
  const response = http.get(url, { headers: await getCustomHeaderAsync() }).catch((err) => {
    return err.response.data;
  });
  return response;
}

export async function updateCandidateProfile(params: { candidateData: any }) {
  const { candidateData } = params;
  const submitData: any = candidateData;

  if (candidateData.applicationDetails) {
    submitData.applicationDetails = {
      ...candidateData.applicationDetails,
    };
  }
  const url = `${RECRUITMENT_URL}/candidates/me/${candidateData.id}`;
  const response = http
    .put(url, submitData, {
      headers: {
        ...(await getCustomHeaderAsync()),
        __loading: true,
      },
    })
    .catch((err) => {
      return err.response.data;
    });
  return response;
}
