import * as React from "react";
import { cloneElement } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import { Modal, ModalProps } from "./modal.type";
import { ModalContext, useModalsContext } from "./use-modal";

export const ModalContent = (props: Modal) => {
  const {
    showClose = true,
    showHeader = true,
    showBack = false,
    onBack,
    containerStyle,
    title = "",
    children,
    params = {},
    id,
    modalType,
    autoScroll,
  } = props;
  const { closeModal, updateModal } = useModalsContext();
  const close = () => closeModal(id);
  const closeBtn = (
    <IconButton color="inherit" onClick={close}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const backBtn = (
    <IconButton color="inherit" onClick={onBack}>
      <NavigateBeforeRoundedIcon />
    </IconButton>
  );
  const renderChildren = () => {
    return (
      <div className="h-full">{children && cloneElement(children, { close, onClose: close })}</div>
    );
  };

  return (
    <div style={containerStyle} className="overflow-auto h-full">
      {showHeader && (
        <div className="flex flex-row">
          {showBack ? backBtn : <div />}
          <div className="flex-1">{title}</div>
          {showClose && closeBtn}
        </div>
      )}
      <ModalContext.Provider
        value={{
          params,
          close,
          updateTitle: (title: string) => updateModal(id, { title }),
          updateModal: (modalProps: ModalProps = {}) => updateModal(id, modalProps),
          modal: props,
        }}
      >
        <div>
          {autoScroll ? <FlatList data={[1]} renderItem={renderChildren} /> : renderChildren()}
        </div>
      </ModalContext.Provider>
    </div>
  );
};
export const FlatList = ({ data, renderItem }: any) => {
  return <div className="h-full">{data.map(renderItem)}</div>;
};
