import React from "react";
import { useTranslation } from "react-i18next";
import { IconFont } from "../../../../../../../icon-font/iconfont";
import { color } from "../../../../../../../mui-theme/palette";
import { iconMap } from "../../../../candidate-home/components/license-exam-entrance-card/type";

interface ReminderTipProps {
  details?: { text: string; icon?: string }[];
}

const ReminderTip: React.FC<ReminderTipProps> = ({ details }) => {
  const { t } = useTranslation();

  const textStyle = "text-grey-33 font-sf-pro-text text-sm font-medium leading-snug flex-1";

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className={`${textStyle} py-2 px-2`} style={{ alignSelf: "flex-start" }}>
        {`${t("you_have_selected_below")}:`}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        {details &&
          details.map(({ text, icon }, index) => (
            <div
              key={index}
              className={textStyle}
              style={{ display: "flex", alignItems: "center" }}
            >
              {icon && (
                <div style={{ marginRight: "8px" }}>
                  <IconFont
                    name={iconMap[icon as keyof typeof iconMap]}
                    size={16}
                    color={color.grey33}
                  />
                </div>
              )}
              <span>{text}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReminderTip;
