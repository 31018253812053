import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CompleteEvent, CompletingEvent, Model, SurveyModel } from "survey-core";
import { fetchRegistrationForm, submitRegistrationForm } from "../../network/event-detail-crud";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "lodash";
import { StatusCode } from "../../../../components/error-state/error-state";
import { fetchConfig } from "../../../agent-referral-link/redux/referral-link-slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getUnavailableModalConfig, openFromApp } from "../../util/event.util";
import { customMarked } from "../../../../components/surveyjs/survey/survey.util";
import { useTranslation } from "react-i18next";
import {
  CheckAction,
  RegMethodType,
  RegistrationFormType,
  RegistrationResponseCode,
  SubmitParticipantsInfoCodeEnum,
} from "../../network/type";
import { prompt } from "../../../../components/modal/prompt";
import { PruToast } from "../../../../components/pru-toast";
import { EVENT_PATH, ROOT_PATH } from "../../../../routes/constants";
import {
  fetchAttendanceLandingByIdAsync,
  fetchEventDetailByIdAsync,
  updateCode,
} from "../../redux/event-slice";
import { formatSurveyJson } from "../../util/event-form.util";

export interface SurveyFormData {
  regFormBody: { [key: string]: any };
  regFormResult: { [key: string]: any } | null;
}

export const useSurveyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { refId = "" } = useParams();
  const [surveyModel, setSurveyModel] = useState<Model>();
  const [errorStatus, setErrorStatus] = useState<StatusCode>();
  const [surveyData, setSurveyData] = useState<SurveyFormData>();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const qrCodeShortenCodeRef = useRef<string>();
  const hashEntry = useAppSelector((state) => state.common?.hashEntry);
  const dropdownConfig = useAppSelector((state) => state.referralLink.dropdown);

  const getRegistrationForm = useCallback(
    async (shortenCode: string, searchParams: URLSearchParams) => {
      const registrationId = searchParams.get("registrationId") || undefined;
      const sessionId = searchParams.get("sessionId") || undefined;
      const formType = (searchParams.get("formType") as RegistrationFormType) || undefined;
      try {
        const res = await fetchRegistrationForm({
          shortenCode,
          registrationId,
          sessionId,
          type: formType,
        });
        if (res?.data?.regMethod === RegMethodType.LINK) {
          return window.location.replace(res?.data?.regLink);
        }
        setSurveyData(res.data);
      } catch (err) {
        const status = get(err, "response.status", "-1"); // -1: unknown error by default
        setErrorStatus(status.toString() as StatusCode);
      }
    },
    [],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (refId) {
      getRegistrationForm(refId, searchParams);
      if (searchParams.get("formType") === RegistrationFormType.WALKIN) {
        dispatch<any>(fetchAttendanceLandingByIdAsync(refId));
      } else {
        dispatch<any>(fetchEventDetailByIdAsync(refId));
      }
    }
  }, [refId]);

  useEffect(() => {
    if (!hashEntry || !hashEntry.data) {
      return;
    }
    const { region, channel, regMethod } = hashEntry.data;
    if (regMethod === RegMethodType.LINK) return;
    dispatch<any>(fetchConfig({ region, channel }));
  }, [dispatch, hashEntry]);

  const onCompleteSurvey = useCallback(
    async (survey: SurveyModel, options: CompletingEvent) => {
      const surveyData = survey.data;
      if (surveyData.registrant_countryCode) {
        surveyData.registrant_countryCode = surveyData.registrant_countryCode?.key;
      }
      try {
        options.allow = false;
        setLoading(true);

        const searchParams = new URLSearchParams(window.location.search);
        const formType = (searchParams.get("formType") as RegistrationFormType) || undefined;
        const sessionId = searchParams.get("sessionId") || undefined;
        const res = await submitRegistrationForm({
          surveyResultBody: surveyData,
          shortenCode: refId,
          type: formType,
          sessionId,
        });
        qrCodeShortenCodeRef.current = res?.data?.shortenCode;
        setLoading(false);
        if (res?.data?.code !== RegistrationResponseCode.SUCCESS) {
          const config = getUnavailableModalConfig(res?.data?.code);
          if (
            !config ||
            (res?.data?.code === RegistrationResponseCode.REGISTER_DUPLICATED &&
              formType !== RegistrationFormType.WALKIN)
          ) {
            PruToast({ message: res?.data?.message || t("Common.server_error") });
          } else {
            const promptRes = await prompt(config);
            if (promptRes.ok) {
              promptConfirm({ key: res?.data?.code, refId, sessionId, dispatch, navigate });
            }
          }
        } else {
          setCompleted(true);
        }
      } catch (error) {
        setLoading(false);
        const status = get(error, "response.status", "-1");
        setErrorStatus(status.toString() as StatusCode);
      }
    },
    [refId],
  );

  useEffect(() => {
    if (surveyData) {
      const { regFormBody, regFormResult } = formatSurveyJson({
        surveyData: surveyData,
        region: hashEntry?.data?.region,
        dropdownConfig,
      });

      const survey = new Model(regFormBody);

      if (regFormResult) {
        // show result page
        survey.mode = "display";
        survey.data = regFormResult;
      } else {
        survey.onCompleting.add(onCompleteSurvey);
      }

      survey.onTextMarkdown.add(function (survey, options) {
        if (options.element.name === "registrant_terms") {
          //convert the markdown text to html
          let str = customMarked.parseInline(options.text, { async: false });
          //set html
          options.html = str;
        }
      });

      setSurveyModel(survey);
    }
  }, [dropdownConfig, hashEntry?.data?.region, onCompleteSurvey, surveyData]);

  return {
    surveyModel,
    surveyData,
    errorStatus,
    loading,
    completed,
    qrCodeShortenCode: qrCodeShortenCodeRef.current,
    onCompleteSurvey,
  };
};

export const promptConfirm = ({
  key,
  refId,
  sessionId,
  navigate,
  dispatch,
}: {
  key: RegistrationResponseCode;
  refId: string;
  sessionId?: string;
  navigate: any;
  dispatch: any;
}) => {
  switch (key) {
    case RegistrationResponseCode.SESSION_FULL:
      window.location.reload();
      break;
    case RegistrationResponseCode.AGENT_QUOTA_FULL:
    case RegistrationResponseCode.EVENT_FULL:
      if (openFromApp()) {
        window.ReactNativeWebView?.postMessage('{ "action" : "close"}');
      } else {
        navigate(-1);
      }
      break;
    case RegistrationResponseCode.REGISTER_DUPLICATED:
      navigate(
        `${ROOT_PATH}/${EVENT_PATH}/check/${refId}?action=${CheckAction.CHECK_IN}&sessionId=${sessionId}`,
      );
      break;
    case RegistrationResponseCode.WALKIN_REGISTER_DUPLICATED:
      dispatch(updateCode(SubmitParticipantsInfoCodeEnum.ALREADY_CHECKED_IN));
      navigate(
        `${ROOT_PATH}/${EVENT_PATH}/check_result/${refId}?sessionId=${sessionId}&checkType=${CheckAction.CHECK_IN}`,
      );
      break;
    default:
      break;
  }
};
