import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../../../routes/constants";
import { HeaderComponent } from "../../../../../components/header/header.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchInboxMessagesAsync, OnboardingState } from "../../../redux/onboarding-slice";
import { InboxMessage, MessageListRequestParams } from "../../../network/types";
import { InfiniteScroll } from "../../../../../components/infinite-scroll-component";
import { ErrorStateBlock } from "../../../../../components/error-state/error-state";
import { InboxItem } from "../components/inbox-item";
import { useTranslation } from "react-i18next";

const pullDownHold = 200;
const progressSize = 24;
const listPadding = 3;

export const InboxListScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    inboxMessagesResponse,
    loaded: apiLoaded,
    getDataError: error,
  } = useSelector((state: { onboarding: OnboardingState }) => state.onboarding);

  const [page, setPage] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const {
    nextPage,
    hasNextPage,
    data: inboxMessages,
  } = useMemo(() => {
    return {
      nextPage: inboxMessagesResponse?.nextPage ?? 1,
      hasNextPage: inboxMessagesResponse?.hasNextPage ?? 1,
      data: inboxMessagesResponse?.data ?? [],
    };
  }, [inboxMessagesResponse]);

  const fetchInboxMessages = useCallback(
    async (params: MessageListRequestParams) => {
      dispatch<any>(fetchInboxMessagesAsync(params));
    },
    [dispatch],
  );

  useEffect(() => {
    fetchInboxMessages({
      page,
      query: {
        type: "inbox_recruitment",
      },
    });
  }, [page]);
  const { t } = useTranslation();
  const translations = useMemo(() => {
    return {
      title: t("Common.inbox"),
      search: t("Common.inbox_search_placeholder_tip"),
      doesNotMatch: t("Common.no_results_matched"),
      noMore: t("Common.messaging_no_more"),
    };
  }, [t]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSearchClick = () => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/inbox/search`);
  };

  // Pull to refresh handler
  const handleRefresh = () => {
    setIsRefreshing(true);
    setPage(1); // Reset to first page to refresh data
  };

  useEffect(() => {
    if (isRefreshing) {
      fetchInboxMessages({ page: 1 }); // Reload the first page
    }
  }, [isRefreshing]);

  useEffect(() => {
    if (apiLoaded) {
      setIsRefreshing(false);
      setIsLoadingMore(false);
    }
  }, [apiLoaded]);

  // Next page loader (infinite scroll)
  const toNextPage = useCallback(async () => {
    if (isLoadingMore || !hasNextPage) return;

    setIsLoadingMore(true);
    setPage((prevPage) => prevPage + 1);
  }, [isLoadingMore, hasNextPage]);
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen w-screen flex flex-col">
      <HeaderComponent title={translations.title} onBack={handleGoBack} />
      <div className="p-4 bg-white">
        <button
          onClick={handleSearchClick}
          className="w-full p-2 border bg-gray-100 h-9 text-left rounded-full flex flex-row items-center"
        >
          <SearchIcon className="mr-2" style={{ fontSize: 16, color: "gray" }} />
          <span className="text-gray-500">{translations.search}</span>
        </button>
      </div>

      <div className="flex-1 overflow-auto p-4" id={"scrollTarget"}>
        <InfiniteScroll
          style={{
            overflow: "hidden",
          }}
          scrollableTarget="scrollTarget"
          dataLength={inboxMessages.length}
          next={toNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className="flex items-center justify-center mt-1">
              <CircularProgress size={progressSize} color="error" />
            </div>
          }
          refreshFunction={handleRefresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={pullDownHold}
          pullDownToRefreshContent={(value) => (
            <div className="items-center flex justify-center mt-1">
              <CircularProgress
                size={progressSize}
                color="error"
                variant="determinate"
                value={(value / pullDownHold) * 100}
              />
            </div>
          )}
          releaseToRefreshContent={
            <div className="bg-neutral-100 items-center flex justify-center mt-1">
              <CircularProgress size={progressSize} color="error" />
            </div>
          }
          endMessage={<div className="text-center text-gray-500 mt-4">{translations.noMore}</div>}
        >
          {error ? (
            <ErrorStateBlock statusCode="-1" />
          ) : inboxMessages.length === 0 ? (
            <div className="text-center text-gray-500">{translations.doesNotMatch}</div>
          ) : (
            <ul className="space-y-4">
              {inboxMessages.map((message: InboxMessage) => (
                <InboxItem key={message?.id} message={message as any} />
              ))}
            </ul>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};
