import { cloneDeep, get, merge } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { takeUIScreenViewEvent } from "../../../../../../common/ga/ga";
import { TakeUIScreenViewEventParameters } from "../../../../../../common/ga/types/ga-general-type";
import { ConfirmButton } from "../../../../../../components/buttons";
import { HeaderComponent } from "../../../../../../components/header/header.component";
import { confirm } from "../../../../../../components/modal/comfirm";
import { PruToast } from "../../../../../../components/pru-toast";
import { useCanGoBack } from "../../../../../../utils/hooks/use-can-go-back";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { selectCandidateProfile, selectToken } from "../../../../../auth/redux/selector";
import { initFileUploadAndDownLoadAndClearForLicenseExam } from "../../../../components/surveyjs/file/upload-file";
import { initSurvey } from "../../../../components/surveyjs/survey/init-survey";
import { markdownHandler } from "../../../../components/surveyjs/survey/survey.util";
import { handleSurveyError } from "../../../../network/network.util";
import {
  fetchConfigAsync,
  fetchTrainingExamByIdAsync,
  fetchTrainingExamCentresAsync,
  fetchTrainingExamTimeslotsAsync,
  getOnboardingTrainingSummaryAsync,
  postCandidateExamRegistryAsync,
  resetTrainingExamTimeslots,
} from "../../../../redux/onboarding-slice";
import {
  selectEnableLicenseExamPayment,
  selectEnableProvideExistingLicense,
  selectOnboardingCurrentExam,
  selectOnboardingExamCentres,
  selectOnboardingRegisteredSchedules,
  selectOnboardingExamTimeslots,
  selectOnboardingTrainingSummary,
} from "../../../../redux/selector";
import {
  ExamStatus,
  PaymentStatus,
  PaymentType,
  RegistrationDTO,
  RoleEnum,
} from "../../../../types";
import { backToApp, getTargetCandidate, getToken, parseJwt } from "../../../../utils";
import { updateTargetCandidatePaymentType } from "../../../course-menu-screen/utils";
import { convertExamSurveyDataToRegistrationDTO } from "../../../form/util";
import { ExamTemplate as _ExamTemplate } from "./templateJson";
import ReminderTip from "./components/reminder-tip";
import { GlobalHelper } from "../../../../../../utils/helpers/global-helper";
import moment from "moment";
import { constants, ONBOARDING_PATH, ROOT_PATH } from "../../../../../../routes/constants";
import {
  getCandidateProfileAsync,
  updateCandidateProfileAsync,
} from "../../../../../auth/redux/auth-slice";
import {
  ExamMode,
  iconMap,
} from "../../../candidate-home/components/license-exam-entrance-card/type";
import { I18nModule, translate } from "../../../../../../i18n/translation";
import { getIdTypeOptions } from "../../../../../../utils/common-utils";
import styles from "./exam-registry-payment-screen.module.css";
import store from "../../../../../../redux/store";

export const ExamRegistryPaymentScreen: React.FC = () => {
  initSurvey();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const canGoBack = useCanGoBack();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const tokenData = useMemo(() => {
    const tokenFromStorage = getToken();
    const tokenPayload = parseJwt(tokenFromStorage);
    return merge(tokenPayload, token);
  }, [token]);

  const [globalConfigs, setGlobalConfigs] = useState({} as any);
  const [recruitConfigs, setRecruitConfigs] = useState({} as any);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  const [formValues, setForm] = useState({} as any);
  const candidateId = query.get("candidateId") ?? GlobalHelper.getTokenUserSub();
  const [survey, setSurvey] = useState(undefined as any);
  const [currentPageNo, setCurrentPageNo] = useState(0);

  const [confirmButtonText, setConfirmButtonText] = useState<string>();
  const [examData, setExamData] = useState<RegistrationDTO>({});
  const [surveyIsReadonly, setSurveyIsReadonly] = useState<boolean>(false);
  const candidateProfile = useSelector(selectCandidateProfile);

  const currentExam = useSelector(selectOnboardingCurrentExam);
  const [pageInitiating, setPageInitiating] = useState(true);
  const enableLicenseExamPayment = useSelector(selectEnableLicenseExamPayment);
  const enableProvideExistingLicense = useSelector(selectEnableProvideExistingLicense);

  const candidateOnboardingTrainingSummary = useSelector(selectOnboardingTrainingSummary);

  const registeredScheduleIds = useSelector(selectOnboardingRegisteredSchedules);

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();

  const examCode = useParams().examId;
  const registrationId = query.get("registrationId");

  const examCentrePools = useSelector(selectOnboardingExamCentres);
  const examTimeslotPools = useSelector(selectOnboardingExamTimeslots);

  const getIdFields = (globalConfigs: any, t: any) => {
    const idTypeConfigs = globalConfigs?.region?.idType;
    if (!idTypeConfigs) {
      return [];
    }
    const options = getIdTypeOptions(globalConfigs?.region?.idType)?.options;

    return [
      {
        type: "dropdown",
        name: "identityDocumentType",
        title: t("Common.id_type"),
        isRequired: true,
        choices: options ?? [],
        requiredErrorText: t("Common.please_select_id_type"),
      },
      ...Object.keys(idTypeConfigs).map((key) => {
        const option = idTypeConfigs[key];
        return {
          type: "text",
          name: `identityDocumentNumber`,
          visibleIf: `{identityDocumentType}="${option.value}"`,
          title: t("Common.id_num"),
          isRequired: true,
          tooltips: option.desc ? t(option.desc.replace(":", ".")) : "",
          placeholder: t("Common.id_num_nric_placeholder"),
          validators: [
            {
              type: "expression",
              text: t(option.errMsg.replace(":", ".")),
              expression: "idNumValidate({#identityDocumentType},{identityDocumentNumber})",
            },
          ],
          inputType: "text",
          requiredErrorText: t("Common.id_num_nric_placeholder"),
        };
      }),
    ];
  };

  const generateFormUI = (elements: any, globalConfigs: any, t: any) => {
    // NOTE: idFieldStartIndex is the index where the id fields should be inserted, after the localName field
    const idFieldStartIndex = 3;
    const hasidentityDocumentTypeField = elements.some(
      (element: any) => element.name === "identityDocumentType",
    );

    if (!hasidentityDocumentTypeField) {
      const idFields = getIdFields(globalConfigs, t);
      elements.splice(idFieldStartIndex, 0, ...idFields);
    }
  };
  const ExamTemplate = useMemo(() => {
    const template = convertTemplate(_ExamTemplate, recruitConfigs, globalConfigs);
    const elements = template.pages[2].elements;
    generateFormUI(elements, globalConfigs, t);
    return template;
  }, [globalConfigs, recruitConfigs]);

  const getPaymentType = () => {
    const targetCandidate = getTargetCandidate();
    return targetCandidate.paymentType;
  };
  const getCandidateStutus = () => {
    const targetCandidate = getTargetCandidate();
    return targetCandidate.status;
  };
  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchData = useCallback(async () => {
    if (!(registrationId || examCode) || !candidateId) return;
    const [configsResponse, _] = await Promise.all([
      dispatch<any>(fetchConfigAsync()),
      dispatch<any>(
        fetchTrainingExamByIdAsync({
          candidateId,
          registrationId: registrationId,
          examCode: examCode!,
        }),
      ),
    ]);

    const recruitConfig = get(configsResponse, "payload.data.Recruitment", {});
    const globalConfig = get(configsResponse, "payload.data.Global", {});

    setRecruitConfigs(recruitConfig);
    setGlobalConfigs(globalConfig);
    GlobalHelper.setIdTypeConfigs(globalConfig?.region?.idType);

    dispatch<any>(getOnboardingTrainingSummaryAsync({ candidateId }));
    setPageInitiating(false);
  }, [candidateId, dispatch, examCode, registrationId]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (candidateOnboardingTrainingSummary?.trainingSummary) {
      setPaymentStatus(candidateOnboardingTrainingSummary?.trainingSummary?.paymentStatus);
    }
  }, [candidateOnboardingTrainingSummary]);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch<any>(getCandidateProfileAsync(candidateId));
      } catch (error) {
        console.error("Failed to fetch candidate profile:", error);
      }
    };

    fetchProfile();
  }, [dispatch, candidateId]);

  const fetchExamCentres = useCallback(async () => {
    if (!currentExam) return;
    await dispatch<any>(fetchTrainingExamCentresAsync(currentExam));
  }, [currentExam, dispatch]);

  const initPersonalForm = () => {
    const arrPhone = candidateProfile?.phone?.split(" ") || [];

    return {
      ...formValues,
      agentCode: candidateProfile?.agentid,
      firstName: candidateProfile?.firstname,
      lastName: candidateProfile?.lastname,
      gender: candidateProfile?.gender,
      localName: candidateProfile?.localName,
      birthday: moment(candidateProfile?.birthday, constants.Default_Date_Format).toISOString(),
      identityDocumentType: candidateProfile?.identityDocumentType,
      identityDocumentNumber: candidateProfile?.identityDocumentNumber,
      email: candidateProfile?.email,
      regionPhone: [
        {
          regionCode: arrPhone[0] || "",
          phoneNumber: arrPhone[1] || "",
        },
      ],

      languagePreference: candidateProfile?.languagePreference,
    };
  };

  useEffect(() => {
    if (!currentExam) {
      return;
    }
    const personalForm = initPersonalForm();
    const canEdit = query.get("canEdit") === "true";

    if (canEdit) {
      const initFormData: any = {
        ...personalForm,
        hasPassedExam: enableProvideExistingLicense ? "" : false,
        paymentType: getPaymentType(),
      };
      setForm(initFormData);
      setSurveyIsReadonly(false);
    } else {
      const currencies = get(store.getState(), "onboarding.configs.Global.region.currencies", []);
      const currencySymbol = currencies.length
        ? currencies.find(
            (currency: any) =>
              currency.code === (currentExam?.originData as any)?.examFeeCurrencyCode,
          )?.symbol ?? currencies[0].symbol
        : "";
      setForm({
        ...personalForm,
        hasPassedExam: currentExam?.originData?.hasPassedExam,
        hasLicenseProof: currentExam?.originData?.licenseInfo?.hasLicenseProof,
        examCentre: currentExam?.originData?.examSchedule?.examCentre,
        examScheduleId: currentExam?.originData?.examSchedule?.id,
        paymentType: getPaymentType(),
        examScore: currentExam?.originData?.licenseInfo?.examScore,
        examDate: currentExam?.originData?.licenseInfo?.examDate,
        officialReceiptNumber: currentExam?.originData?.licenseInfo?.officialReceiptNumber,
        paymentReceiptDate: currentExam?.originData?.licenseInfo?.paymentReceiptDate,
        proofInfos: currentExam?.originData?.licenseInfo?.proofInfos,
        licenseFeeReceiptNo: currentExam?.originData?.examPayment?.licenseFeeReceiptNo,
        licenseFeeReceiptDate: currentExam?.originData?.examPayment?.licenseFeeReceiptDate,
        examFeeReceiptNo: currentExam?.originData?.examPayment?.examFeeReceiptNo,
        examFeeReceiptDate: currentExam?.originData?.examPayment?.examFeeReceiptDate,
        remainingQuota: currentExam?.originData?.examSchedule?.remainingQuota,
        examFee: `${currencySymbol}${currentExam?.originData?.examSchedule?.examFee}`,
        startRegistrationDate: currentExam?.originData?.examSchedule?.startRegistrationDate,
        lastRegistrationTime: currentExam?.originData?.examSchedule?.lastRegistrationDate,
        address: currentExam?.originData?.examSchedule?.address,
        remarks: currentExam?.originData?.examSchedule?.remarks,
      });
      setSurveyIsReadonly(true);
    }

    if (tokenData.role !== RoleEnum.CANDIDATE) {
      setSurveyIsReadonly(true);
    }
    // if (getCandidateStutus() !== CandidateStatus.IN_PROGRESS) {
    //   setSurveyIsReadonly(true);
    // }
  }, [currentExam, enableProvideExistingLicense, tokenData.role]);

  useEffect(() => {
    if (!survey) {
      return;
    }
    const examCentreQuestion = survey.getQuestionByName("examCentre");
    const examRegistrationIdQuestion = survey.getQuestionByName("examScheduleId");
    var centreChoices;
    var timeChoices;
    if (!surveyIsReadonly) {
      centreChoices = examCentrePools.map(function (pool) {
        return {
          value: pool.examCentre,
          text: pool.examCentre,
        };
      });
      timeChoices = examTimeslotPools.map(function (pool) {
        return {
          ...pool,
          examMode: [ExamMode.COMPUTER, ExamMode.PAPER].includes(pool?.examMode as ExamMode)
            ? pool.examMode
            : null,
          examModeIcon: iconMap[pool?.examMode as keyof typeof iconMap],
          deviceType: pool?.deviceType,
          disabled: registeredScheduleIds.includes(pool.value),
          text: `${pool?.examDate},${t(moment(pool?.examDate, "YYYY-MM-DD").format("ddd"))},${
            pool?.examStartTime
          }-${pool?.examEndTime}`,
        };
      });
    } else {
      const examSchedule = currentExam?.originData?.examSchedule;
      centreChoices = [
        {
          value: examSchedule?.examCentre,
          text: examSchedule?.examCentre,
        },
      ];
      timeChoices = [
        {
          ...examSchedule,
          examMode: [ExamMode.COMPUTER, ExamMode.PAPER].includes(examSchedule?.examMode as ExamMode)
            ? examSchedule?.examMode
            : null,
          examModeIcon: iconMap[examSchedule?.examMode as keyof typeof iconMap],
          deviceType: examSchedule?.deviceType,
          disabled: registeredScheduleIds.includes(examSchedule?.id),
          value: examSchedule?.id,
          text: `${examSchedule?.examDate},${t(
            moment(examSchedule?.examDate, "YYYY-MM-DD").format("ddd"),
          )},${examSchedule?.startTime}-${examSchedule?.endTime}`,
        },
      ];
    }
    examCentreQuestion.choices = centreChoices;
    examRegistrationIdQuestion.choices = timeChoices;
  }, [
    currentExam,
    examCentrePools,
    examTimeslotPools,
    survey,
    surveyIsReadonly,
    registeredScheduleIds,
  ]);

  useEffect(() => {
    fetchExamCentres();
  }, [fetchExamCentres]);

  useEffect(() => {
    //the browser back event
    window.history.pushState(null, "", window.location.href);
    const handlePopState = () => {
      if (survey.currentPageNo === 2 && enableLicenseExamPayment) {
        survey.currentPageNo = 1;
      } else if (survey.currentPageNo > 0) {
        survey.currentPageNo = 0;
      } else {
        navigate(-1);
      }
    };
    window.onpopstate = handlePopState;
    return () => {
      window.onpopstate = null;
    };
  }, [enableLicenseExamPayment, navigate, survey]);

  useEffect(() => {
    if (!survey) {
      return;
    }
    if (currentExam && (examData.examCentre || formValues.examCentre)) {
      dispatch<any>(
        fetchTrainingExamTimeslotsAsync({
          ...currentExam,
          examCentre: examData.examCentre || formValues.examCentre,
        } as any),
      );
    } else {
      dispatch(resetTrainingExamTimeslots());
    }
    const examRegistrationIdQuestion = survey.getQuestionByName("examScheduleId");
    examRegistrationIdQuestion.value = "";
  }, [currentExam, dispatch, examData.examCentre, formValues.examCentre, survey]);

  const updateCandidateProfile = useCallback(
    async (examData: any) => {
      const candidateData: any = {};
      candidateData.gender = examData.gender;
      candidateData.firstname = examData.firstName;
      candidateData.lastname = examData.lastName;
      candidateData.localName = examData.localName;
      candidateData.birthday = moment(examData.birthday).format(constants.Default_Date_Format);
      candidateData.identityDocumentType = examData.identityDocumentType;
      candidateData.identityDocumentNumber = examData.identityDocumentNumber;
      candidateData.email = examData.email;
      candidateData.regionCode = examData.regionPhone[0].regionCode;
      candidateData.phoneNumber = examData.regionPhone[0].phoneNumber;
      candidateData.languagePreference = examData.languagePreference ?? "en";
      candidateData.agentCode = examData.agentCode;
      await dispatch<any>(
        updateCandidateProfileAsync({
          candidateData: { ...candidateProfile, ...candidateData },
        }),
      );
    },
    [candidateProfile, dispatch],
  );

  const onSubmitHandler = useCallback(
    (examData: any) => {
      const examTime = examTimeslotPools.find((item) => item.value === examData.examScheduleId);
      confirm({
        message: t("candidate_form_submit_reminder_tips"),
        title: t("reminder_title"),
        confirmButtonText: t("global.text.confirm"),
        messageComponent: (
          <ReminderTip
            details={[
              ...(!examData.hasPassedExam
                ? [
                    { text: `${examData.examCentre}` },
                    { text: `${examTime?.name}` },
                    ...([ExamMode.COMPUTER, ExamMode.PAPER].includes(examTime?.examMode as ExamMode)
                      ? [
                          {
                            text: ` ${translate(
                              examTime?.examMode as ExamMode,
                              I18nModule.Common,
                            )}`,
                            icon: examTime?.examMode,
                          },
                        ]
                      : []),
                    { text: `${t("remainingQuota")}: ${examTime?.remainingQuota}` },
                  ]
                : []),
              ...(examData.hasPassedExam
                ? [
                    { text: `${t("license")}: ${currentExam?.name}` },
                    {
                      text: `${t("existingLicense")}: ${t("app.select.yes")}`,
                    },
                    {
                      text: `${t("proof")}: ${
                        examData.hasLicenseProof ? t("app.select.yes") : t("app.select.no")
                      }`,
                    },
                    {
                      text: `${t("examProofCopy")}: ${
                        examData.hasLicenseProof ? t("uploaded") : t("requested")
                      }`,
                    },
                  ]
                : []),
            ]}
          />
        ),
        messageStyle: { textAlign: "left" },
      }).then(async (result) => {
        if (result.ok) {
          const actionResponse = await dispatch<any>(
            postCandidateExamRegistryAsync({
              candidateId: candidateId as string,
              body: convertExamSurveyDataToRegistrationDTO(currentExam, examData, false),
            }),
          );
          const errors = actionResponse?.error ?? actionResponse?.payload?.errors;
          if (errors) {
            const message = errors.message ?? "Unknown error";
            handleSurveyError({ errors, survey, defaultErrMsg: message });
            return;
          } else {
            if (examData.paymentType) {
              updateTargetCandidatePaymentType(examData.paymentType);
            }
            await updateCandidateProfile(examData);
            PruToast({
              status: "success",
              message: t("common.success"),
            });
            navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/candidate-home`);
            backToApp();
          }
        }
      });
    },
    [
      examTimeslotPools,
      t,
      currentExam,
      dispatch,
      candidateId,
      survey,
      updateCandidateProfile,
      navigate,
    ],
  );
  const validateData = useCallback(
    async (examData: any) => {
      const actionResponse = await dispatch<any>(
        postCandidateExamRegistryAsync({
          candidateId: candidateId as string,
          body: convertExamSurveyDataToRegistrationDTO(currentExam, examData, true),
        }),
      );

      const errors = actionResponse?.error ?? actionResponse?.payload?.errors;

      if (errors) {
        const message = errors.message ?? "Unknown error";
        handleSurveyError({ errors, survey, defaultErrMsg: message });
        return false;
      } else {
        return true;
      }
    },
    [currentExam, dispatch, candidateId, survey],
  );

  useEffect(() => {
    if (!survey) return;
    const isLastPage = currentPageNo === survey.visiblePageCount - 1;
    const isExamPassed = examData?.hasPassedExam;

    let buttonText;
    if (!isExamPassed && !isLastPage) {
      buttonText = t("app.button.next");
    } else {
      if (surveyIsReadonly) {
        buttonText = t("Common.close");
      } else {
        buttonText = t("register");
      }
    }
    setConfirmButtonText(buttonText);
  }, [
    currentPageNo,
    t,
    examData?.hasPassedExam,
    enableLicenseExamPayment,
    survey,
    surveyIsReadonly,
  ]);
  useEffect(() => {
    setSurvey(new Model(ExamTemplate));
    const gaData: TakeUIScreenViewEventParameters = {
      module: "Recruitment",
      feature: "License",
      journey: "view_individual_license",
      stage: "journey_landing",
      screen_id: "SCR_RCRTMT_LCNS_DTLS",
      screen_name: "Recruitment-License-Details-Screen",
    };
    takeUIScreenViewEvent(gaData);
  }, [ExamTemplate]);

  useEffect(() => {
    if (!survey) {
      return;
    }
    const hasPassedExamQuestion = survey.getQuestionByName("hasPassedExam");
    hasPassedExamQuestion.visible = enableProvideExistingLicense;

    const paymentType = survey.getQuestionByName("paymentType");
    const examStatus = currentExam?.examResult;

    const licenseFeeReceiptNoQuestion = survey.getQuestionByName("licenseFeeReceiptNo");
    const paymentTypeQuestion = survey.getQuestionByName("licenseFeeReceiptDate");
    const isExamFailedButCodeNowLater =
      examStatus === ExamStatus.FAIL &&
      currentExam?.originData?.examPayment?.paymentType === PaymentType.CodeNowPayLater;
    const flag =
      paymentType.value === PaymentType.FullPayment &&
      (examStatus !== ExamStatus.FAIL || isExamFailedButCodeNowLater);

    licenseFeeReceiptNoQuestion.visible = flag;
    paymentTypeQuestion.visible = flag;
  }, [currentExam, enableProvideExistingLicense, examData, survey]);

  const form = useMemo(() => {
    if (pageInitiating) return;
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    survey.mergeData({ ...formValues, ...survey.data });
    survey.onComplete.add((_sender: any) => {});
    survey.onTextMarkdown.add(markdownHandler);
    survey.onGetQuestionTitleActions.add((_: any, options: any) => {
      if (options.question.tooltips) {
        options.titleActions = [
          {
            id: "show-popover",
            component: "popover",
            tooltip: options.question.tooltips,
          },
        ];
      }
    });
    setExamData(survey.data);
    survey.onValueChanged.add(async (_sender: any, options: any) => {
      setExamData({ ...survey.data, [options.name]: options.value });

      if (options.name === "examScheduleId") {
        const remainingQuotaQuestion = survey.getQuestionByName("remainingQuota");
        const examFeeQuestion = survey.getQuestionByName("examFee");
        const startRegistrationDateQuestion = survey.getQuestionByName("startRegistrationDate");
        const lastRegistrationTimeQuestion = survey.getQuestionByName("lastRegistrationTime");
        const addressQuestion = survey.getQuestionByName("address");
        const remarksQuestion = survey.getQuestionByName("remarks");
        var examScheduleId = options.value;
        const examTimeslotPool = examTimeslotPools.find((obj) => obj.value === examScheduleId);

        if (examScheduleId) {
          remainingQuotaQuestion.value = examTimeslotPool?.remainingQuota;
          examFeeQuestion.value = examTimeslotPool?.examFee;
          startRegistrationDateQuestion.value = examTimeslotPool?.startRegistrationDate;
          lastRegistrationTimeQuestion.value = examTimeslotPool?.lastRegistrationDate;
          addressQuestion.value = examTimeslotPool?.address;
          remarksQuestion.value = examTimeslotPool?.remarks;
        }
      }
    });

    survey.onCurrentPageChanged.add((_sender: any, options: any) => {
      const currentPageNo = survey.currentPageNo;
      setCurrentPageNo(currentPageNo);
    });
    survey.getAllQuestions().forEach((question: any) => {
      question.readOnly = surveyIsReadonly;
      if (question.name === "paymentType") {
        question.readOnly = getPaymentType() ? true : false;
      }
      const hiddenQuestions = [
        "personalInfoAgreement1",
        "personalInfoAgreement2",
        "personalInfoAgreement3",
      ];
      if (surveyIsReadonly) {
        if (hiddenQuestions.includes(question.name)) {
          question.visible = false;
        }
      }
    });
    initFileUploadAndDownLoadAndClearForLicenseExam(survey);
    return (
      <div className="flex flex-1 bg-gray-100 flex-col  h-screen pb-4">
        <div
          className="flex pt-5 pl-5 pr-4 text-xl font-semibold line-height-28"
          style={{ flexWrap: "wrap" }}
        >
          <span
            style={{
              overflowWrap: "break-word",
              overflow: "hidden",
              whiteSpace: "normal",
              maxWidth: "100%",
            }}
          >
            {currentExam?.name}
          </span>
        </div>
        <div className="bg-gray-100  flex justify-center items-center pt-5">
          <Survey
            currentPageNo={0}
            className="survey-common"
            id="training-payment"
            model={survey}
          />
        </div>
      </div>
    );
  }, [formValues, pageInitiating, survey, surveyIsReadonly, examTimeslotPools]);

  const bottom = useMemo(() => {
    if (!survey) {
      return;
    }
    return (
      <div className="sticky bottom-0 bg-gray-100 px-4 pt-2 pb-7 flex items-center justify-between gap-4">
        <ConfirmButton
          onClick={async () => {
            let isValid = false;
            isValid = survey.validateCurrentPage();
            if (!isValid) {
              return;
            }
            const isLastPage = currentPageNo === survey.visiblePageCount - 1;
            const isExamPassed = examData?.hasPassedExam;
            if (!isExamPassed && !isLastPage) {
              if (enableLicenseExamPayment && survey.currentPageNo !== 1) {
                //page0
                survey.currentPageNo = 1;
              } else {
                if (enableLicenseExamPayment && !surveyIsReadonly) {
                  const proceed = await validateData(examData);
                  if (proceed) {
                    survey.currentPageNo = 2;
                  }
                } else {
                  survey.currentPageNo = 2;
                }
              }
            } else {
              if (surveyIsReadonly) {
                navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/candidate-home`);
                backToApp();
              } else {
                onSubmitHandler(examData);
              }
            }
          }}
        >
          {confirmButtonText}
        </ConfirmButton>
      </div>
    );
  }, [
    survey,
    confirmButtonText,
    currentPageNo,
    examData,
    enableLicenseExamPayment,
    validateData,
    onSubmitHandler,
  ]);

  return (
    <div className={styles.profileContainer}>
      {isPortrait ? (
        <HeaderComponent
          title={t("Recruitment.license_exam")}
          onBack={() => {
            if (survey.currentPageNo === 2 && enableLicenseExamPayment) {
              survey.currentPageNo = 1;
            } else if (survey.currentPageNo > 0) {
              survey.currentPageNo = 0;
            } else {
              if (canGoBack()) navigate(-1);
            }
          }}
        />
      ) : (
        <span className={styles.tips}>
          {survey?.currentPageNo === 2
            ? t("candidate_data")
            : surveyIsReadonly
            ? t("exam_detail")
            : t("new_registration")}
        </span>
      )}
      <div className={styles.content}>
        {form}
        {bottom}
      </div>
    </div>
  );
};

const convertTemplate = (_template: any, recruitConfigs: any, globalConfigs: any) => {
  const template = cloneDeep(_template);
  const elements = template.pages[2].elements;
  const examPageElements = template.pages[0].elements;
  const enableExamFeeInput = get(recruitConfigs, "enableExamFeeInput", false);
  // remove exam fee from the elements if not enabled
  if (!enableExamFeeInput) {
    const examFeeField = examPageElements.find((item: any) => item.name === "examFee");

    if (examFeeField) {
      examFeeField.visibleIf = undefined;
      examFeeField.visible = false;
    }
  }
  const regionPhoneField = elements.find((item: any) => item.name === "regionPhone");
  regionPhoneField.readOnly = true;
  regionPhoneField.templateElements[0].readOnly = true;
  regionPhoneField.templateElements[1].readOnly = true;

  // add max and min birthday
  const birthdayField = template.pages[2].elements.find((item: any) => item.name === "birthday");
  birthdayField.visible = true;
  if (!recruitConfigs?.candidateProfile?.enableBirthday) {
    birthdayField.visible = false;
  } else if (globalConfigs?.region.ageValidation) {
    const { min, max } = globalConfigs?.region?.ageValidation;
    const minDate = max?.value
      ? moment().subtract(max.value, "years").format("YYYY-MM-DD")
      : undefined;
    const maxDate = min?.value
      ? moment().subtract(min.value, "years").format("YYYY-MM-DD")
      : undefined;

    birthdayField.min = minDate;
    birthdayField.max = maxDate;
  }

  // Function to translate a given field if it exists
  const translateField = (obj: any, field: string) => {
    if (obj?.[field]) {
      obj[field] = getI18n().t(obj[field]);
    }
  };
  // Loop through each element in the first page
  template.pages.forEach((page: any) => {
    page.elements = page.elements.map((q: any) => {
      translateField(q, "title");
      translateField(q, "placeholder");
      translateField(q, "labelTrue");
      translateField(q, "labelFalse");
      translateField(q, "requiredErrorText");
      translateField(q, "tooltips");
      translateField(q, "description");
      translateField(q?.modalConfig, "modalTitle");
      // translate choices if they exist
      if (q.choices && Array.isArray(q.choices)) {
        q.choices.forEach((choice: any) => {
          translateField(choice, "text");
        });
      }

      if (q?.type === "html") {
        const htmlString = q.html;
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        const paragraphs = tempDiv.getElementsByTagName("p");
        Array.from(paragraphs).forEach((p) => {
          const content = translate((p.textContent as string).trim());
          p.textContent = content;
        });
        const newHtmlString = tempDiv.innerHTML;
        q.html = newHtmlString;
      }

      // Check if the element has validators and translate their text fields
      if (q.validators && Array.isArray(q.validators)) {
        q.validators.forEach((validator: any) => {
          translateField(validator, "text");
        });
      }

      // If the element is a paneldynamic type, we need to handle nested templateElements
      if (q.type === "paneldynamic" && q.templateElements && Array.isArray(q.templateElements)) {
        q.templateElements = q.templateElements.map((te: any) => {
          translateField(te, "title");
          translateField(te, "labelTrue");
          translateField(te, "labelFalse");
          translateField(te, "requiredErrorText");
          translateField(te, "placeholder");
          translateField(q, "description");
          translateField(te?.modalConfig, "modalTitle");

          if (te.validators && Array.isArray(te.validators)) {
            te.validators.forEach((validator: any) => {
              translateField(validator, "text");
            });
          }

          return te;
        });
      }

      return q;
    });
  });
  return template;
};
