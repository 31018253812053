import React, { memo, useMemo } from "react";
import { styles } from "./license-exam-entrance-card.style";
import { useLicenseExamEntranceCard } from "./license-exam-entrance-card.hook";
import { ExamMode, iconBgColorMap, iconColorMap, iconMap } from "./type";
import { useTranslation } from "react-i18next";
import { IconFontLayer } from "../../../../../../icon-font/iconfont-layer";
import { color } from "../../../../../../mui-theme/palette";
import { textStyleSet } from "../../../../../../mui-theme/text.style";
import { IconFont } from "../../../../../../icon-font/iconfont";

interface ComponentProps {}

export const LicenseExamEntranceCardComponent: React.FC<ComponentProps> = memo(
  (props: ComponentProps) => {
    const { handlePress, translations, licenses } = useLicenseExamEntranceCard(props);
    return (
      <div className="py-3">
        <div className="flex flex-row items-center mb-3 mx-4">
          <p className="text-grey33" style={textStyleSet.text_18_bold}>
            {translations.title}
          </p>
        </div>
        {licenses.map((licenseOrExam) => (
          <LicenseExamItemComponent
            key={licenseOrExam.examName}
            licenseOrExam={licenseOrExam}
            handlePress={handlePress}
          />
        ))}
      </div>
    );
  },
);

interface LicenseExamItemProps {
  licenseOrExam: any;
  handlePress?: (licenseOrExam: any) => void;
}

const LicenseExamItemComponent: React.FC<LicenseExamItemProps> = ({
  licenseOrExam,
  handlePress,
}) => {
  return (
    <div
      style={styles.contentContainer}
      onClick={() => handlePress?.(licenseOrExam)}
      className="aspect-ratio-1-1:rounded-xl"
    >
      {!licenseOrExam.examSchedule ? (
        <LicenseExamItemV1 licenseOrExam={licenseOrExam} />
      ) : (
        <ExamRegistrationItem licenseOrExam={licenseOrExam} />
      )}
    </div>
  );
};

const LicenseExamItemV1: React.FC<LicenseExamItemProps> = ({ licenseOrExam }) => {
  const contentComponent = useMemo(() => {
    if (!licenseOrExam.examName) {
      return (
        <div className="flex flex-1 flex-col justify-center ml-3">
          <div className="flex flex-row items-center">
            <p className="text-grey33 flex-1" style={textStyleSet.text_16_med}>
              {licenseOrExam.description}
            </p>
            <div
              className="self-center rounded-[4px]"
              style={{
                backgroundColor: licenseOrExam.statusBackgroundColor,
              }}
            >
              <p
                className="px-2 py-1"
                style={{ ...textStyleSet.text_12_med, color: licenseOrExam.statusTextColor }}
              >
                {licenseOrExam.status}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-1 flex-col justify-center ml-3">
        <div className="flex flex-row">
          <p className="text-grey99 flex-1 self-center" style={textStyleSet.text_14_med}>
            {licenseOrExam.examName}
          </p>
          <div
            className="self-start rounded-[4px]"
            style={{
              backgroundColor: licenseOrExam.statusBackgroundColor,
            }}
          >
            <p
              className="px-2 py-1"
              style={{ ...textStyleSet.text_12_med, color: licenseOrExam.statusTextColor }}
            >
              {licenseOrExam.status}
            </p>
          </div>
        </div>
        <p className="text-grey33 pt-1" style={textStyleSet.text_16_med}>
          {licenseOrExam.description}
        </p>
      </div>
    );
  }, [licenseOrExam]);
  return (
    <div className="flex flex-row items-center">
      <IconFontLayer
        size={36}
        backgroundSize={56}
        containerStyle={styles.iconContainer}
        name="licenseexam"
        color={color.majorRed}
      />
      {contentComponent}
    </div>
  );
};

const ExamRegistrationItem: React.FC<LicenseExamItemProps> = ({ licenseOrExam }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center">
      <IconFontLayer
        size={36}
        backgroundSize={56}
        containerStyle={{
          ...styles.iconContainer,
          backgroundColor: iconBgColorMap[(licenseOrExam.examMode ?? ExamMode.DEFAULT) as ExamMode],
        }}
        name={iconMap[(licenseOrExam.examMode ?? ExamMode.DEFAULT) as ExamMode]}
        color={iconColorMap[(licenseOrExam.examMode ?? ExamMode.DEFAULT) as ExamMode]}
      />
      <div className="flex flex-1 flex-col ml-3">
        <div className="flex flex-row">
          <div className="flex flex-1 items-center">
            <p className="text-grey99 flex-1 self-center" style={textStyleSet.text_14_med}>
              {licenseOrExam.examName}
            </p>
          </div>
          <div
            style={{
              backgroundColor: licenseOrExam.statusBackgroundColor,
            }}
            className="self-start rounded-[4px]"
          >
            <p
              className="px-2 py-1"
              style={{ ...textStyleSet.text_12_med, color: licenseOrExam.statusTextColor }}
            >
              {t(licenseOrExam.statusI18nKey)}
            </p>
          </div>
        </div>
        <div>
          {licenseOrExam.score ? (
            <p className="text-grey33 pt-1" style={textStyleSet.text_16_med}>
              {t("Common.score")}: {licenseOrExam.score}
              {"  "}
              <span style={{ color: color.greycf }}>|</span>
              {"  "}
              {t("Common.result")}: {t(licenseOrExam.statusI18nKey)}
            </p>
          ) : null}
          <p className="text-grey33 pt-1" style={textStyleSet.text_16_med}>
            <IconFont name="time-event" size={16} color={color.grey33} />
            {"  "}
            {licenseOrExam.examSchedule}
          </p>
          <p className="text-grey33 pt-1" style={textStyleSet.text_16_med}>
            <IconFont name="location-event" size={16} color={color.grey33} />
            {"  "}
            {licenseOrExam.examCentre}
          </p>
          {[ExamMode.COMPUTER, ExamMode.PAPER].includes(licenseOrExam.examMode) ? (
            <>
              <p className="text-grey33 pt-1" style={textStyleSet.text_16_med}>
                <IconFont name="exammode" size={16} color={color.grey33} />
                {"  "}
                {t(`Common.${licenseOrExam.examMode}`)}
              </p>
              {licenseOrExam?.deviceType ? (
                <div className="flex flex-row pt-1 pl-5" style={textStyleSet.text_14_med}>
                  <span>
                    {"  "}
                    {t("Device")}:{" "}
                  </span>
                  <span className="pl-2">{licenseOrExam.deviceType}</span>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
