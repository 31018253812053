import * as React from "react";
import { linkOpen } from "../components/modal/link-open";
import { useGlobalModal } from "../components/use-modal/use-modal";
import { EVENT_OPEN_LINK_WITHIN_PAGE } from "../constants/constants";
import { PrivacyNoticeScreen } from "../modules/onboarding/pages/protocol-screen/privacy-notice.screen";
import { ProtocolComponent } from "../modules/onboarding/pages/protocol-screen/components/protocol-component";
import { t } from "i18next";

export const setupEventListeners = () => {
  document.addEventListener(EVENT_OPEN_LINK_WITHIN_PAGE, (event: any) => {
    const url = event?.detail?.url;

    if (!url) return;
    if (url.includes("/web/onboarding/privacy-notice")) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const modal = useGlobalModal();
      modal.open(<PrivacyNoticeScreen onClose={modal.close} />, {
        showHeader: false,
        modalType: "bottom",
        modalProps: {
          className: "dialog-full",
          PaperProps: {
            style: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              width: window.innerWidth + "px",
            },
          },
        },
      });
      // url might be relative route path
      // GlobalHelper.getGlobalNavigate()?.(`${FRONTEND_ROOT_PATH}${url}`);
    } else {
      const pattern = /^\/([^/]+)\/([^/]+)$/;
      const match = url.match(pattern);
      if (match) {
        const title = t(match[1]);
        const desc = t(match[2]);
        const modal = useGlobalModal();
        modal.open(<ProtocolComponent onClose={modal.close} title={title} desc={desc} />, {
          showHeader: false,
          modalType: "bottom",
          modalProps: {
            className: "dialog-full",
            PaperProps: {
              style: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                width: window.innerWidth + "px",
              },
            },
          },
        });
      } else {
        linkOpen({ url });
      }
    }
  });
};
