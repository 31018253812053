import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, debounce } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../../../routes/constants";
import { HeaderComponent } from "../../../../../components/header/header.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchInboxMessagesAsync, OnboardingState } from "../../../redux/onboarding-slice";
import { InboxMessage, MessageListRequestParams } from "../../../network/types";
import { InfiniteScroll } from "../../../../../components/infinite-scroll-component";
import { ErrorStateBlock } from "../../../../../components/error-state/error-state";
import { InboxItem } from "../components/inbox-item";
import { useTranslation } from "react-i18next";

const pullDownHold = 200;
const progressSize = 24;
const listPadding = 3;

export const InboxSearchScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    inboxMessagesResponse,
    loaded: apiLoaded,
    getDataError: error,
  } = useSelector((state: { onboarding: OnboardingState }) => state.onboarding);

  const [page, setPage] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const translations = useMemo(() => {
    return {
      search_placeholder: t("Common.inbox_search_placeholder_tip"),
      title: t("Common.inbox"),
      search: t("Common.inbox_search_placeholder_tip"),
      doesNotMatch: t("Common.no_results_matched"),
      noMore: t("Common.messaging_no_more"),
    };
  }, [t]);
  const {
    nextPage,
    hasNextPage,
    data: inboxMessages,
  } = useMemo(() => {
    return {
      nextPage: inboxMessagesResponse?.nextPage ?? 1,
      hasNextPage: inboxMessagesResponse?.hasNextPage,
      data: inboxMessagesResponse?.data ?? [],
    };
  }, [inboxMessagesResponse]);

  const [searchValue, setSearchValue] = useState("");

  const fetchInboxMessages = useCallback(
    async (params: MessageListRequestParams) => {
      dispatch<any>(fetchInboxMessagesAsync(params));
    },
    [dispatch],
  );

  const debouncedFetchInboxMessages = useMemo(
    () => debounce(fetchInboxMessages, 500),
    [fetchInboxMessages],
  );

  useEffect(() => {
    const query = {
      type: "inbox_recruitment",
      searchkey: searchValue,
    };

    if (isRefreshing) {
      debouncedFetchInboxMessages({ page: 1, query });
    } else {
      debouncedFetchInboxMessages({ page, query });
    }
  }, [page, searchValue, isRefreshing, debouncedFetchInboxMessages]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSearchInput = (e: any) => {
    // handle search input
    const searchValue = e.target.value;
    setSearchValue(searchValue);
    setPage(1);
  };

  // Pull to refresh handler
  const handleRefresh = () => {
    setIsRefreshing(true);
    setPage(1); // Reset to first page to refresh data
  };
  useEffect(() => {
    if (apiLoaded) {
      setIsRefreshing(false);
      setIsLoadingMore(false);
    }
  }, [apiLoaded]);

  // Next page loader (infinite scroll)
  const toNextPage = useCallback(async () => {
    if (isLoadingMore || !hasNextPage) return;

    setIsLoadingMore(true);
    setPage((prevPage) => prevPage + 1);
  }, [isLoadingMore, hasNextPage]);
  const listComponent = useMemo(() => {
    if (!searchValue) {
      return null;
    }
    return (
      <div className="flex-1 overflow-auto p-4" id={"scrollTarget"}>
        <InfiniteScroll
          style={{
            overflow: "hidden",
          }}
          scrollableTarget="scrollTarget"
          dataLength={inboxMessages.length}
          next={toNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className="flex items-center justify-center mt-1">
              <CircularProgress size={progressSize} color="error" />
            </div>
          }
          refreshFunction={handleRefresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={pullDownHold}
          pullDownToRefreshContent={(value) => (
            <div className="items-center flex justify-center mt-1">
              <CircularProgress
                size={progressSize}
                color="error"
                variant="determinate"
                value={(value / pullDownHold) * 100}
              />
            </div>
          )}
          releaseToRefreshContent={
            <div className="bg-neutral-100 items-center flex justify-center mt-1">
              <CircularProgress size={progressSize} color="error" />
            </div>
          }
          endMessage={
            inboxMessages.length === 0 ? null : (
              <div className="text-center text-gray-500 mt-4">{translations.noMore}</div>
            )
          }
        >
          {error ? (
            <ErrorStateBlock statusCode="-1" />
          ) : inboxMessages.length === 0 ? (
            <div className="text-center text-gray-500">{translations.doesNotMatch}</div>
          ) : (
            <ul className="space-y-4">
              {inboxMessages.map((message: InboxMessage) => (
                <InboxItem highlightText={searchValue} key={message?.id} message={message as any} />
              ))}
            </ul>
          )}
        </InfiniteScroll>
      </div>
    );
  }, [inboxMessages, error, hasNextPage, toNextPage, handleRefresh, searchValue]);
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen w-screen flex flex-col">
      <HeaderComponent title={translations.title} onBack={handleGoBack} />
      <div className="p-4 bg-white">
        <div className="flex flex-row w-full p-2 border bg-gray-100 text-left rounded-full items-center h-9">
          <SearchIcon className="mr-2" style={{ fontSize: 16, color: "gray" }} />

          <input
            type="text"
            placeholder={translations.search_placeholder}
            autoFocus
            onChange={handleSearchInput}
            className="flex-1 border-none focus:outline-none focus:border-none bg-gray-100"
          />
        </div>
      </div>
      {listComponent}
    </div>
  );
};
