import React, { FC, Fragment, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { useAppSelector } from "../../../../../redux/store";
import { getI18nData } from "../../../../../utils/i18n/i18n.util";
import { formatEventTime, getEventType, openFromApp } from "../../../util/event.util";
import Icon from "../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../components/icon/assets";
import {
  ACCESSIBILITY_TYPE,
  OnlineMeetingLinkDisplayEnum,
  PartyType,
  RegisterStatus,
} from "../../../network/type";
import dayjs from "dayjs";
import { useQrCodeContent } from "./qr-code-content.hook";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

export const QrCodeContentComponent = memo(() => {
  const { refId = "" } = useParams();
  const eventQRcodeData = useAppSelector((state) => state.event.eventQRcodeData)!;
  const {
    firstName,
    lastName,
    qrCode,
    name,
    venue,
    category,
    type,
    startTime,
    endTime,
    status,
    party,
    expiredTime,
    onlineMeetingLink,
    onlineMeetingLinkDisplay,
  } = useAppSelector((state) => state.event.eventQRcodeData)!;
  const { t, i18n } = useTranslation();
  const { downloadAsImage, onClickLinkFromApp, addToCalendar } = useQrCodeContent();

  const debounceAddToCalendar = useMemo(() => debounce(addToCalendar, 200), [addToCalendar]);

  const accessibilityConfig = useMemo(
    () => ({
      [ACCESSIBILITY_TYPE.INTERNAL]: t("event.internal"),
      [ACCESSIBILITY_TYPE.PUBLIC]: t("event.public"),
    }),
    [t],
  );

  const isGrey = useMemo(() => dayjs().isAfter(expiredTime), [expiredTime]);

  const userName = useMemo(() => {
    const wordSpace = lastName ? " " : "";
    return i18n.language.includes("zh")
      ? `${lastName}${wordSpace}${firstName}`
      : `${firstName}${wordSpace}${lastName}`;
  }, [firstName, i18n.language, lastName]);

  return (
    <React.Fragment>
      <div className="flex flex-col items-center">
        <span className="mt-10 mb-2 text-center text-base font-semibold">
          {userName}
          {party === PartyType.AGENT ? ` ${t("event.agent_type_me")}` : ""}
        </span>

        {status === RegisterStatus.CANCELLED ? (
          <Fragment>
            <Icon svg={SvgAssets.expired} width={180} height={180} />
            <span className="text-base font-medium text-center">{t("event.qr_code_expired")}</span>
          </Fragment>
        ) : (
          <Fragment>
            <div className="w-[200px] h-[200px] p-[10px]">
              <QRCode value={qrCode} size={190} fgColor={isGrey ? "#CCCCCC" : undefined} />
            </div>
            <span
              className={`mt-2 text-sm font-medium ${
                isGrey ? "line-through text-[#CCCCCC]" : "text-[#666]"
              }`}
            >
              {qrCode.replace(/(\d{4})(?=\d)/g, "$1 ")}
            </span>
            {!isGrey && (
              <div
                className="px-4 py-2 rounded-lg border-2 border-[#CCC] mt-4 flex flex-row items-center"
                onClick={downloadAsImage}
              >
                <Icon svg={SvgAssets.download} className="w-4 h-4 mr-1" />
                <span className="text-sm font-medium">{t("event.save_to_album")}</span>
              </div>
            )}
          </Fragment>
        )}
      </div>

      <div className="w-full flex flex-row justify-between items-center mt-3">
        <div className="w-6 h-6 rounded-xl bg-[#F5F5F5] -ml-7"></div>
        <div className="flex-1 border-dashed border-b-2 border-[#F0F0F0]"></div>
        <div className="w-6 h-6 rounded-xl bg-[#F5F5F5] -mr-7"></div>
      </div>

      <div className="w-full h-full flex flex-col">
        <span className="mt-3 text-xl font-semibold">{getI18nData(name)}</span>
        <div className="mt-2">
          <span className="text-sm font-medium text-[#999]">
            {accessibilityConfig[category.accessibility]}
          </span>
          <span className="inline-block h-[12px] w-[1px] bg-[#E6E6E6] relative translate-y-[1px] mx-2" />
          <span className="text-sm font-medium text-[#999]">{getI18nData(category.name)}</span>
          <span className="inline-block h-[12px] w-[1px] bg-[#E6E6E6] relative translate-y-[1px] mx-2" />
          <span className="text-sm font-medium text-[#999]">{getEventType(type)}</span>
        </div>

        <div className="flex flex-row items-start my-2">
          <Icon
            svg={SvgAssets.timeGrey66}
            width={16}
            height={16}
            className="flex-shrink-0 mt-[2px]"
          />
          <div className="flex flex-col gap-1 ml-1">
            <span className="text-sm font-medium text-[#666] min-w-0">
              {formatEventTime(startTime, endTime)}
            </span>
            <span
              className="text-sm text-[#4386E5] font-medium cursor-pointer"
              onClick={() => {
                debounceAddToCalendar(refId, eventQRcodeData);
              }}
            >
              {t("event.add_to_calendar")}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-start mb-4">
          <Icon
            svg={SvgAssets.locationGrey66}
            width={16}
            height={16}
            className="flex-shrink-0 mt-[2px]"
          />
          <div className="flex flex-col flex-1 min-w-0 gap-1 ml-1">
            <span className="text-sm font-medium text-[#666]">{getI18nData(venue)}</span>
            {onlineMeetingLink &&
            onlineMeetingLinkDisplay?.includes(OnlineMeetingLinkDisplayEnum.REGISTRANT_TICKET) ? (
              openFromApp() ? (
                <span
                  className="text-[#4386E5] text-sm font-medium"
                  onClick={() => onClickLinkFromApp(onlineMeetingLink)}
                >
                  {t("event.online_link")}
                </span>
              ) : (
                <a
                  href={onlineMeetingLink}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#4386E5] text-sm font-medium"
                >
                  {t("event.online_link")}
                </a>
              )
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
