import React, { FC, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../redux/store";
import { getI18nData } from "../../../../../utils/i18n/i18n.util";
import dayjs from "dayjs";
import { constants } from "../../../../../routes/constants";
import Icon from "../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../components/icon/assets";
import { EventType, OnlineMeetingLinkDisplayEnum, Session } from "../../../network/type";
import { openFromApp } from "../../../util/event.util";

export const AgendaComponent = memo(() => {
  const { sessions, venue, type } = useAppSelector((state) => state.event.eventQRcodeData)!;
  const { t } = useTranslation();

  return sessions && sessions.length > 0 ? (
    <div className="w-full h-full border-solid border-t-[1px] border-[#F0F0F0] pt-4">
      <span className="text-base font-semibold">{t("event.your_agenda")}</span>
      <div className="mt-3 pl-3 w-full">
        {sessions?.map((session, index) => {
          const subSessionInx =
            typeof session.subIndex === "number" ? session.subIndex + 1 : undefined;
          const sessionInx = session.index + 1;
          return (
            <SessionItem
              key={index}
              session={session}
              venue={
                getI18nData(session.venue) ||
                (type === EventType.VIRTUAL ? t("event.online_event") : getI18nData(venue))
              }
              title={
                subSessionInx
                  ? t("event.sub_session_item_title", { sessionInx, subSessionInx })
                  : t("event.session_item_title", { sessionInx })
              }
            />
          );
        })}
      </div>
    </div>
  ) : null;
});

const SessionItem = ({
  session,
  title,
  venue,
}: {
  session: Session;
  title: string;
  venue: string;
}) => {
  const { t } = useTranslation();
  const { name, startTime, endTime, onlineMeetingLink, onlineMeetingLinkDisplay } = session;

  const onClickLinkFromApp = useCallback((link: string) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        action: "open-url",
        payload: { url: link },
      }),
    );
  }, []);

  return (
    <div className="w-full border-l-2 border-[#E6E6E6] last:border-transparent relative flex flex-row pb-6 pl-5">
      <div className="w-3 h-3 rounded-[6px] border-[3px] border-[#E8192C] absolute -left-[7px] bg-white"></div>
      <div className="-mt-[6px] flex flex-col w-full">
        <span className="text-base font-medium">{title}</span>
        <span className="text-base font-medium">{getI18nData(name)}</span>
        <div className="mt-[5px] flex flex-row items-start">
          <Icon svg={SvgAssets.locationGray} className="w-4 h-4 mr-1 flex-shrink-0 mt-[2px]" />
          <div className="flex flex-col flex-1 min-w-0 gap-1">
            <span className="text-sm font-medium text-[#999]">{venue}</span>
            {onlineMeetingLink &&
            onlineMeetingLinkDisplay?.includes(OnlineMeetingLinkDisplayEnum.REGISTRANT_TICKET) ? (
              openFromApp() ? (
                <span
                  className="text-[#4386E5] text-sm font-medium"
                  onClick={() => onClickLinkFromApp(onlineMeetingLink)}
                >
                  {t("event.online_link")}
                </span>
              ) : (
                <a
                  href={onlineMeetingLink}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#4386E5] text-sm font-medium"
                >
                  {t("event.online_link")}
                </a>
              )
            ) : null}
          </div>
        </div>
        <div className="mt-[5px] flex flex-row items-center">
          <Icon svg={SvgAssets.timeGray} className="w-4 h-4 mr-1 flex-shrink-0" />
          <span className="text-sm font-medium text-[#999] flex-1 min-w-0">{`${dayjs(
            startTime,
          ).format(constants.Time_Format)}-${dayjs(endTime).format(constants.Time_Format)}`}</span>
        </div>
      </div>
    </div>
  );
};
